import styled from "styled-components";
import ProximaNova from "../../../../../assets/fonts/ProximaNova-Regular.otf";

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (min-width: 992px) {
    margin-top: 20px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CenteredRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 75px;
`;

// @media (min-width: 576px) {
//   margin-left: 50px;
//   margin-right:  50px;
// }
// @media (min-width: 992px) {
//   margin-left: 100px;
//   margin-right:  100px;
// }
// @media (min-width: 1600px) {
//   margin-left: 100px;
//   margin-right:  100px;
// }

export const CommentInput = styled.textarea`
  font: normal normal 400 1rem proxima-nova, sans-serif;
  color: #828282;
  resize: none;
  width: 100%;
  height: 75px;
  background: #f2f2f2;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  outline: none;
  border: none;
  padding: 10px;
  ::placeholder {
    color: #828282;
  }
`;

export const EndContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const PostButton = styled.button`
  width: 63px;
  height: 25px;
  font-size: 14px;
  margin-top: 2vh;
  font: Bold 1rem Proxima Nova;
  background: #0071e2;
  border-radius: 5px;
  border: 1px solid #0071e2;
  margin-bottom: 1vh;
  opacity: 1;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const HorizontalCommentAuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75px;
  margin-right: 15px;
`;

export const ProfileImage = styled.img`
  background-color: #ebebeb;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  @media (min-width: 576px) {
    margin-left: 50px;
    margin-right: 50px;
  }
`;

export const CommentAuthor = styled.div`
  text-align: center;
  margin: 0;
  color: #333333;
  font-weight: 500;
  font-size: 0.75rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
  }
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UsernameText = styled.p`
  font: normal normal 400 12px proxima-nova, sans-serif;
  line-height: 17px;
  color: #4f4f4f;
  margin-top: 5px;
  margin-bottom: 0px;
`;
