import axios from "axios";
import { API } from "../constants";
/**
 * Function to retrieve snippet object based on the snippet id
 *
 * @param id - snippet id to retrieve
 */
const getSnippet = async (id) => {
  let api = API + "/api/episode/snippet?SnippetID=" + id;

  return axios.get(api).then((response) => {
    return response.data;
  });
};

export default getSnippet;
