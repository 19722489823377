import styled from "styled-components";
import { Link } from "react-router-dom";

export const LibraryHeader = styled.div`
  width: 100%;
  margin-top: 2.4vh;
  margin-bottom: 1rem;
  color: #333333;
  font-size: 1.5rem;
  font-family: Proxima Nova Lt;

  @media (min-width: 1920px) {
    font-size: 2rem;
    margin-bottom: 1.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666rem;
    margin-bottom: 1.77777777778rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
    margin-bottom: 2.66666666667rem;
  }
`;

export const LibraryContainer = styled.div`
  width: 100%;
`;

export const LoggetOutMessage = styled.h3`
  font-size: 0.875rem;
  color: #4f4f4f;
  margin-top: 0.75rem;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-top: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-top: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
    margin-top: 2rem;
  }
`;

export const LoggedOutLink = styled(Link)`
  color: #2f80ed;
  text-decoration: underline;
`;
