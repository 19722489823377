const recommendations = [
  {
    ID: "37c678b3-0651-4831-b043-ba85028d9468",
    Title: "The Pulse by Wharton Digital Health",
    School: "UPenn",
    Subscribers: ["a61c88d1-8cb2-4ed1-b4ba-8602ee7ecc69"],
    RSS: "https://anchor.fm/s/7bb94a8/podcast/rss",
    Creators: ["string"],
    Img:
      "https://d3t3ozftmdmh3i.cloudfront.net/production/podcast_uploaded_nologo/1197338/1197338-1579537360656-d34befd31d52d.jpg",
    Description:
      "Capturing the pulse of healthcare innovation spanning leaders across the healthcare ecosystem.",
    Category: "Business",
    Curation: "All about healthcare",
    User: {
      Name: "Caitlin Pintavorn",
      Username: "caitlin",
      Img: "https://i.ibb.co/m5yjnNT/Asset-18-3x.png",
      Text: "All about healthcare innovation!",
    },
  },
  {
    ID: "451418c3-003e-4fb5-bdd8-997844c6bbba",
    Subscribers: [],
    School: "Yale",
    Category: "Education",
    RSS: "https://feeds.megaphone.fm/happinesslab",
    Title: "The Happiness Lab with Dr. Laurie Santos",
    Creators: ["string"],
    Img:
      "https://megaphone.imgix.net/podcasts/a119da18-ae5e-11e9-a441-b7c300008a78/image/uploads_2F1592404408978-9s5qp0eqyn7-ae34e4bca84ba25275b6e9d7ea08a249_2FHL%2BFinal%2BArtwork%2BPushkin.jpg?ixlib=rails-2.1.2&max-w=3000&max-h=3000&fit=crop&auto=format,compress",
    Description:
      "You might think you know what it takes to lead a happier life… more money, a better job, or Instagram-worthy vacations. You’re dead wrong. Yale professor Dr. Laurie Santos has studied the science of happiness and found that many of us do the exact opposite of what will truly make our lives better. Based on the psychology course she teaches at Yale -- the most popular class in the university’s 300-year history -- Laurie will take you through the latest scientific research and share some surprising and inspiring stories that will change the way you think about happiness. iHeartMedia is the exclusive podcast partner of Pushkin Industries. ",
    Curation: "We live in a society",
    User: {
      Img: "https://i.ibb.co/qs5GmMh/Asset-19-3x.png",
      Username: "ckoh",
      Name: "Christine Koh",
      Text: "What it means to lead a happier, more fulfilling life",
    },
  },
  {
    ID: "83fe216d-a5fe-4537-9f69-7bd93527dd6c",
    Creators: [""],
    Img: "https://i1.sndcdn.com/avatars-000611049777-ufj2ye-original.jpg",
    Title: "Wharton FinTech Podcast",
    Description:
      "Connecting you with the leaders, ideas, and companies that are reinventing global financial services. Our guests are the top FinTech founders, executives, investors, and thinkers from across the world.\n\nFollow us for more FinTech content including videos, blogs, and analysis by searching @whartonfintech on your preferred platform.",
    Category: "Technology",
    Subscribers: [],
    RSS:
      "https://feeds.soundcloud.com/users/soundcloud:users:176298819/sounds.rss",
    School: "UPenn",
    Curation: "Startups, startups everywhere",
    User: {
      Username: "ethan",
      Name: "Ethan Chung",
      Img: "https://i.ibb.co/z5DMdw7/Asset-20-3x.png",
      Text: "Investor and founder perspectives on fintech",
    },
  },

  {
    Img:
      "https://megaphone.imgix.net/podcasts/ea3e9f0c-55c0-11eb-bb7b-67fdf92da016/image/CryingInPublicLogo.jpg?ixlib=rails-2.1.2&max-w=3000&max-h=3000&fit=crop&auto=format,compress",
    Description:
      "Welcome to Crying in Public, just another weekly podcast hosted by two 20-something, college women living in NYC. Follow along while they dive into growing up in a time where there’s no distinction between what’s public and what’s private. Even though we’re encouraged to put ONLY our best self forward on social media, these ladies dare to be open as young women navigating the 21st century. As we love to say: sh*t happens, so cry about it.",
    Subscribers: ["a61c88d1-8cb2-4ed1-b4ba-8602ee7ecc69", ""],
    Title: "Crying In Public",
    Creators: ["string"],
    Category: "Comedy",
    RSS: "https://anchor.fm/s/22776ce0/podcast/rss",
    School: "NYU",
    ID: "e9120c7e-904a-4128-9930-36afbe2fccd7",
    Curation: "Young 20-somethings in the city",
    User: {
      Name: "Stephanie Park",
      Username: "stephanie",
      Img: "https://i.ibb.co/mh4p63c/Asset-15-3x.png",
      Text: "Super fun and honest convo about life in NYC",
    },
  },
  {
    Creators: ["3c089dcf-e106-4ea6-8c12-1cbaa722c4f2"],
    Img:
      "https://content-prod-ripr.thepublicsradio.org/articles/d0d61d5e-264f-4778-9ddd-8ed2c754f00d/possibly300.png",
    Description:
      "Possibly looks at the science behind proposed environmental solutions - helping you cut through the noise and make choices that, possibly, will make a difference.",
    Category: "News & Politics",
    Subscribers: ["0b53a814-7db3-49f6-82de-70ea9a1f40e4"],
    RSS: "https://thepublicsradio.org/show/possibly-podcast/feed.xml",
    ID: "bb463c37-ea17-4279-ad00-4e18df49d153",
    Title: "Possibly",
    School: "Brown",
    Curation: "Saving our planet, if you're into that",
    User: {
      Username: "guaca",
      Img: "https://i.ibb.co/YX04gJs/Asset-22-3x.png",
      Name: "Nick Kkguaca",
      Text: "Exploring fundamental eco questions",
    },
  },
  {
    ID: "8e1a024c-c095-46f0-aead-197f2797d717",
    Title: "All My Relations Podcast",
    Creators: ["string"],
    Category: "Society & Culture",
    Img:
      "https://storage.buzzsprout.com/variants/twyfjdddcez033jk9jo0ib12f0p0/8d66eb17bb7d02ca4856ab443a78f2148cafbb129f58a3c81282007c6fe24ff2.jpg",
    Description:
      "Welcome! All My Relations is a podcast hosted by Matika Wilbur (Swinomish and Tulalip), and Desi Small Rodriguez (Northern Cheyenne) [previously by Dr. Keene] to explore our relationships— relationships to land, to our creatural relatives, and to one another.  Each episode invites guests to delve into a different topic facing Native American peoples today. We keep it real, play some games, laugh a lot, and even cry sometimes. We invite you to join us!",
    Subscribers: [],
    RSS: "https://feeds.buzzsprout.com/262196.rss",
    School: "Brown",
    Curation: "Unpacking identities",
    User: {
      Img: "https://i.ibb.co/Pz3Mmhh/Asset-17-3x.png",
      Name: "Aric",
      Username: "aricz",
      Text: "Great podcast and learnings on native identities",
    },
  },
  {
    Subscribers: [],
    RSS: "https://hubermanlab.libsyn.com/rss",
    ID: "6796d767-4e44-4c5e-8105-f252d541e1a1",
    Category: "Education",
    Img:
      "https://ssl-static.libsyn.com/p/assets/f/7/d/9/f7d9cdda658759cb/Huberman-Lab-Podcast-Thumbnail-2000x2000.jpg",
    Description:
      "The Huberman Lab Podcast discusses Neuroscience: how our brain and its connections with the organs of our body controls our perceptions, our behaviors, and our health.\n\nWe also discuss existing and emerging tools for measuring and changing how our nervous system works.\n\nDr. Andrew Huberman is a tenured Professor of Neurobiology and Ophthalmology at Stanford School of Medicine. His laboratory studies neural regeneration and neuroplasticity, and brain states such as stress, focus, fear, and optimal performance.\n\nFor more than 20 years, Dr. Huberman has consistently published original research findings and review articles in top-level peer-reviewed journals including Nature, Science, Cell, Neuron, and Current Biology.\n\nHe is a regular member of several National Institutes of Health review panels, and a Fellow of the McKnight Foundation and the Pew Charitable Trusts.\n\nDr. Huberman regularly consults for technology development companies, professional athletic organizations, and for various units of U.S. and Canadian Special Operations.",
    School: "Stanford",
    Title: "Huberman Lab",
    Creators: ["string"],
    Curation: "Put on your lab goggles",
    User: {
      Username: "ryan",
      Img: "https://i.ibb.co/2SxwpzV/Asset-16-3x.png",
      Name: "Ryan Kim",
      Text: "Practical, science-first advice on life and health",
    },
  },
];

export default recommendations;
