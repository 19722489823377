import React, { useState, useEffect } from "react";
import * as s from "./StyledEpisodeItem";
import {
  useGetAndSet,
  useSetStoreValue,
  useStoreState,
} from "react-context-hook";
import getEpisode from "../../../api/podcasts/getEpisode";
import getPodcast from "../../../api/podcasts/getPodcast";
import ImageNotFound from "../../../assets/image_not_found.png";
import formatDate from "../../../utils/formatDate";
import updateLikes from "../../../api/podcasts/updateLikes";
import removeLikes from "../../../api/podcasts/removeLikes";
import DateImg from "../../../assets/date.svg";
import PlayIcon from "../../../assets/PlayIcon.svg";
import PauseIcon from "../../../assets/PauseIcon.svg";

const EpisodeItem = ({ episodeID, incrementUndefined }) => {
  const { user } = useStoreState();
  const setUrl = useSetStoreValue("url");
  const [playing, setPlaying] = useGetAndSet("playing");
  const [currentImage, setCurrentImage] = useGetAndSet("currentImage");
  const setCurrentSeries = useSetStoreValue("currentSeries");
  const [currentTitle, setCurrentTitle] = useGetAndSet("currentTitle");
  const setCurrentPodcastID = useSetStoreValue("currentPodcastID");

  const [podcast, setPodcast] = useState({});
  const [episode, setEpisode] = useState({});
  const setCurrentEpisodeID = useSetStoreValue("currentEpisodeID");
  const [liked, setLiked] = React.useState(false);
  const [isLikeInArray, setIsLikeInArray] = React.useState(false);
  const [loaded, setLoaded] = useState(false);

  // function to load episode at the most recently played timestamp
  useEffect(() => {
    if (!loaded) {
      getEpisode(episodeID).then((data) => {
        setEpisode(data);
        if (!data.ID) {
          incrementUndefined();
        } else {
          getPodcast(data.Ps).then((data) => {
            setPodcast(data.Podcast);
          });
        }
      });
    }
    setLoaded(true);
  }, [loaded, episodeID, incrementUndefined]);

  useEffect(() => {
    if (episode.Likes && user.id && episode.Likes.includes(user.id)) {
      setLiked(true);
      setIsLikeInArray(episode.Likes.includes(user.id));
    }
  }, [episode, user.id]);

  let load = () => {
    // condition where on the same episode and looking to pause
    if (playing && currentTitle === episode.Name) {
      setPlaying(false);
      // condition to play a new podcast episode
    } else {
      setUrl(episode.Key);
      setCurrentImage(episode.Img ? episode.Img : podcast.Img);
      setCurrentPodcastID(podcast.ID);
      setCurrentSeries(podcast.Title);
      setCurrentTitle(episode.Name);
      setCurrentEpisodeID(episode.ID.toString());
      setPlaying(true);
    }
  };

  let likeCount;
  if (isLikeInArray && liked) {
    likeCount = 0;
  } else if (isLikeInArray && !liked) {
    likeCount = -1;
  } else if (!isLikeInArray && liked) {
    likeCount = 1;
  } else if (!isLikeInArray && !liked) {
    likeCount = 0;
  }

  const handleLike = () => {
    if (liked) {
      removeLikes(episode.ID, user.id);
      setLiked(false);
    } else {
      updateLikes(episode.ID, user.id);
      setLiked(true);
    }
  };

  let likeHeart = liked ? (
    <s.LikedIcon onClick={handleLike} />
  ) : (
    <s.LikeIcon onClick={handleLike} />
  );
  return (
    <div>
      {episode.ID && (
        <s.EpisodeItemContainer>
          <s.ImageContainer onClick={() => load(episode, podcast.Title)}>
            <s.Image src={episode.Img || podcast.Img || ImageNotFound} />
            {playing &&
            currentImage != null &&
            episode.Name === currentTitle ? (
              <s.PauseIcon src={PauseIcon}/>
            ) : (
              <s.PlayIcon src={PlayIcon} />
            )}
          </s.ImageContainer>

          <s.Info>
            <s.EpisodeName
              to={
                "/podcasts/" +
                podcast.ID +
                "/" +
                podcast.Title +
                "/" +
                episodeID +
                "/" +
                episode.Name
              }
            >
              {episode.Name}
            </s.EpisodeName>
            <s.Description
              dangerouslySetInnerHTML={{
                __html: episode.Description,
              }}
            ></s.Description>
            <s.BottomRow>
              <s.LikeContainer>
                {likeHeart}
                <s.LikeNumber>
                  {episode.Likes ? episode.Likes.length + likeCount : likeCount}
                </s.LikeNumber>
                <s.CommentsLink
                  to={
                    "/podcasts/" +
                    podcast.ID +
                    "/" +
                    podcast.Title +
                    "/" +
                    episodeID +
                    "/" +
                    episode.Name
                  }
                >
                  <s.CommentIcon />
                </s.CommentsLink>
                <s.LikeNumber>
                  {episode.Comments && episode.Comments.length}
                </s.LikeNumber>
              </s.LikeContainer>
              <s.RightBottomContainer>
                {episode.PubDate && (
                  <>
                    <s.DateSVG src={DateImg} />
                    <s.DateText>{formatDate(episode.PubDate)}</s.DateText>
                  </>
                )}
              </s.RightBottomContainer>
            </s.BottomRow>
          </s.Info>
        </s.EpisodeItemContainer>
      )}
    </div>
  );
};

export default EpisodeItem;
