import React, { useState, useEffect } from "react";
import * as s from "./StyledSnippetLibraryItem";
import getEpisode from "../../../../../api/podcasts/getEpisode";
import getPodcast from "../../../../../api/podcasts/getPodcast";
import deleteSnippet from "../../../../../api/snippets/deleteSnippet";
import ImageNotFound from "../../../../../assets/image_not_found.png";
import PlayIcon from "../../../../../assets/PlayIcon.svg";

export default function SnippetLibraryItem(props) {
  const snippet = props.snippet;
  const [episode, setEpisode] = useState({});
  const [podcast, setPodcast] = useState({});
  const handleFocus = (event) => event.target.select();
  const [loaded, setLoaded] = useState(false);
  const [loaded1, setLoaded1] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [style, setStyle] = useState({ display: "none" });

  useEffect(() => {
    getEpisode(snippet.EpisodeID).then((response) => {
      setEpisode(response);
      setLoaded(true);
    });
    getPodcast(snippet.PodcastID).then((response) => {
      setPodcast(response);
      setLoaded1(true);
    });
  }, [snippet]);
  if (Object.keys(snippet).length === 0) {
    return <div></div>;
  }
  const url = window.location.host + "/s/" + snippet.SnippetID;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const deleteEvent = () => {
    deleteSnippet(snippet.SnippetID, window.localStorage.getItem("token"));
    props.delete(props.index);
  };

  let thumbnail = "";
  if ("Podcast" in podcast) {
    thumbnail = episode.Img
      ? episode.Img
      : podcast.Podcast.Img
      ? podcast.Podcast.Img
      : ImageNotFound;
  } else {
    thumbnail = episode.Img ? episode.Img : ImageNotFound;
  }

  if (!loaded && !loaded1) {
    return <div></div>;
  }
  return (
    <s.Container>
      <s.ThumbnailContainer>
        <s.ThumbnailPlayContainer
          to={"/s/" + snippet.SnippetID}
          onMouseEnter={(e) => {
            setStyle({ display: "block" });
          }}
          onMouseLeave={(e) => {
            setStyle({ display: "none" });
          }}
        >
          <s.Thumbnail src={thumbnail} />
          <s.PlayIcon src={PlayIcon} style={style} />
        </s.ThumbnailPlayContainer>

        <s.MobileIcons>
          <s.IconButtonn onClick={handleClickOpen}>
            <s.ShareIconn />
          </s.IconButtonn>
          <s.IconButtonn onClick={() => deleteEvent()}>
            <s.DeleteIcon />
          </s.IconButtonn>
        </s.MobileIcons>
      </s.ThumbnailContainer>
      <s.Info>
        <s.Title to={"/s/" + snippet.SnippetID}>
          {snippet.SnippetTitle ? snippet.SnippetTitle : "Untitled"}
        </s.Title>
        <s.PodcastName
          to={"/podcasts/" + snippet.PodcastID + "/" + snippet.PodcastName}
        >
          {snippet.PodcastName}
        </s.PodcastName>
        <s.EpisodeName
          to={
            "/podcasts/" +
            snippet.PodcastID +
            "/" +
            snippet.PodcastName +
            "/" +
            snippet.EpisodeID +
            "/" +
            snippet.EpisodeName
          }
        >
          {snippet.EpisodeName}
        </s.EpisodeName>
        <s.Description>
          {snippet.SnippetDescription
            ? snippet.SnippetDescription
            : "This snippet has no description"}
        </s.Description>
      </s.Info>
      <s.ShareContainer>
        <s.ShareLinkRow>
          <s.ShareText>Share Snippet</s.ShareText>
          <s.ShareLinkPositioning>
            <s.LinkIcon />
            <s.ShareLink onFocus={handleFocus}>{url}</s.ShareLink>
          </s.ShareLinkPositioning>
        </s.ShareLinkRow>
        <s.ShareIconButton onClick={handleClickOpen}>
          <s.ShareIconn />
        </s.ShareIconButton>
      </s.ShareContainer>
      <s.DeleteContainer>
        <div>
          <s.IconButtonn onClick={() => deleteEvent()}>
            <s.DeleteIcon />
          </s.IconButtonn>
        </div>
      </s.DeleteContainer>
      <s.Dialogg onClose={handleClose} open={open}>
        <s.DialogTitlee>Share</s.DialogTitlee>
        <s.DialogContentt>
          <s.DialogContentTextt>
            {" "}
            <s.ShareLinkPositioning>
              <s.LinkIcon />
              <s.ShareLinkMobile onFocus={handleFocus}>{url}</s.ShareLinkMobile>
            </s.ShareLinkPositioning>
          </s.DialogContentTextt>
        </s.DialogContentt>
        <s.DialogActionss>
          <s.Buttonn onClick={handleClose}>Close</s.Buttonn>
        </s.DialogActionss>
      </s.Dialogg>
    </s.Container>
  );
}
