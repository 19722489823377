import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

export const Container = styled.div`
`;

export const Buttonn = withStyles(() => ({
  root: {
    color: "white",
    outline: "none",
    backgroundColor: "#2F80ED",
    textTransform: "none",
    borderRadius: "5px",
    width: "160px",
    minWidth: "160px",
    height: "32px",
    minHeight: "32px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#007ac9",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "220px",
      minWidth: "220px",
      height: "42px",
      minHeight: "42px",
      borderRadius: "7px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "280px",
      minWidth: "280px",
      height: "56px",
      minHeight: "56px",
      borderRadius: "9px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "400px",
      minWidth: "400px",
      height: "84px",
      minHeight: "84px",
      borderRadius: "14px",
    },
  },
}))(Button);

export const Popoverr = withStyles(() => ({
  paper: {
    padding: "1rem",
    maxWidth: 300,
    "@media (min-width: 992px)": {
      maxWidth: 440,
    },
    "@media (min-width: 1920px)": {
      padding: "1.33333333333rem",
      borderRadius: 6,
      maxWidth: 580,
    },
    "@media (min-width: 2560px)": {
      padding: "1.77777777778rem",
      borderRadius: 8,
      maxWidth: 780,
    },
    "@media (min-width: 3840px)": {
      padding: "2.66666666667rem",
      borderRadius: 14,
      maxWidth: 1172,
    },
    "&&": {
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))(Popover);

export const InfoHeader = styled.h5`
  color: #4f4f4f;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;
  margin: 0;
  padding: 0;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333333rem;
  }
`;

export const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.375rem;
  @media (min-width: 2560px) {
    margin-top: 0.75rem;
  }
  @media (min-width: 3840px) {
    margin-top: 1.5rem;
  }
`;
