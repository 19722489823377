import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as s from "./StyledPodcastItem";
import ImageNotFound from "../../../../../assets/image_not_found.png";

const PodcastItem = ({ data }) => {
  const [error, setError] = useState(false);
  const cleanTitle = (data) => {
    return data.Title.includes("#") ? data.Title.replace("#", "") : data.Title;
  };
  return (
    <s.ItemContainer>
      <s.ItemImage>
        <Link
          to={{
            pathname: "/podcasts/" + data.ID + "/" + cleanTitle(data),
          }}
        >
          <s.ItemImageImg
            alt={data.Title}
            src={error ? ImageNotFound : data.Img}
            onError={(e) => {
              setError(true);
            }}
          />
        </Link>
      </s.ItemImage>
      <s.ItemDetail
        style={{
          marginTop: "1.777777777vh",
          color: "#262626",
        }}
      >
        {data.Title}
      </s.ItemDetail>
      <s.ItemDetail
        style={{
          marginTop: "0.0888888888vh",
          color: "#828282",
        }}
      >
        {data.Category}
      </s.ItemDetail>
    </s.ItemContainer>
  );
};

export default PodcastItem;
