import axios from "axios";
import { API } from "../constants";

/**
 * Read notifications for user
 *
 * @param userID: the userID
 * @param notificationID: the notificationID
 */
const readNotifications = async (userID, notificationID) => {
  return axios.post(API + "/api/notifications/read", {
    body: {
      notificationID: notificationID,
      userID: userID,
    },
    token: "token",
  });
};

export default readNotifications;
