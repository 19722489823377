import React from "react";
import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

export const Container = styled.div`
  margin-bottom: 140px;
  @media (min-width: 2560px) {
    margin-bottom: 160px;
  }
`;
export const Header = styled.div`
  color: #333333;
  font-size: 1.5rem;
  font-family: Proxima Nova Lt;

  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;
export const HeaderDescription = styled.p`
  color: #828282;
  font-size: 0.875rem;
  font-family: "Proxima Nova Lt";
  margin-top: 0.25rem;
  margin-bottom: 1rem;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-bottom: 1.33333333333rem;
    margin-top: 0.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-bottom: 1.77777777778rem;
    margin-top: 0.44444444444rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333333rem;
    margin-bottom: 2.66666666667rem;
    margin-top: 0.66666666666rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-top: 2.4vh;
  flex-direction: column;
  align-items: flex-start;
`;

export const Trending = styled.h2`
  color: #2f80ed;
  font-size: 1.125rem;
  margin-bottom: 0;
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const Tabss = withStyles(() => ({
  root: {
    marginBottom: "0.375rem",
    "@media (min-width: 992px)": {
      marginTop: "0.125rem",
    },
    "@media (min-width: 1920px)": {
      marginTop: "0.5rem",
      marginBottom: "1.125rem",
    },
    "@media (min-width: 2560px)": {
      marginTop: "1rem",
      marginBottom: "2rem",
    },
    "@media (min-width: 3840px)": {
      marginTop: "1.5rem",
      marginBottom: "3rem",
    },
  },
  indicator: {
    color: "transparent",
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
}))(Tabs);

export const Tabb = withStyles(() => ({
  root: {
    color: "#333333",
    opacity: "1",
    textTransform: "none",
    fontFamily: "Proxima Nova Lt",
    fontSize: "1.125rem",
    marginTop: "-0.25rem",
    marginRight: "32px",
    minWidth: "auto",
    padding: 0,
    letterSpacing: "initial",

    "@media (min-width: 576px)": {},
    "@media (min-width: 992px)": {},
    "@media (min-width: 1920px)": {
      fontSize: "1.5rem",
      marginRight: "64px",
    },
    "@media (min-width: 2560px)": {
      marginRight: "76px",

      fontSize: "2rem",
    },
    "@media (min-width: 3840px)": {
      fontSize: "3rem",
      marginRight: "108px",
    },
  },
  selected: {
    textDecoration: "underline",
  },
  wrapper: {
    textAlign: "start",
    padding: 0,
    alignItems: "start",
  },
}))((props) => <Tab disableRipple {...props} />);
