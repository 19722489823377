import React from "react";
import CommentItem from "./CommentItem";

const CommentList = ({ comments }) => {
  return (
    <>
      {comments.map((item) => {
        return <CommentItem item={item} />;
      })}
    </>
  );
};

export default CommentList;
