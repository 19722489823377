// get user 1
// import defaultProfile from "../../assets/user.svg";
import { API } from "../constants";
import axios from "axios";
import mixpanel from "mixpanel-browser";

/**
 * Will Change...
 *
 * @param {*} token: get it from window.localStorage.getItem("token")
 * @param {*} setUser
 */
const getCurrentUser = async (token, setUser) => {
  let api = API + "/api/users/token?token=" + token;
  return axios.get(api).then((data) => {
    console.log("getuser", data.data); // { "userId": 1, "id": 1, "title": "...", "body": "..." }
    setUser({
      email: data.data.Email,
      name: data.data.Name,
      username: data.data.Username,
      password: data.data.Password,
      bio: data.data.Bio,
      podcasts: data.data.MyPodcasts,
      image: data.data.Img,
      id: data.data.ID,
      followers: data.data.Followers,
      following: data.data.Following,
      likes: data.data.LikedEpisodes,
      comments: data.data.Comments,
      token: token,
      subscriptions: data.data.SubscribedEpisodes,
    });
    mixpanel.identify(data.data.ID);
    mixpanel.track("Logged In");
    return data.data;
  });

  //return {"ID" : 0, "UserID" : 0, "Username" : "caitlin", "Password" : "password", "Bio" : "bio", "Img" : "img", "Birthday" : "05/03/1999", "Ps": []}
};

export default getCurrentUser;
