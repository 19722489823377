import axios from "axios";
import { API } from "../constants";
/**
 * Adds a new audioboard object to the audioboard database and attaches an audioboard id to the creator.
 *
 * @param audioboard - audioboard object
 * @param token - token
 */
const addAudioBoard = async (data) => {
  return axios
    .post(API + "/api/audioboard/add", {
      body: {
        userID: data.userID,
        image: data.image,
        title: data.title,
        description: data.description,
        thumbnailColor: data.thumbnailColor,
      },
      token: window.localStorage.getItem("token"),
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default addAudioBoard;
