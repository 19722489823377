import axios from "axios";
import { API } from "../constants";

// Secure route, requires token

/**
 * removeLikes: function that makes a PUT request to update add userID to episodes likes Array
 *
 * @param {*} episodeID
 * @param {*} userID
 */
const removeLikes = async (episodeID, userID) => {
  axios
    .put(API + "/api/episode/unlike", {
      Body: {
        episodeID: episodeID,
        userID: userID,
      },
      Token: window.localStorage.getItem("token"),
    })
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
};

export default removeLikes;
