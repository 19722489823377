import axios from "axios";
import { API } from "../constants";

/**
 * likeSnippet: function that makes a PUT request to add userID to snippet likes Array
 *
 * @param {*} snippetID
 * @param {*} userID
 */
const likeSnippet = async (snippetID, userID) => {
  axios
    .put(API + "/api/snippet/like", {
      Body: {
        snippetID: snippetID,
        userID: userID,
      },
      Token: window.localStorage.getItem("token"),
    })
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
};

export default likeSnippet;
