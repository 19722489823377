import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const PodcastEpisodeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 28px;
  @media (min-width: 1200px) {
    width: 65vw;
    max-height: 175px;
    margin-bottom: 30px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 42px;
  }
  @media (min-width: 2560px) {
    max-height: 250px;
    margin-bottom: 56px;
  }
  @media (min-width: 3840px) {
    max-height: 325px;
    margin-bottom: 84px;
  }
`;

export const PodcastEpisodeImage = styled(LazyLoadImage)`
  cursor: pointer;
  border-radius: 5px;
  height: 48px;
  width: 48px;
  @media (min-width: 992px) {
    height: 72px;
    width: 72px;
    border-radius: 10px;
  }
  @media (min-width: 1200px) {
    height: 104px;
    width: 104px;
  }
  @media (min-width: 1920px) {
    height: 138px;
    width: 138px;
  }
  @media (min-width: 2560px) {
    height: 184px;
    width: 184px;
  }
  @media (min-width: 3840px) {
    height: 278px;
    width: 278px;
  }
`;

export const PodcastEpisodeTextContainer = styled.div`
  width: calc(80vw - 48px);

  @media (min-width: 992px) {
    width: calc(100% - 72px);
  }
  @media (min-width: 1200px) {
    width: calc(100% - 104px);
  }
  @media (min-width: 1920px) {
    width: calc(100% - 138px);
  }
  @media (min-width: 2560px) {
    width: calc(100% - 184px);
  }
  @media (min-width: 3840px) {
    width: calc(100% - 278px);
  }
`;

export const PodcastEpisodeText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  background: none;
  outline: none;
  border: none;
  margin-left: 16px;
  padding: 0;
  @media (min-width: 992px) {
    width: calc(100% - 32px);
    height: 72px;
    margin-left: 32px;
  }
  @media (min-width: 1200px) {
    height: 104px;
  }
  @media (min-width: 1920px) {
    height: 138px;
  }
  @media (min-width: 2560px) {
    height: 184px;
  }
  @media (min-width: 3840px) {
    height: 278px;
  }
  :active {
    outline: none;
  }
  :focus {
    outline: none;
  }
`;

export const PodcastEpisodeName = styled.div`
  color: #262626;
  font-size: 0.875rem;
  font-family: "Proxima Nova Lt";
  margin-bottom: 6px;
  :hover {
    text-decoration: underline;
  }

  @media (min-width: 992px) {
    font-family: "Proxima Nova";
    font-size: 1.125rem;
    color: #4f4f4f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const PodcastEpisodeSummary = styled.p`
  color: #828282;
  font-size: 0.75rem;
  overflow-y: auto;
  font-family: Proxima Nova Lt;
  font-weight: 400;
  height: 80px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  @media (min-width: 992px) {
    font-family: Proxima Nova;
    height: auto;
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.15rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.5rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333rem;
  }
`;

export const PodcastEpisodeImageContainer = styled.div`
  position: relative;
`;

export const PlayIcon = styled.img`
  && {
    width: 48px;
    height: 48px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
    margin-left: 2%;
  }
  @media (min-width: 992px) {
    && {
      height: 72px;
      width: 72px;
    }
  }
  @media (min-width: 1200px) {
    && {
      height: 104px;
      width: 104px;
    }
  }
  @media (min-width: 1920px) {
    && {
      height: 138px;
      width: 138px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 184px;
      width: 184px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 278px;
      width: 278px;
    }
  }
`;

export const PauseIcon = styled.img`
  && {
    width: 48px;
    height: 48px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
  }
  @media (min-width: 992px) {
    && {
      height: 72px;
      width: 72px;
    }
  }
  @media (min-width: 1200px) {
    && {
      height: 104px;
      width: 104px;
    }
  }
  @media (min-width: 1920px) {
    && {
      height: 138px;
      width: 138px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 184px;
      width: 184px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 278px;
      width: 278px;
    }
  }
`;

export const EpisodeButtonBox = styled.div`
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 1.5%;
  @media (min-width: 992px) {
    height: 72px;
    width: 72px;
  }
  @media (min-width: 1200px) {
    height: 104px;
    width: 104px;
  }
  @media (min-width: 1920px) {
    height: 138px;
    width: 138px;
  }
  @media (min-width: 2560px) {
    height: 184px;
    width: 184px;
  }
  @media (min-width: 3840px) {
    height: 278px;
    width: 278px;
  }
`;
