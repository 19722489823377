import React from "react";
import * as s from "./StyledNotificationItem";
import defaultProfile from "../../../../assets/DefaultProfile.svg";
import getTimeDifference from "../../../../utils/getTimeDifference";

const NotificationItem = ({ item, markNotificationRead }) => {
  console.log("notification item", item);

  const renderImage = (item) => {
    if (item.Type === "user") {
      return (
        <s.CreatorLink to={`/profile/${item.CreatorUsername}`}>
          <s.ProfileImage
            src={item.Image ? item.Image : defaultProfile}
            alt="profile"
            image={item.Image}
          />
        </s.CreatorLink>
      );
    } else if (item.Type === "podcast") {
      return (
        <s.CreatorLink to={`/podcasts/${item.CreatorID}/${item.CreatorName}`}>
          <s.PodcastImage
            src={item.Image ? item.Image : defaultProfile}
            alt="profile"
            image={item.Image}
          />
        </s.CreatorLink>
      );
    }
  };

  const renderMessage = (item) => {
    if (item.Type === "user") {
      return (
        <s.MessageText>
          <s.CreatorLink to={`/profile/${item.CreatorUsername}`}>
            {item.CreatorName}
          </s.CreatorLink>
          {item.Message}
        </s.MessageText>
      );
    } else if (item.Type === "podcast") {
      return (
        <s.MessageText>
          <s.CreatorLink to={`/podcasts/${item.CreatorID}/${item.CreatorName}`}>
            {item.CreatorName}
          </s.CreatorLink>
          {item.Message}
        </s.MessageText>
      );
    }
  };

  return (
    <s.NotificationItem img={item.Image}>
      <s.RowContainer>
        <s.CenterContainer>
          {item.Read ? <s.ReadDot /> : <s.UnreadDot />}
        </s.CenterContainer>

        {renderImage(item)}

        <s.CenterColumnContainer onClick={() => markNotificationRead()}>
          {renderMessage(item)}
          <s.TimeText>{getTimeDifference(item.Timestamp)}</s.TimeText>
        </s.CenterColumnContainer>
      </s.RowContainer>
    </s.NotificationItem>
  );
};

export default NotificationItem;
