import React, { useState, useEffect } from "react";
import * as s from "./StyledSignUp";
import Header from "../../shared_components/Header/Header";
import { Redirect } from "react-router-dom";
import { useSetStoreValue, useGetAndSet } from "react-context-hook";
import addUser from "../../../api/user/addUser";
import signIn from "../../../api/user/signIn";
// import oauthUser from "../../../api/user/oauthUser";
// import { ReactComponent as GIcon } from "../../../assets/google_logo.svg";
// import GoogleLogin from "react-google-login";
import getCurrentUser from "../../../api/user/getCurrentUser";

function validateEmail(email) {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

export default function SignUp() {
  const [loggedIn, setLoggedIn] = useGetAndSet("loggedIn");
  const [error, setError] = useState(false);
  const setUser = useSetStoreValue("user");
  const [playing, setPlaying] = useGetAndSet("playing");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (playing) {
      setPlaying(false);
    }
  });

  // const responseGoogle = (response) => {
  //   if (!("profileObj" in response)) {
  //     return;
  //   }
  //   console.log(response);
  //   console.log(response.profileObj.name.replace(/\s/g, "").toLowerCase());
  //   const email = response.profileObj.email;
  //   const username = response.profileObj.name.replace(/\s/g, "").toLowerCase();
  //   const name = response.profileObj.name;
  //   const imageUrl = response.profileObj.imageUrl;
  //   const image = imageUrl.slice(0, -5).concat("s400-c");
  //   oauthUser(username, name, email, image).then((response) => {
  //     console.log(response);
  //     if (response && response.Logged === true) {
  //       // log in the person
  //       getCurrentUser(response.Token, setUser);
  //       if (loggedIn === false) {
  //         setLoggedIn(true);
  //       }
  //     } else {
  //       setError(true);
  //     }
  //   });
  // };

  const signUp = () => {
    setIsDisabled(true);
    setError(true);
    setErrorMessage("");

    if (!email || !name || !username || !password || !password2) {
      setErrorMessage("A field cannot be left blank.");
      setError(true);
      setIsDisabled(false);
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email.");
      setError(true);
      setIsDisabled(false);
      return;
    }
    if (password !== password2) {
      setErrorMessage("Passwords don't match.");
      setError(true);
      setIsDisabled(false);
      return;
    }

    const user = {
      Email: email,
      Name: name,
      Password: password,
      Username: username,
    };

    addUser(user).then((response) => {
      if (response.data.error === "existing username") {
        setErrorMessage("Username is taken, please choose another.");
        setError(true);
        setIsDisabled(false);
        return;
      }
      if (response.data.error === "existing email") {
        setErrorMessage("Email already exists.");
        setError(true);
        setIsDisabled(false);
        return;
      }

      if (response.data.added === true) {
        if (loggedIn === false) {
          signIn(username, password).then((response) => {
            if (response.Logged === true) {
              setLoggedIn(true);
              setUser({
                email: email,
                name: name,
                username: username,
              });
              getCurrentUser(response.Token, setUser);
            } else {
              setErrorMessage("Unknown error :(");
              setError(true);
            }
          });
        }
      } else {
        setErrorMessage("Unknown error :(");
        setIsDisabled(false);
        setError(true);
      }
    });
  };

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        signUp();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <s.Container>
      <Header active_link="signup" />
      <s.SignupContainer>
        <s.WelcomeText>Join the Castyr Community!</s.WelcomeText>
        <s.InputContainer>
          <s.Inputs
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></s.Inputs>
          <s.Inputs
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          ></s.Inputs>
          <s.Inputs
            placeholder="Email"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          ></s.Inputs>
          <s.Inputs
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></s.Inputs>
          {
            <s.Inputs
              type="password"
              placeholder="Confirm Password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            ></s.Inputs>
          }
          {error && (
            // should handle more error cases
            <s.Error>{errorMessage}</s.Error>
          )}
        </s.InputContainer>

        <s.NextButton onClick={() => signUp()} disabled={isDisabled}>
          Sign Up
        </s.NextButton>
        {/* <GoogleLogin
          clientId="972338854953-3bbcln0hp5kt3r8fdcdhjku3ogha8b10.apps.googleusercontent.com"
          render={(renderProps) => (
            <s.GoogleButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <s.GoogleIcon>
                <GIcon style={{ width: "20%", height: "20%" }} />
                Sign Up
              </s.GoogleIcon>
            </s.GoogleButton>
          )}
          buttonText="Sign Up"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        /> */}

        <s.BottomText>
          <s.SignupText>
            By clicking sign up, you agree that you are 13 years of age or older{" "}
            <br></br> and understand our{" "}
            <s.StyledLink
              href="https://www.notion.so/caitlinpintavorn/Terms-of-Use-c8a47fa052b941919a8102ecbb412b7a"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </s.StyledLink>{" "}
            and{" "}
            <s.StyledLink
              href="https://www.notion.so/caitlinpintavorn/Privacy-Policy-d7e4469efc784eaabe1830e051e2bb70"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy.
            </s.StyledLink>
          </s.SignupText>
        </s.BottomText>

        {loggedIn && (
          <Redirect
            to={{
              pathname: "/profile",
              new: true,
            }}
          />
        )}
      </s.SignupContainer>
    </s.Container>
  );
}
