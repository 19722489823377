import React, { useState, useEffect } from "react";
import * as s from "./StyledPodcastEpisode";
import {
  useGetAndSet,
  useSetStoreValue,
  useStoreState,
} from "react-context-hook";
import { Link } from "react-router-dom";
import addHistory from "../../../../api/history/addHistory";
import ImageNotFound from "../../../../assets/image_not_found.png";
import PlayIcon from "../../../../assets/PlayIcon.svg";
import PauseIcon from "../../../../assets/PauseIcon.svg";

const PodcastEpisode = (props) => {
  const data = props.data;
  const name = props.name;
  const defaultPic = props.defaultPic;
  const podcastID = props.podcastID;

  const setUrl = useSetStoreValue("url");
  const [playing, setPlaying] = useGetAndSet("playing");
  const setPlayed = useSetStoreValue("played");
  const setLoaded = useSetStoreValue("loaded");
  const [currentImage, setCurrentImage] = useGetAndSet("currentImage");
  const setCurrentSeries = useSetStoreValue("currentSeries");
  const [currentTitle, setCurrentTitle] = useGetAndSet("currentTitle");
  const setListened = useSetStoreValue("listened");
  const { user } = useStoreState();
  const setCurrentEpisodeID = useSetStoreValue("currentEpisodeID");
  const setCurrentPodcastID = useSetStoreValue("currentPodcastID");
  const [style, setStyle] = useState({ display: "none" });
  const [episodeImg, setEpisodeImg] = useState("");

  const [error, setError] = useState(false);

  useEffect(() => {
    if (episodeImg === "") {
      if (data.Img !== "") {
        setEpisodeImg(data.Img);
      } else {
        if (defaultPic !== "") {
          setEpisodeImg(defaultPic);
        } else {
          setEpisodeImg(
            "https://images.pexels.com/photos/1108099/pexels-photo-1108099.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
          );
        }
      }
    }
  }, [data.Img, defaultPic, episodeImg]);

  let load = (episode, title) => {
    // conditiong where on the same episode and looking to pause
    if (playing && currentTitle === episode.Name) {
      setPlaying(false);
      // condition to play a new podcast episode
    } else {
      setUrl(episode.Key);
      setPlayed(0);
      setLoaded(0);
      setPlaying(true);
      setCurrentEpisodeID(episode.ID.toString());
      setCurrentImage(episodeImg);
      setCurrentSeries(title);
      setCurrentPodcastID(podcastID);
      setCurrentTitle(episode.Name);
      setListened(true);
      const today = new Date();
      const episodeBody = {
        datetime: today.getTime().toString(),
        episodeID: episode.ID.toString(),
        podcastID: podcastID.toString(),
        timestamp: "0".toString(),
        userID: user.id ? user.id.toString() : user.id,
      };
      const token = window.localStorage.getItem("token");
      addHistory(episodeBody, token).then((response) => console.log(response));
    }
  };

  return (
    <s.PodcastEpisodeContainer>
      <s.PodcastEpisodeImageContainer>
        <s.PodcastEpisodeImage
          alt={props.data.Name}
          onClick={() => load(props.data, props.name)}
          src={error ? ImageNotFound : episodeImg}
          onError={(e) => setError(true)}
        />
        <s.EpisodeButtonBox
          onMouseEnter={(e) => {
            setStyle({ display: "block" });
          }}
          onMouseLeave={(e) => {
            setStyle({ display: "none" });
          }}
          onClick={() => load(props.data, props.name)}
        >
          <div style={style}>
            {playing && currentImage != null && data.Name === currentTitle ? (
              <s.PauseIcon
                src={PauseIcon}
                onClick={() => load(data, name)}
                alt="pause"
              />
            ) : (
              <s.PlayIcon
                src={PlayIcon}
                onClick={() => load(data, name)}
                alt="play"
              />
            )}
          </div>
        </s.EpisodeButtonBox>
      </s.PodcastEpisodeImageContainer>
      <s.PodcastEpisodeTextContainer>
        <s.PodcastEpisodeText>
          <Link
            to={
              "/podcasts/" +
              podcastID +
              "/" +
              name +
              "/" +
              data.ID +
              "/" +
              data.Name
            }
            style={{
              textDecoration: "none",
              cursor: "pointer",
              outline: "none",
            }}
          >
            <s.PodcastEpisodeName>{data.Name}</s.PodcastEpisodeName>
          </Link>

          <s.PodcastEpisodeSummary
            dangerouslySetInnerHTML={{ __html: data.Description }}
          ></s.PodcastEpisodeSummary>
        </s.PodcastEpisodeText>
      </s.PodcastEpisodeTextContainer>
      {/* Redirects to the episode */}
    </s.PodcastEpisodeContainer>
  );
};

export default PodcastEpisode;
