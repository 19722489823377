import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 22px;
  width: 300px;
  margin-bottom: 0.75rem;
  @media (min-width: 576px) {
    width: 450px;
  }
  @media (min-width: 1920px) {
    width: 600px;
    margin-bottom: 1rem;
    padding-left: 28px;
    padding-right: 28px;
  }
  @media (min-width: 2560px) {
    width: 800px;
    margin-bottom: 1.375rem;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 3840px) {
    width: 1200px;
    margin-bottom: 2rem;
    padding-left: 58px;
    padding-right: 58px;
  }
`;

export const ProfileInformation = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProfilePicture = styled.img`
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 50%;
  @media (min-width: 1920px) {
    height: 42px;
    width: 42px;
  }
  @media (min-width: 2560px) {
    height: 56px;
    width: 56px;
  }
  @media (min-width: 3840px) {
    height: 84px;
    width: 84px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-drection: row;
`;

export const ColumnCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1920px) {
    margin-left: 0.125rem;
  }
  @media (min-width: 2560px) {
    margin-left: 0.25rem;
  }
  @media (min-width: 3840px) {
    margin-left: 0.75rem;
  }
`;

export const NameText = styled.p`
  font-size: 0.75rem;
  color: #4f4f4f;
  margin: 0;
  margin-top: 0.125rem;
  font-family: Proxima Nova Lt;
  @media (min-width: 1920px) {
    font-size: 1rem;
    margin-top: 0.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
  }
`;

export const LinkContainer = styled.div`
  margin-left: 10px;
`;

export const UsernameText = styled.p`
  font-size: 0.75rem;
  color: #4f4f4f;
  margin: 0;
  margin-top: -0.125rem;
  font-family: Proxima Nova Lt;
  @media (min-width: 1920px) {
    font-size: 1rem;
    margin-top: -0.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
    margin-top: -0.5rem;
  }
`;

export const FollowButton = withStyles((theme) => ({
  root: {
    color: "white",
    outline: "none",
    backgroundColor: "#0071E2",
    textTransform: "none",
    borderRadius: "30px",
    width: "110px",
    minWidth: "110px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "4px",
    marginBottom: "8px",
    "&:hover": {
      backgroundColor: "#007ac9",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "150px",
      minWidth: "150px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "200px",
      minWidth: "200px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "300px",
      minWidth: "300px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
}))(Button);

export const UnfollowButton = withStyles((theme) => ({
  root: {
    color: "white",
    outline: "none",
    backgroundColor: "#BDBDBD",
    textTransform: "none",
    borderRadius: "30px",
    width: "110px",
    minWidth: "110px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "4px",
    marginBottom: "8px",
    "&:hover": {
      backgroundColor: "#4F4F4f",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "150px",
      minWidth: "150px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "200px",
      minWidth: "200px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "300px",
      minWidth: "300px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
}))(Button);

export const ButtonContainer = styled.div`
  display: ${(props) => (props.self ? "none" : "block")};
`;
