import React, { useEffect, useState } from "react";
import * as s from "./StyledLibraryNav";
import HistoryActive from "../../../../assets/LibraryPageAssets/history_active.svg";
import HistoryNonActive from "../../../../assets/LibraryPageAssets/history_non_active.svg";
import LikesActive from "../../../../assets/LibraryPageAssets/likes_active.svg";
import LikesNonActive from "../../../../assets/LibraryPageAssets/likes_non_active.svg";
import SnippetsActive from "../../../../assets/LibraryPageAssets/snippets_active.svg";
import SnippetsNonActive from "../../../../assets/LibraryPageAssets/snippets_non_active.svg";
import SubscriptionActive from "../../../../assets/LibraryPageAssets/subscription_active.svg";
import SubscriptionNonActive from "../../../../assets/LibraryPageAssets/subscription_non_active.svg";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

const LibraryNav = ({ activePage, setActivePage, loggedIn }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [value, setValue] = React.useState(loggedIn ? 0 : 4);
  const handleChange = (event, newValue) => {
    if (!loggedIn) {
      return;
    }
    if (newValue === 0) {
      setActivePage("history");
    } else if (newValue === 1) {
      setActivePage("subscriptions");
    } else if (newValue === 2) {
      setActivePage("likes");
    } else if (newValue === 3) {
      setActivePage("snippets");
    }
    setValue(newValue);
  };
  const variant = windowDimensions.width < 576 ? "fullWidth" : "standard";
  const likedText = windowDimensions.width < 992 ? "LIKED" : "LIKED EPISODES";

  useEffect(() => {
    if (loggedIn) {
      setValue(0);
    }
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [loggedIn]);

  return (
    <s.Container>
      <s.Tabss
        value={value}
        onChange={handleChange}
        variant={variant}
        textColor="secondary"
      >
        <s.Tabb
          label={
            <>
              <s.SmallIcon
                src={value === 0 ? HistoryActive : HistoryNonActive}
              />
              HISTORY
            </>
          }
          disabled={!loggedIn}
        />
        <s.LongTabb
          style={{}}
          label={
            <>
              <s.Icon
                src={value === 1 ? SubscriptionActive : SubscriptionNonActive}
              />
              SUBSCRIPTIONS
            </>
          }
          disabled={!loggedIn}
        />
        <s.Tabb
          label={
            <>
              <s.Icon src={value === 2 ? LikesActive : LikesNonActive} />
              {likedText}
            </>
          }
          disabled={!loggedIn}
        />
        <s.Tabb
          label={
            <>
              <s.Icon src={value === 3 ? SnippetsActive : SnippetsNonActive} />
              SNIPPETS
            </>
          }
          disabled={!loggedIn}
        />
        <s.NoShowTab />
      </s.Tabss>
    </s.Container>
  );
};

export default LibraryNav;
