import axios from "axios";
import { API } from "../constants";

/**
 * Get comments for an episodee
 *
 * @param snippetID: the snippet ID
 */
const getSnippetComments = async (snippetID) => {
  let api = API + "/api/snippet/comments?SnippetID=" + snippetID;
  return axios.get(api).then((data) => {
    return data.data;
  });
};

export default getSnippetComments;
