import React, { useEffect, useState } from "react";
import Footer from "../../shared_components/Footer/Footer";
import { useSetStoreValue } from "react-context-hook";
import Header from "../../shared_components/Header/Header";
import * as s from "./StyledLandingPage";
import TweetEmbed from "react-tweet-embed";
import { useHistory } from "react-router-dom";
import SquiggleVector from "../../../assets/squiggle.svg";
import CloudsVector from "../../../assets/Clouds.svg";
import WavesVector from "../../../assets/Wavess.svg";
import HeadphonesVector from "../../../assets/headphones_mic.svg";

const LandingPage = () => {
  const setPlaying = useSetStoreValue("playing");
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!loaded) {
      setPlaying(false);
      setLoaded(true);
    }
  }, [loaded, setPlaying]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage:
          "linear-gradient(0deg, #c5e2ff 0%, rgba(255,255,255,0) 41%), linear-gradient(319deg, #d5eeff 45%, rgba(255,255,255,0) 85%), linear-gradient(0deg, #f4faff 100%, rgba(255,255,255,0) 100%)",
      }}
    >
      <Header isLandingPage={true} />
      <s.Container>
        <s.TextContainer>
          <s.HeaderH1>
            Like TikTok and Goodreads{" "}
            <s.HeaderH1Span>had a podcast baby.</s.HeaderH1Span>
          </s.HeaderH1>
          <s.HeaderAltH1>See you on the airwavesss.</s.HeaderAltH1>
          <s.PHLink
            href={
              "https://www.producthunt.com/posts/castyr?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-castyr"
            }
            target="_blank"
          >
            <s.PHImg
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=298176&theme=light"
              alt="Castyr - Like TikTok and Goodreads had a podcast baby | Product Hunt"
            />
          </s.PHLink>

          <s.CloudsVector src={CloudsVector} />

          <s.WavesVector src={WavesVector} />

          <s.ForTheListenerContainer>
            <s.VerticalCenter>
              <s.SquiggleVector src={SquiggleVector} />
              <s.HeaderH2>For the avid listener</s.HeaderH2>
            </s.VerticalCenter>
            <s.HeadphonesVector src={HeadphonesVector} />

            <s.HeaderH3>
              Discover the next top podcaster before they get big.
            </s.HeaderH3>
            <s.PContent>
              Castyr’s staff-curated content library surfaces podcasts from your
              peers and up-and-coming creators. Keep track of your favorite
              finds through Listening History, Subscriptions, and Likes.
            </s.PContent>
            <s.HeaderH3>
              Clip and take notes on your favorite parts of a episode.
            </s.HeaderH3>
            <s.PContent>
              Tired of using paper or 1 of your 10 notetaking apps to remember
              your favorite parts of an episode? With Audio Snippeting, you can
              clip episode audio directly and attach notes to it.
            </s.PContent>
            <s.HeaderH3>
              Curate and share your audio snippets with the world.
            </s.HeaderH3>
            <s.PContent>
              You can add any snippet on Castyr to different Audio Boards in
              your profile for easy access. You can also share your own snippets
              to the community feed, where you can see, listen to, and
              like/comment on snippets from users you follow.
            </s.PContent>
            <s.CenterContent>
              <s.PrimaryButton onClick={() => history.push("/signup")}>
                Start Listening
              </s.PrimaryButton>
            </s.CenterContent>
          </s.ForTheListenerContainer>
          <s.ForThePodcasterContainer>
            <s.VerticalCenter>
              <s.SquiggleVector src={SquiggleVector} />
              <s.HeaderH2>For the up-and-coming podcaster</s.HeaderH2>
            </s.VerticalCenter>
            <s.HeaderH3>
              Grow your podcast and personal brand at the same time.
            </s.HeaderH3>
            <s.PContent>
              Create a followable, personal profile attached to your podcast so
              you can connect with your listeners 1-on-1. No more one-sided
              interactions.
            </s.PContent>
            <s.HeaderH3>
              See what your listeners like best about your content with our
              analytics dashboard.*
            </s.HeaderH3>
            <s.PContent>
              With Castyr’s granular data on your fans’ listens, audio snippets,
              comments, and likes, you can create better, more engaging content.
            </s.PContent>
            <s.HeaderH3>Monetize through fan engagement.*</s.HeaderH3>
            <s.PContent>
              Loyal listeners can use Castyr Dubs*, our in-app currency to
              purchase merchandise, private chat rooms, and exclusive broadcasts
              with you. They earn these by using social features on the platform
              or by purchasing them directly.
            </s.PContent>
            <s.CenterContent>
              <s.PrimaryButton onClick={() => history.push("/signup")}>
                Get Started
              </s.PrimaryButton>
            </s.CenterContent>
          </s.ForThePodcasterContainer>
        </s.TextContainer>
      </s.Container>

      <s.TweetWallContainer>
        <s.TweetHeader>Just trying to make you feel seen</s.TweetHeader>

        <s.TweetWall>
          <s.Tweet>
            <TweetEmbed
              id="1371561800269852674"
              options={{ conversation: "none" }}
            />
          </s.Tweet>
          <s.Tweet>
            <TweetEmbed
              id="1340418871879069696"
              options={{ conversation: "none" }}
            />
          </s.Tweet>
          <s.Tweet>
            <TweetEmbed
              id="1346273402944950272"
              options={{ conversation: "none" }}
            />
          </s.Tweet>
          <s.Tweet>
            <TweetEmbed
              id="1353740873314439177"
              options={{ conversation: "none" }}
            />
          </s.Tweet>
          <s.Tweet>
            <TweetEmbed
              id="1335999182054821890"
              options={{ conversation: "none" }}
            />
          </s.Tweet>
          <s.Tweet>
            <TweetEmbed
              id="965716881054273537"
              options={{ conversation: "none" }}
            />
          </s.Tweet>
        </s.TweetWall>
      </s.TweetWallContainer>
      <s.DisclaimerContainer>
        <s.DisclaimerText>*Coming soon :)</s.DisclaimerText>
      </s.DisclaimerContainer>
      <Footer />
    </div>
  );
};

export default LandingPage;
