import React, { useContext } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { AudioPlayerContext } from "./AudioPlayerContext";
import { useStoreState, useGetAndSet } from "react-context-hook";
import addHistory from "../../../../api/history/addHistory";

const ReactPlayerContainer = styled.div`
  width: 0;
  height: 0;
`;

export default function AudioPlayer() {
  const {
    controls,
    volume,
    muted,
    setDuration,
    playbackRate,
    progressInterval,
    loop,
    setSeconds,
    player,
    playerSeekTo,
    seeking,
  } = useContext(AudioPlayerContext);

  const [loggedIn] = useGetAndSet("loggedIn");
  const [user] = useGetAndSet("user");

  const { url, playing, currentEpisodeID, currentPodcastID } = useStoreState();

  const [recentlyListened, setRecentlyListened] = useGetAndSet(
    "recentlyListened"
  );

  /**
   * handleDuration: function for reactplayer to set global duration of audio file
   */
  const handleDuration = (duration) => {
    setDuration(duration);
  };

  /**
   * handleProgress: function to update seconds elapsed in global state
   */
  const handleProgress = (state) => {
    if (seeking) {
      return;
    }
    setSeconds(parseInt(state.playedSeconds));
  };

  /**
   * handleStart: function to start podcast at most recently listened time
   */
  const handleStart = (state) => {
    // condition where we have recently listened podcasts
    if (recentlyListened.length >= 1) {
      const checkIdx = recentlyListened.findIndex(
        (i) => i.EpisodeID === currentEpisodeID
      );
      if (checkIdx !== -1) {
        setSeconds(parseInt(recentlyListened[checkIdx].StoppedAt));
        playerSeekTo(parseInt(recentlyListened[checkIdx].StoppedAt));
      }
    } else {
      setSeconds(0);
      playerSeekTo(0);
    }

    // tracking seconds listened to only if logged in
    if (loggedIn) {
      const today = new Date();
      // this is the podcast info
      const localBody = {
        LastPlayed: today.getTime().toString(),
        EpisodeID: currentEpisodeID.toString(),
        PodcastID: currentPodcastID.toString(),
        StoppedAt: "0",
      };

      const episodeIdx = recentlyListened.findIndex(
        (i) => i.EpisodeID === currentEpisodeID
      );

      // make sure that if the episode already exists, replace it
      if (episodeIdx !== -1) {
        const copyRecentlyListened = [...recentlyListened];
        copyRecentlyListened[episodeIdx] = localBody;
        setRecentlyListened(copyRecentlyListened);
      } else {
        setRecentlyListened([...recentlyListened, localBody]);
      }

      const token = window.localStorage.getItem("token");

      const episodeBody = {
        datetime: today.getTime().toString(),
        episodeID: currentEpisodeID.toString(),
        podcastID: currentPodcastID.toString(),
        timestamp: "0",
        userID: user.id.toString(),
      };
      addHistory(episodeBody, token).then((response) => console.log(response));
    }
  };

  return (
    <ReactPlayerContainer>
      <ReactPlayer
        ref={player}
        url={url}
        playing={playing}
        controls={controls}
        loop={loop}
        playbackRate={playbackRate}
        progressInterval={progressInterval}
        volume={volume}
        muted={muted}
        onStart={handleStart}
        onProgress={handleProgress}
        onDuration={handleDuration}
        style={{ display: "none" }}
      />
    </ReactPlayerContainer>
  );
}
