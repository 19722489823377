import React from "react";
import * as s from "./StyledTeamDisplay";
import TeamItem from "../TeamItem/TeamItem";

let team_data = [
  [
    "Caitlin",
    "CEO, Co-Founder",
    "Crying In Public",
    "https://www.linkedin.com/in/caitlin-pintavorn/",
    "/podcasts/e9120c7e-904a-4128-9930-36afbe2fccd7/Crying%20In%20Public",
  ],
  [
    "Aric",
    "CTO, Co-Founder",
    "BreakYourBubble",
    "https://www.linkedin.com/in/aric-zhuang/",
    "/podcasts/52e73999-5f3a-4d9f-a66d-ee62fca16ec6/BreakYourBubble",
  ],
  [
    "Stephanie",
    "Head UI/UX Designer",
    "The Ball Boys",
    "https://www.linkedin.com/in/stephanieparkhello/",
    "/podcasts/ea41d1a2-053d-4596-a8c0-2d79cb0e4453/The%20Ball%20Boys",
  ],
  [
    "Nick",
    "Frontend SWE",
    "Unearthed",
    "https://www.linkedin.com/in/guaca/",
    "/podcasts/26234c7f-fbce-43b0-8d8a-b75f59cfa03a/Unearthed",
  ],
  [
    "Ryan",
    "Frontend SWE",
    "Show-N-Tell",
    "https://www.linkedin.com/in/ryanhkim/",
    "/podcasts/639b5a4a-f73c-46cd-947e-d7b86ddb5dea/Show-N-Tell",
  ],
  [
    "Ethan",
    "Backend SWE",
    "Founders Couch",
    "https://www.linkedin.com/in/ethan-chung-625480173/",
    "/podcasts/5df88ef8-3dbe-451b-a4a9-cc4b1e828dba/Founders%20Couch",
  ],
  [
    "Christine",
    "Brand & Social Manager",
    "Rizdeology",
    "https://www.linkedin.com/in/christine-koh-9b7491179/",
    "/podcasts/8703dffd-20b8-4320-8579-8b7513207cbc/rizdeology",
  ],
];

function renderItems(data) {
  const items = data.map((item) => {
    const name = item[0];
    const role = item[1];
    const pod = item[2];
    const linkedin = item[3];
    const podURL = item[4];
    return (
      <TeamItem
        name={name}
        role={role}
        pod={pod}
        linkedin={linkedin}
        podURL={podURL}
      />
    );
  });

  return items;
}

export default class TeamDisplay extends React.Component {
  render() {
    return (
      <s.HeaderContainer>
        <s.HeaderText>Meet The Team</s.HeaderText>
        <s.HeaderContent>
          We started Castyr after launching and scaling an FM radio station in
          Providence, RI to tens of thousands of loyal listeners. We’re avid
          podcasters and listeners from Google, Facebook, Microsoft, Two Sigma,
          Etsy, and Attentive.
        </s.HeaderContent>
        <s.TeamContainer>{renderItems(team_data)}</s.TeamContainer>
      </s.HeaderContainer>
    );
  }
}
