import React, { useState, useEffect } from "react";
import defaultProfile from "../../../../assets/DefaultProfile.svg";
import * as s from "./StyledDisplayProfile";
import { useGetAndSet, useSetStoreValue } from "react-context-hook";
import updateUser from "../../../../api/user/updateUser";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import FollowInfoModal from "./FollowModal/FollowInfoModal";
import followUser from "../../../../api/user/followUser";
import unfollowUser from "../../../../api/user/unfollowUser";
import checkIfUsernameExists from "../../../../api/user/checkIfUsernameExists";
import PodcastItem from "./PodcastItem/PodcastItem";
import { ReactComponent as CreatorTag } from "../../../../assets/creator.svg";
import AudioBoards from "./AudioBoards/AudioBoards";

export default function DisplayProfile(props) {
  const [user, setUser] = useGetAndSet("user");
  const [isEdit, setIsEdit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [beforeEditUsername, setBeforeEditUsername] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [usernameExists, setUsernameExists] = useState("");
  const [followersModal, setFollowersModal] = useState(false);
  const [followingModal, setFollowingModal] = useState(false);
  const setAttemptedLogin = useSetStoreValue("attemptedLogin");
  const profile = props.propsUser.ID ? props.propsUser : user;
  const [timer, setTimer] = React.useState();
  const placeholder = props.propsUser
    ? "This user has no description yet."
    : "Tell the Castyr Community about yourself... (in 300 characters or less!)";
  const [loggedIn, setloggedIn] = useGetAndSet("loggedIn");
  const history = useHistory();

  const uploadImg = () => {
    const getUploadResult = (resultEvent) => {
      if (resultEvent.event === "success") {
        let url = resultEvent.info.secure_url;
        setUser({ ...user, image: url });
      }
    };

    const myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "dd2tkx3cg",
        uploadPreset: "ml_default",
        cropping: false,
      },
      (error, result) => {
        getUploadResult(result);
      }
    );

    myWidget.open();
  };

  useEffect(() => {
    if (props.propsUser.ID === user.id) {
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit) {
      clearTimeout(timer);
      if (beforeEditUsername === username) {
        setUsernameExists("");
        return;
      }
      setTimer(
        setTimeout(() => {
          triggerUpdate();
        }, 500)
      );
    }
  }, [username]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.propsUser.ID) {
      setName(profile.Name);
      setBeforeEditUsername(profile.Username);
      setUsername(profile.Username);
      setDescription(profile.Bio);
      setImg(profile.Img);
    } else {
      setName(profile.name);
      setBeforeEditUsername(profile.username);
      setUsername(profile.username);
      setDescription(profile.bio);
      setImg(profile.image);
    }
    if (props.propsUser.Followers) {
      setFollowers(props.propsUser.Followers);
    }
    if (user.following) {
      setFollowing(user.following);
    }
  }, [profile, user, props.propsUser.ID, props.propsUser.Followers]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const saveProfile = () => {
    setUsernameExists("");
    updateUser({
      Bio: description,
      Img: user.image,
      Name: name,
      Username: username,
    });
    setUser({ ...user, username: username, name: name, bio: description });
  };
  const saveButton = (
    <s.ButtonContainer>
      <s.EditSaveButton
        onClick={() => {
          setIsEdit(false);
          saveProfile();
        }}
        disabled={usernameExists === "t"}
      >
        Save
      </s.EditSaveButton>
    </s.ButtonContainer>
  );

  let editButtons;

  const handleFollow = (userID, followerID) => {
    if (userID) {
      followUser(userID, followerID);
      setFollowing([...following, userID]);
      setFollowers([...followers, followerID]);
    }
  };

  const handleModalFollow = (userID, followerID) => {
    if (userID) {
      followUser(userID, followerID);
      setFollowing([...following, userID]);
    }
  };

  const handleUnfollow = (userID, unfollowerID) => {
    if (userID) {
      unfollowUser(userID, unfollowerID);
      setFollowing(following.filter((item) => item !== userID));
      setFollowers(followers.filter((item) => item !== unfollowerID));
    }
  };

  const handleModalUnfollow = (userID, unfollowerID) => {
    if (userID) {
      unfollowUser(userID, unfollowerID);
      setFollowing(following.filter((item) => item !== userID));
    }
  };

  if (user.id !== "" && props.propsUser && props.propsUser.ID === "") {
    editButtons = (
      <s.ButtonContainer>
        <s.EditSaveButton
          onClick={() => {
            setIsEdit(true);
          }}
        >
          Edit Profile
        </s.EditSaveButton>
        <s.LogoutButton
          onClick={() => {
            if (loggedIn) {
              setloggedIn(false);
            }
            window.localStorage.removeItem("token");
            setAttemptedLogin(false);
            setUser({
              email: "",
              name: "",
              username: "",
              password: "",
              bio: "",
              podcasts: [],
              image: defaultProfile,
              token: "",
              id: "",
            });
            history.push("/explore");
          }}
        >
          Log out
        </s.LogoutButton>
      </s.ButtonContainer>
    );
  } else {
    editButtons = (
      <s.ButtonContainer>
        <s.FollowButtonContainer loggedIn={loggedIn}>
          {following.includes(props.propsUser.ID) ? (
            <s.UnfollowButton
              onClick={() => handleUnfollow(props.propsUser.ID, user.id)}
            >
              Unfollow
            </s.UnfollowButton>
          ) : (
            <s.FollowButton
              onClick={() => handleFollow(props.propsUser.ID, user.id)}
            >
              Follow
            </s.FollowButton>
          )}
        </s.FollowButtonContainer>
      </s.ButtonContainer>
    );
  }

  const displayFollowers = () => {
    setFollowersModal(true);
  };

  const displayFollowing = () => {
    setFollowingModal(true);
  };

  const closeFollowers = () => {
    setFollowersModal(false);
  };

  const closeFollowing = () => {
    setFollowingModal(false);
  };

  const triggerUpdate = async () => {
    // Use a string instead of true/false so we can have 3 states,
    // this way the username exists error/success message
    // will only come up when the user edits the username input.
    const usernameExists = await checkIfUsernameExists(username);
    const string = usernameExists ? "t" : "f";
    setUsernameExists(string);
  };

  const renderPodcasts = (ids) => {
    return ids.map((id) => <PodcastItem id={id} />);
  };

  return (
    <div>
      <s.CenterContainer>
        {redirect && <Redirect to="/profile" />}
        <s.Container style={{ marginBottom: 0 }}>
          <s.DesktopPictureContainer>
            {isEdit ? <s.AddIcon onClick={uploadImg}></s.AddIcon> : <div />}
            <s.DesktopProfilePicture
              src={img ? img : defaultProfile}
              onClick={isEdit ? uploadImg : null}
            ></s.DesktopProfilePicture>
            <s.DesktopFollowContainer>
              {props.propsUser.MyPodcasts &&
                props.propsUser.MyPodcasts.length >= 1 && (
                  <s.CreatorTagContainer>
                    <CreatorTag />
                  </s.CreatorTagContainer>
                )}

              <s.FollowItem onClick={displayFollowers}>
                <s.FollowText>
                  {props.propsUser.ID
                    ? followers
                      ? followers.length
                      : 0
                    : user.followers
                    ? user.followers.length
                    : 0}{" "}
                  Followers
                </s.FollowText>
              </s.FollowItem>
              <s.FollowItem onClick={displayFollowing}>
                <s.FollowText>
                  {props.propsUser.ID
                    ? props.propsUser.Following
                      ? props.propsUser.Following.length
                      : 0
                    : user.following
                    ? user.following.length
                    : 0}{" "}
                  Following
                </s.FollowText>
              </s.FollowItem>
            </s.DesktopFollowContainer>
          </s.DesktopPictureContainer>

          <s.InfoContainer>
            <s.HeaderContainer>
              {isEdit ? (
                <s.NameContainer>
                  <s.Name
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Enter your name here"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  ></s.Name>
                  <s.Username
                    value={username}
                    onChange={handleUsernameChange}
                    InputProps={{
                      startAdornment: <s.Prefix position="start">@</s.Prefix>,
                      disableUnderline: true,
                    }}
                    placeholder="Enter your desired username here"
                    variant="filled"
                  ></s.Username>
                  {usernameExists === "f" && (
                    <s.UsernameSuccess>
                      This username is available.
                    </s.UsernameSuccess>
                  )}
                  {usernameExists === "t" && (
                    <s.UsernameError>This username is taken.</s.UsernameError>
                  )}
                </s.NameContainer>
              ) : (
                <s.NameContainer>
                  <s.Name
                    value={name}
                    disabled
                    InputProps={{ disableUnderline: true }}
                    placeholder="No name given"
                  ></s.Name>
                  <s.Username
                    value={"@" + username}
                    disabled
                    InputProps={{ disableUnderline: true }}
                  ></s.Username>
                </s.NameContainer>
              )}
              {isEdit ? saveButton : editButtons}
            </s.HeaderContainer>

            <s.MobileProfilePictureContainer>
              <s.MobilePictureContainer>
                {isEdit ? (
                  <s.MobileAddIcon onClick={uploadImg}></s.MobileAddIcon>
                ) : (
                  <div />
                )}
                <s.MobileProfilePicture
                  src={img ? img : defaultProfile}
                  onClick={isEdit ? uploadImg : null}
                ></s.MobileProfilePicture>
              </s.MobilePictureContainer>
            </s.MobileProfilePictureContainer>
            <s.MobileFollowContainer>
              {props.propsUser.MyPodcasts &&
                props.propsUser.MyPodcasts.length >= 1 && (
                  <s.CreatorTagContainer>
                    <CreatorTag />
                  </s.CreatorTagContainer>
                )}
              <s.FollowItem onClick={displayFollowers}>
                <s.FollowText>
                  {props.propsUser.ID
                    ? followers
                      ? followers.length
                      : 0
                    : user.followers
                    ? user.followers.length
                    : 0}{" "}
                  Followers
                </s.FollowText>
              </s.FollowItem>
              <s.FollowItem onClick={displayFollowing}>
                <s.FollowText>
                  {props.propsUser.ID
                    ? props.propsUser.Following
                      ? props.propsUser.Following.length
                      : 0
                    : user.following
                    ? user.following.length
                    : 0}{" "}
                  Following
                </s.FollowText>
              </s.FollowItem>
            </s.MobileFollowContainer>

            <s.About>About</s.About>
            {isEdit ? (
              <s.Description
                multiline
                rows={4}
                value={description}
                onChange={handleDescriptionChange}
                InputProps={{ disableUnderline: true }}
                placeholder={"Enter your description here."}
                inputProps={{ maxLength: 300 }}
                variant="filled"
              ></s.Description>
            ) : (
              <s.Description
                disabled
                multiline
                rows={4}
                value={description}
                InputProps={{ disableUnderline: true }}
                placeholder={placeholder}
                inputProps={{ maxLength: 300 }}
              ></s.Description>
            )}
          </s.InfoContainer>
        </s.Container>
      </s.CenterContainer>
      {props.propsUser.MyPodcasts && props.propsUser.MyPodcasts.length >= 1 && (
        <s.CenterContainer>
          <s.Container>
            <s.ColumnContainer>
              <s.ContentHeader>{name.split(" ")[0]}'s Podcasts</s.ContentHeader>
              <s.PodcastContainer>
                {renderPodcasts(props.propsUser.MyPodcasts)}
              </s.PodcastContainer>
            </s.ColumnContainer>
          </s.Container>
        </s.CenterContainer>
      )}
      <AudioBoards pageUserId={props.propsUser.ID}></AudioBoards>
      <FollowInfoModal
        type={"FOLLOWING"}
        open={followingModal}
        closeModal={closeFollowing}
        data={props.propsUser.ID ? props.propsUser.Following : user.following}
        following={following}
        handleFollow={handleModalFollow}
        handleUnfollow={handleModalUnfollow}
        handleClose={closeFollowing}
      />
      <FollowInfoModal
        type={"FOLLOWERS"}
        open={followersModal}
        closeModal={closeFollowers}
        data={props.propsUser.ID ? followers : user.followers}
        following={following}
        handleFollow={handleModalFollow}
        handleUnfollow={handleModalUnfollow}
        handleClose={closeFollowers}
      />
    </div>
  );
}
