import axios from "axios";
import { API } from "../constants";

/**
 * Adds a new user to the database
 *
 * @param {user: 
    Username: the "unique" username for the person
    Password: the password assoicated with this person's account
    Name: the Name of the person
    Email: the person's email 
 * }
 * */
const addUser = async (user) => {
  return axios
    .post(API + "/api/users/add", {
      body: {
        email: user.Email,
        name: user.Name,
        password: user.Password,
        username: user.Username,
      },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => console.log(error));
};

export default addUser;
