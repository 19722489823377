import React, { useEffect, useState } from "react";
import { useStoreState } from "react-context-hook";
import PodcastItem from "./PodcastItem/PodcastItem";
import * as s from "./StyledSubscriptions";

const Subscriptions = () => {
  const { user } = useStoreState();
  const [podcasts, setPodcasts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (user && user.subscriptions) {
      setPodcasts(user.subscriptions);
      setLoaded(true);
    }
  }, [user]);

  // function to render each recently played episode item
  const renderItems = () => {
    const items = (
      <s.GridContainer>
        {podcasts.map((p) => {
          return <PodcastItem id={p} />;
        })}
      </s.GridContainer>
    );

    if (podcasts.length === 0) {
      return (
        <s.NoSubscriptionsMessage>
          No subscriptions yet! Find podcasts to subscribe to{" "}
          <s.NoSubscriptionsLink to="/explore">here!</s.NoSubscriptionsLink>
        </s.NoSubscriptionsMessage>
      );
    } else {
      return items;
    }
  };
  if (!loaded) {
    return <div></div>;
  }
  return <div>{renderItems()}</div>;
};

export default Subscriptions;
