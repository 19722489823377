import React from "react";

import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { HexColorPicker } from "react-colorful";
import AddIcon from "@material-ui/icons/Add";

export const Container = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 200px;
  margin-top: 12px;
  @media (min-width: 576px) {
    justify-content: center;
  }
  @media (min-width: 992px) {
    margin-left: 2vw;
    width: 85%;
    margin-top: 2.5rem;
  }
  @media (min-width: 1920px) {
    margin-top: 3.375rem;
  }
  @media (min-width: 2560px) {
    margin-top: 4.625rem;
  }
  @media (min-width: 3840px) {
    margin-top: 7rem;
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;

  @media (min-width: 576px) {
    width: 80%;
  }
  @media (min-width: 992px) {
  }
`;

export const SnippetsHeader = styled.h3`
  color: #2f80ed;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;

  @media (min-width: 1920px) {
    font-size: 2rem;
    margin-bottom: 0.875rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666rem;
    margin-bottom: 1.125rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
    margin-bottom: 1.375rem;
  }
`;

export const Tabss = withStyles(() => ({
  root: {
    marginBottom: "0.375rem",
    "@media (min-width: 1920px)": {
      marginBottom: "0.75rem",
    },
    "@media (min-width: 2560px)": {
      marginBottom: "1.125rem",
    },
    "@media (min-width: 3840px)": {
      marginBottom: "1.5rem",
    },
  },
  indicator: {
    color: "transparent",
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
}))(Tabs);

export const Tabb = withStyles(() => ({
  root: {
    fontFamily: "Proxima Nova Lt",
    fontSize: "1.125rem",
    padding: 0,
    minWidth: 100,
    letterSpacing: "revert",
    opacity: "1",

    "@media (min-width: 576px)": {
      minWidth: 125,
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.5rem",
    },
    "@media (min-width: 2560px)": {
      fontSize: "2rem",
    },
    "@media (min-width: 3840px)": {
      fontSize: "3rem",
    },
  },
  selected: {
    textDecoration: "underline",
  },
  wrapper: {
    textAlign: "start",
    padding: 0,
    alignItems: "start",
  },
}))((props) => <Tab disableRipple {...props} />);

export const NewBoardContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 0;
`;

export const EditSaveButton = withStyles((theme) => ({
  root: {
    color: "#2F80ED",
    outline: "none",
    backgroundColor: "white",
    border: "1px solid #2F80ED",
    textTransform: "none",
    borderRadius: "30px",
    width: "132px",
    minWidth: "132px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "4px",
    marginBottom: "8px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#ebf5ff",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "180px",
      minWidth: "180px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "240px",
      minWidth: "240px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
      border: "2px solid #2F80ED",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "360px",
      minWidth: "360px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
  disabled: {
    backgroundColor: "rgba(0, 0, 0, 0.26)",
  },
}))(Button);

export const Dialogg = withStyles(() => ({
  paper: {
    maxWidth: "initial",
    "&&": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
  },
}))(Dialog);

export const DialogTitlee = withStyles(() => ({
  root: {
    color: "#2f80ed",
    fontSize: "1.2rem",
    fontFamily: "Proxima Nova Lt",
    textAlign: "center",
    marginTop: 32,

    "@media (min-width: 992px)": {
      fontSize: "1.5rem",
    },

    "@media (min-width: 1920px)": {
      fontSize: "2rem",
      marginTop: 42,
      marginBottom: 10,
    },
    "@media (min-width: 2560px)": {
      fontSize: "2.66666666rem",
      marginTop: 56,
      marginBottom: 24,
    },
    "@media (min-width: 3840px)": {
      fontSize: "4rem",
      marginTop: 86,
      marginBottom: 54,
    },
  },
}))(DialogTitle);

export const DialogContentt = withStyles(() => ({
  root: {
    minWidth: 296,
    paddingLeft: "6%",
    paddingRight: "6%",
    "@media (min-width: 576px)": {
      width: 380,
    },
    "@media (min-width: 992px)": {
      width: 540,
      paddingLeft: "12%",
      paddingRight: "12%",
    },
    "@media (min-width: 1920px)": {
      width: 720,
    },
    "@media (min-width: 2560px)": {
      width: 960,
    },
    "@media (min-width: 3840px)": {
      width: 1440,
    },
  },
}))(DialogContent);

export const DialogActionss = withStyles(() => ({
  root: {
    justifyContent: "center",
    marginBottom: 44,
    marginTop: 8,
    "@media (min-width: 1920px)": {
      marginBottom: 58,
      marginTop: 10,
    },
    "@media (min-width: 2560px)": {
      marginBottom: 78,
      marginTop: 14,
    },
    "@media (min-width: 3840px)": {
      marginBottom: 116,
      marginTop: 22,
    },
  },
}))(DialogActions);

export const SaveButton = withStyles(() => ({
  root: {
    backgroundColor: "#2F80ED",
    color: "white",
    padding: 0,
    height: 24,
    minWidth: 80,
    fontSize: "0.875rem",
    fontFamily: "Proxima Nova Lt",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#0071E2",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      minWidth: 106,
      height: 32,
      borderRadius: 5,
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      minWidth: 142,
      height: 42,
      borderRadius: 7,
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.333333333333rem",
      minWidth: 212,
      height: 64,
      borderRadius: 11,
    },
  },
  label: {
    fontFamily: "Proxima Nova",
  },
  disabled: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
}))(Button);

export const FormLabel = styled.h5`
  color: #4f4f4f;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-bottom: 0.75rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-bottom: 1rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333333rem;
    margin-bottom: 1.5rem;
  }
`;

export const FormTextInput = withStyles({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontFamily: "Proxima Nova Lt",
      fontSize: ".75rem",
      padding: 0,
      marginBottom: "1.5rem",
      letterSpacing: "initial",
      "&.MuiInputBase-input::placeholder": {
        color: "#BDBDBD",
        opacity: 1,
        fontFamily: "Proxima Nova Lt",
      },

      "@media (min-width: 1920px)": {
        fontSize: "1rem",
        marginBottom: "2rem",
      },
      "@media (min-width: 2560px)": {
        fontSize: "1.33333333333rem",
        marginBottom: "2.66666666667rem",
      },
      "@media (min-width: 3840px)": {
        fontSize: "2rem",
        marginBottom: "4rem",
      },
    },
  },
})(TextField);

export const PickedColorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  @media (min-width: 1920px) {
    margin-top: 16px;
  }
  @media (min-width: 2560px) {
    margin-top: 20px;
  }
  @media (min-width: 3840px) {
    margin-top: 28px;
  }
`;

export const PickedColor = styled.div`
  width: 24px;
  height: 24px;
  font-family: Proxima Nova Lt;
  @media (min-width: 1920px) {
    width: 32px;
    height: 32px;
  }
  @media (min-width: 2560px) {
    width: 42px;
    height: 42px;
  }
  @media (min-width: 3840px) {
    width: 64px;
    height: 64px;
  }
`;

export const PickedColorText = styled.h5`
  color: #4f4f4f;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;
  margin: 0;
  margin-left: 8px;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-left: 12px;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-left: 16px;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333333rem;
    margin-left: 24px;
  }
`;

export const ColorPicker = styled(HexColorPicker)`
  && {
    width: 120px;
    height: 120px;
  }
  @media (min-width: 992px) {
    && {
      width: 160px;
      height: 160px;
    }
  }
  @media (min-width: 1920px) {
    && {
      width: 212px;
      height: 212px;
    }
  }
  @media (min-width: 2560px) {
    && {
      width: 284px;
      height: 284px;
    }
  }
  @media (min-width: 3840px) {
    && {
      width: 426px;
      height: 426px;
    }
  }
`;

export const AudioBoards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 105%;
`;

export const AudioBoard = styled.div`
  margin-right: 32px;
  margin-bottom: 32px;
  @media (min-width: 1920px) {
    margin-right: 38px;
    margin-bottom: 38px;
  }
  @media (min-width: 2560px) {
    margin-right: 48px;
    margin-bottom: 48px;
  }
  @media (min-width: 3840px) {
    margin-right: 64px;
    margin-bottom: 64px;
  }
`;

export const NoAudioBoards = styled.h4`
  color: #828282;
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 0.875rem;
  @media (min-width: 1920px) {
    font-size: 1.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.75rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.5rem;
  }
`;

export const AudioBoardThumbnail = styled.div`
  width: 250px;
  height: 140px;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
  @media (min-width: 1920px) {
    width: 300px;
    height: 168px;
  }
  @media (min-width: 2560px) {
    width: 375px;
    height: 210px;
  }
  @media (min-width: 3840px) {
    width: 506px;
    height: 284px;
  }
`;

export const AudioBoardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 32px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  @media (min-width: 1920px) {
    height: 46px;
    width: 300px;
    border-radius: 0 0 7px 7px;
  }
  @media (min-width: 2560px) {
    height: 58px;
    width: 375px;
    border-radius: 0 0 9px 9px;
  }
  @media (min-width: 3840px) {
    height: 78px;
    width: 506px;
    border-radius: 0 0 12px 12px;
  }
`;

export const AudioBoardInfoText = styled.h4`
  color: #717171;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;
  margin-left: 1rem;
  padding-top: 0.5rem;
  cursor: pointer;

  @media (min-width: 1920px) {
    font-size: 1.25rem;
    margin-left: 1.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.75rem;
    margin-left: 1.625rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.5rem;
    margin-left: 2.25rem;
  }
`;

export const CloseIconn = styled(CloseIcon)`
  margin-top: 4px;
  && {
    fill: #818089;
  }

  @media (min-width: 992px) {
    && {
      font-size: 1.75rem;
    }
  }
  @media (min-width: 1920px) {
    && {
      font-size: 2.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 3.11111111111rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 4.66666666667rem;
    }
  }
`;

export const DeleteIconn = withStyles(() => ({
  root: {
    fontSize: "1.25rem",
    "@media (min-width: 1920px)": {
      fontSize: "1.5rem",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.875rem",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.375rem",
    },
  },
}))(DeleteOutlineIcon);

export const IconButtonn = styled(IconButton)`
  && {
    margin-right: 10px;
  }
  @media (min-width: 1920px) {
    && {
      margin-right: 12px;
    }
  }
  @media (min-width: 2560px) {
    && {
      margin-right: 15px;
    }
  }
  @media (min-width: 3840px) {
    && {
      margin-right: 20px;
    }
  }
`;

export const AddIconn = withStyles(() => ({
  root: {
    fontSize: "1.25rem",
    fill: "#2F80ED",
    "@media (min-width: 992px)": {
      fontSize: "1.5rem",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.8rem",
      marginRight: 2,
    },
    "@media (min-width: 2560px)": {
      fontSize: "2.25rem",
    },
    "@media (min-width: 3840px)": {
      fontSize: "3rem",
      marginRight: 3,
    },
  },
}))(AddIcon);
