import React from "react";
import PodcastEpisode from "../PodcastEpisode/PodcastEpisode";
import * as s from "./StyledPodcastEpisodeList";

let renderItems = (episodes, name, defaultPic, podcastID) => {
  if (episodes === undefined) {
    return null;
  }
  const items = episodes
    .sort((a, b) => new Date(b.PubDate) - new Date(a.PubDate))
    .map((item) => {
      return (
        <div>
          <PodcastEpisode
            podcastID={podcastID}
            data={item}
            name={name}
            defaultPic={defaultPic}
          />
        </div>
      );
    });
  return items;
};

const PodcastEpisodeList = (props) => {
  const episodes = props.episodes;
  const name = props.name;
  const defaultPic = props.defaultPic;
  const podcastID = props.podcastID;

  return (
    <s.PodcastListContainer>
      {renderItems(episodes, name, defaultPic, podcastID)}
    </s.PodcastListContainer>
  );
};

export default PodcastEpisodeList;
