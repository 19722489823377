import React, { useEffect, useState } from "react";
import * as s from "./StyledHistory";
import EpisodeItem from "../../../shared_components/EpisodeItem/EpisodeItem";
import { useStoreState, useGetAndSet } from "react-context-hook";
import getHistory from "../../../../api/history/getHistory";

const History = (props) => {
  // gets recently listened array from global state
  const { user } = useStoreState();
  const [recentlyListened, setRecentlyListened] = useGetAndSet(
    "recentlyListened"
  );
  const [loaded, setLoaded] = useState(false);
  const [undefinedCount, setUndefinedCount] = useState(0);

  useEffect(() => {
    // grab the history data once and load it into global state
    if (!loaded) {
      getHistory(user.id).then((data) => {
        setRecentlyListened(data);
        setLoaded(true);
      });
    }
  });

  // function to render each recently played episode item
  const renderItems = () => {
    const sortedList = recentlyListened.sort((a, b) => {
      return parseInt(a.LastPlayed) < parseInt(b.LastPlayed) ? 1 : -1;
    });

    const items = sortedList.map((item, i) => {
      return (
        <EpisodeItem
          key={i}
          episodeID={item.EpisodeID}
          incrementUndefined={() => setUndefinedCount(undefinedCount + 1)}
        />
      );
    });
    if (undefinedCount === items.length) {
      return (
        <s.NoHistoryMessage>
          No history! Listen to podcast episodes{" "}
          <s.NoHistoryLink to="/explore">here!</s.NoHistoryLink>
        </s.NoHistoryMessage>
      );
    } else {
      return items;
    }
  };
  if (!loaded) {
    return <s.RecentContainer></s.RecentContainer>;
  }

  return <s.RecentContainer>{renderItems(recentlyListened)}</s.RecentContainer>;
};

export default History;
