import axios from "axios";
import { API } from "../constants";
/**
 * Adds a new comment to a snippet.
 *
 * @param episode - episode object
 * @param token - token
 */
const addSnippetComment = async (data, token) => {
  return axios
    .post(API + "/api/snippet/addcomment", {
      body: {
        commentText: data.commentText,
        snippetID: data.snippetID,
        userID: data.userID,
      },
      token: window.localStorage.getItem("token"),
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default addSnippetComment;
