import axios from "axios";
import { API } from "../constants";

/**
 * Adding listening history for a user
 *
 * @param episode - episode object
 * @param token - token
 */
const addHistory = async (episode, token) => {
  return axios
    .post(API + "/api/history/add", {
      body: {
        datetime: episode.datetime,
        episodeID: episode.episodeID,
        podcastID: episode.podcastID,
        timestamp: episode.timestamp,
        userID: episode.userID,
      },
      token: token,
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default addHistory;
