import React, { useState, useEffect } from "react";
import * as s from "./StyledEpisodeHeader";
import {
  useStoreState,
  useGetAndSet,
  useSetStoreValue,
} from "react-context-hook";
import addHistory from "../../../../../api/history/addHistory";
import updateLikes from "../../../../../api/podcasts/updateLikes";
import removeLikes from "../../../../../api/podcasts/removeLikes";
import ImageNotFound from "../../../../../assets/image_not_found.png";
import Comments from "../Comments/Comments";
import PlayIcon from "../../../../../assets/PlayIcon.svg";
import PauseIcon from "../../../../../assets/PauseIcon.svg";

const EpisodeHeader = (props) => {
  let podcast = props.podcast;
  let episode = props.episode;
  const { loggedIn, user, attemptedLogin } = useStoreState();
  const [liked, setLiked] = useState(false);
  const setUrl = useSetStoreValue("url");
  const [playing, setPlaying] = useGetAndSet("playing");
  const setPlayed = useSetStoreValue("played");
  const setLoaded = useSetStoreValue("loaded");
  const [currentImage, setCurrentImage] = useGetAndSet("currentImage");
  const setCurrentSeries = useSetStoreValue("currentSeries");
  const [currentTitle, setCurrentTitle] = useGetAndSet("currentTitle");
  const setListened = useSetStoreValue("listened");
  const setCurrentEpisodeID = useSetStoreValue("currentEpisodeID");
  const setCurrentPodcastID = useSetStoreValue("currentPodcastID");
  const [likeCount, setLikeCount] = useState(0);
  useEffect(() => {
    if (episode.Likes) {
      setLikeCount(episode.Likes.length);
    }
  }, [episode.Likes]);

  /**
   * Loads state to the react player
   *
   * @param {*} episode - episode data
   * @param {*} title - title of the podcast series
   */
  let load = (episode, title) => {
    // condition where on the same episode and looking to pause
    if (playing && currentTitle === episode.Name) {
      setPlaying(false);
      // condition to play a new podcast episode
    } else {
      setUrl(episode.Key);
      setPlayed(0);
      setLoaded(0);
      setPlaying(true);
      setCurrentImage(episode.Img ? episode.Img : podcast.Img);
      setCurrentSeries(title);
      setCurrentEpisodeID(episode.ID.toString());
      setCurrentPodcastID(podcast.ID);
      setCurrentTitle(episode.Name);
      setListened(true);
      let id = "";
      user.id ? (id = user.id.toString()) : (id = "");
      const today = new Date();
      const episodeBody = {
        datetime: today.getTime().toString(),
        episodeID: episode.ID.toString(),
        podcastID: podcast.ID.toString(),
        timestamp: "0".toString(),
        userID: id,
      };
      const token = window.localStorage.getItem("token");
      addHistory(episodeBody, token).then((response) => console.log(response));
    }
  };

  useEffect(() => {
    if (
      loggedIn &&
      episode !== undefined &&
      episode.Likes !== undefined &&
      user !== undefined &&
      user.id !== undefined
    ) {
      setLiked(episode.Likes.includes(user.id));
    }
  }, [episode, user, loggedIn, podcast.Creators]);

  const handleLike = () => {
    if (liked) {
      removeLikes(episode.ID, user.id);
      setLiked(false);
      setLikeCount(likeCount - 1);
    } else {
      updateLikes(episode.ID, user.id);
      setLiked(true);
      setLikeCount(likeCount + 1);
    }
  };

  const likeHeart = loggedIn ? (
    <s.HeartContainer onClick={() => handleLike()}>
      {liked ? <s.EpisodeFavoriteIcon /> : <s.EpisodeFavoriteOutlineIcon />}
      <s.LikedCount>{likeCount}</s.LikedCount>
    </s.HeartContainer>
  ) : (
    <s.HeartContainer>
      <s.SignUpLink to="/signup">
        <s.EpisodeFavoriteOutlineIcon />
      </s.SignUpLink>
      <s.LikedCount>{likeCount}</s.LikedCount>
    </s.HeartContainer>
  );

  if (!("ID" in podcast) && !("ID" in episode)) {
    return <div></div>;
  }
  if (
    window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("token") !== "" &&
    loggedIn === false &&
    attemptedLogin === false
  ) {
    return <div></div>;
  }

  return (
    <s.EpisodeContainer>
      <s.EpisodeHeaderContainer>
        <s.EpisodeThumbnailContainer>
          <s.EpisodeThumbnail
            src={
              podcast.Img
                ? podcast.Img
                : episode.Img
                ? episode.Img
                : ImageNotFound
            }
          />
          {playing && currentImage != null && episode.Name === currentTitle ? (
            <s.PauseIcon
              src={PauseIcon}
              onClick={() => load(episode, podcast.Title)}
            />
          ) : (
            <s.PlayIcon
              src={PlayIcon}
              onClick={() => load(episode, podcast.Title)}
            />
          )}
          <s.DesktopLikeContainer>{likeHeart}</s.DesktopLikeContainer>
        </s.EpisodeThumbnailContainer>
        <s.EpisodeHeaderInfo>
          <s.PodcastName to={"/podcasts/" + podcast.ID + "/" + podcast.Title}>
            {podcast.Title}
          </s.PodcastName>
          <s.EpisodeName>{episode.Name}</s.EpisodeName>
          <s.EpisodeDescription>Description</s.EpisodeDescription>
          <s.EpisodeDesktopDescription
            dangerouslySetInnerHTML={{
              __html: episode.Description,
            }}
          ></s.EpisodeDesktopDescription>
          <s.DesktopComments>
            <Comments episodeID={props.eid} />
          </s.DesktopComments>
        </s.EpisodeHeaderInfo>
      </s.EpisodeHeaderContainer>
      <s.EpisodeMobileDescription
        dangerouslySetInnerHTML={{
          __html: episode.Description,
        }}
      ></s.EpisodeMobileDescription>
      <s.MobileLikeContainer>{likeHeart}</s.MobileLikeContainer>
      <s.MobileComments>
        <Comments episodeID={props.eid} />
      </s.MobileComments>
    </s.EpisodeContainer>
  );
};

export default EpisodeHeader;
