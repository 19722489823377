import styled from "styled-components";
import { Link } from "react-router-dom";

export const LikesContainer = styled.div`
  margin-bottom: 140px;
  margin-top: 16px;

  @media (min-width: 992px) {
    margin-top: 6px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 160px;
    margin-top: 14px;
  }
  @media (min-width: 2560px) {
    margin-top: 22px;
  }
  @media (min-width: 3840px) {
    margin-bottom: 150px;
    margin-top: 28px;
  }
`;

export const NoLikesMessage = styled.h3`
  font-size: 0.875rem;
  color: #4f4f4f;
  margin-top: 0.75rem;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-top: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-top: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
    margin-top: 2rem;
  }
`;

export const NoLikesLink = styled(Link)`
  color: #2f80ed;
  text-decoration: underline;
`;
