import { API } from "../constants";
import axios from "axios";
import defaultProfile from "../../assets/DefaultProfile.svg";
/*
Gets a user from token, only call if we know token exists/user is logged in
*/
const getUserByToken = async (setUser) => {
  let api =
    API + "/api/users/token?token=" + window.localStorage.getItem("token");
  return axios
    .get(api)
    .then((data) => {
      setUser({
        email: data.data.Email,
        name: data.data.Name,
        username: data.data.Username,
        password: data.data.Password,
        bio: data.data.Bio,
        podcasts: data.data.MyPodcasts,
        image: data.data.Img ? data.data.Img : defaultProfile,
        id: data.data.ID,
        followers: data.data.Followers,
        following: data.data.Following,
        likes: data.data.LikedEpisodes,
        comments: data.data.Comments,
        subscriptions:
          data.data
            .SubscribedEpisodes /* we should eventually rename this field on the backend to avoid confusion*/,
      });
      return data.data;
    })
    .then((data) => data);
};

export default getUserByToken;
