import React, { useEffect } from "react";
import packageJson from "../package.json";
import Snackbar from "@material-ui/core/Snackbar";
import styled from "styled-components";
import ProximaNova from "./assets/fonts/ProximaNova-Regular.otf";

const Card = styled.div`
  width: 280px;
  height: 140px;
  background: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #007bff;
  border-radius: 8px;
  @media (min-width: 992px) {
    width: 312px;
    height: 148px;
  }
  @media (min-width: 1920px) {
    width: 400px;
    height: 188px;
  }
  @media (min-width: 2560px) {
    width: 700px;
    height: 332px;
    border-radius: 12px;
    border: 2px solid #007bff;
  }
  @media (min-width: 3840px) {
    width: 1140px;
    height: 500px;
    border-radius: 16px;
    border: 2px solid #007bff;
  }
`;

const Snackbarr = styled(Snackbar)`
  && {
    @media (max-width: 576px) {
      justify-content: center;
    }
  }
`;

const Text = styled.p`
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  color: #2f80ed;
  text-align: center;
  font-size: 1rem;
  padding-top: 16px;
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
    padding-top: 20px;
    padding-bottom: 16px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
    padding-top: 40px;
    padding-bottom: 24px;
    padding-left: 52px;
    padding-right: 52px;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
    padding-top: 68px;
    padding-bottom: 40px;
    padding-left: 72px;
    padding-right: 72px;
  }
`;

export const Button = styled.button`
  background: #2F80ED;
  color: white;
  border-radius: 8px;
  border: none;
  text-direction: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 36px;
  font-weight bold;
  cursor: pointer; 
  margin: 0 auto;
  font-size: 1rem;
  box-shadow: 3px 3px 10px #00000029;
  outline: none;
  font-family: Proxima Nova;
  &:focus {
    outline: none;
  }
  @media (min-width: 992px) {
    font-size: 1.125rem;
    width: 232px;
    height: 40px;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
    width: 280px;
    height: 48px;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
    width: 520px;
    height: 76px;
    border-radius: 12px;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
    width: 780px;
    height: 112px;
    border-radius: 12px;
  }
`;

global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export default function CacheBuster(props) {
  const [loading, setLoading] = React.useState(true);
  const [isLatestVersion, setIsLatestVersion] = React.useState(true);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.replace(window.location.href);
  };

  const checkVersion = () => {
    fetch("/meta.json", { cache: "no-cache" })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          setLoading(false);
          setIsLatestVersion(false);
        } else {
          setLoading(false);
          setIsLatestVersion(true);
        }
      });
  };

  useEffect(() => {
    checkVersion();
  }, []);

  // In case they leave the tab open, check version every 30 mins
  useEffect(() => {
    const timer = setInterval(() => {
      checkVersion();
    }, 30 * 60 * 1000);
    return () => clearInterval(timer);
  });

  return (
    <Snackbarr
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={!loading && !isLatestVersion}
    >
      <Card>
        <Text>We've updated Castyr, hit the button to get the update!</Text>
        <Button onClick={() => refreshCacheAndReload()}>Get update!</Button>
      </Card>
    </Snackbarr>
  );
}
