import axios from "axios";
import { API } from "../constants";
/**
 * Function to retrieve snippets from people user follows
 *
 * @param id - userid to retrieve following snippets from
 */
const getFollowingSnippets = async (id) => {
  let api = API + "/api/snippet/following?UserID=" + id;

  return axios
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.log(error));
};

export default getFollowingSnippets;
