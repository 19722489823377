import axios from "axios";
import { API } from "../constants";

// Secure route, requires token

/**
 * updateLikes: function that makes a PUT request to update add userID to episodes likes Array
 *
 * @param {*} episodeID
 * @param {*} userID
 */
const updateLikes = async (episodeID, userID) => {
  axios
    .put(API + "/api/episode/update", {
      Body: {
        episodeID: episodeID,
        userID: userID,
      },
      Token: window.localStorage.getItem("token"),
    })
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
};

export default updateLikes;
