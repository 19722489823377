import moment from "moment";

const displayDates = (number, unit) => {
  if (number > 1) {
    return `${number} ${unit}s ago`;
  } else {
    return `${number} ${unit} ago`;
  }
};

const getTimeDifference = (time) => {
  const now = moment();
  const momentTime = moment(time);
  const years = now.diff(momentTime, "years");
  const months = now.diff(momentTime, "months");
  const days = now.diff(momentTime, "days");
  const hours = now.diff(momentTime, "hours");
  const minutes = now.diff(momentTime, "minutes");
  const seconds = now.diff(momentTime, "seconds");
  if (years !== 0) {
    return displayDates(years, "year");
  } else if (months !== 0) {
    return displayDates(months, "month");
  } else if (days !== 0) {
    return displayDates(days, "day");
  } else if (hours !== 0) {
    return displayDates(hours, "hour");
  } else if (minutes !== 0) {
    return displayDates(minutes, "minute");
  } else if (seconds !== 0) {
    return displayDates(seconds, "second");
  }
};

export default getTimeDifference;
