import styled from "styled-components";
import { Link } from "react-router-dom";

export const NotificationItem = styled.li`
  margin: 2px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 60px;
  :hover {
    cursor: pointer;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CenterColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
`;

export const MessageText = styled.div`
  color: #4f4f4f;
  font: normal normal 500 0.9rem proxima-nova, sans-serif;
`;

export const TimeText = styled.div`
  color: #0071e2;
  font: normal normal 550 0.9rem proxima-nova, sans-serif;
`;

export const ProfileImage = styled.img`
  height: 37px;
  width: 37px;
  border-radius: 50%;
  background-color: ${(props) => (props.image ? "transparent" : "#ebebeb")};
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: cover;
`;

export const PodcastImage = styled.img`
  height: 37px;
  width: 37px;
  background-color: ${(props) => (props.image ? "transparent" : "#ebebeb")};
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

export const UnreadDot = styled.span`
  height: 7px;
  width: 7px;
  background: #0071e2;
  border-radius: 50%;
  display: inline-block;
  margin-left: 14px;
  margin-right: 14px;
`;

export const ReadDot = styled.span`
  height: 7px;
  width: 7px;
  background: transparent;
  border-radius: 50%;
  display: inline-block;
  margin-left: 14px;
  margin-right: 14px;
`;

export const CreatorLink = styled(Link)`
  color: #4f4f4f;
  font: normal normal 550 0.9rem proxima-nova, sans-serif;
  :hover {
    text-decoration: underline;
  }
`;
