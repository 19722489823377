import styled from "styled-components";
import ProximaNova from "../../../../assets/fonts/ProximaNova-Regular.otf";
import { default as Linkk } from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import ShareIcon from "@material-ui/icons/Share";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 28px;
  flex-direction: column;
  @media (min-width: 992px) {
    margin-left: 20px;
  }
  @media (min-width: 1920px) {
    margin-left: 28px;
  }
  @media (min-width: 2560px) {
    margin-left: 28px;
  }
  @media (min-width: 3840px) {
    margin-bottom: 68px;
  }
`;
export const SnippetContainer = styled.div`
  display: flex;
  margin-left: 44px;
  @media (min-width: 1920px) {
    margin-left: 58px;
  }
  @media (min-width: 2560px) {
    margin-left: 76px;
  }
  @media (min-width: 3840px) {
    margin-left: 104px;
  }
`;
export const AuthorPic = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
  @media (min-width: 1920px) {
    width: 42px;
    height: 42px;
    margin-right: 16px;
  }
  @media (min-width: 2560px) {
    width: 56px;
    height: 56px;
    margin-right: 20px;
  }
  @media (min-width: 3840px) {
    width: 84px;
    height: 84px;
    margin-right: 28px;
  }
`;
export const AuthorRow = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;
export const AuthorLink = styled(Link)`
  text-decoration: none;
`;
export const AuthorText = styled.span`
  margin: 0;
  color: #333333;
  font-weight: 500;
  font-size: 0.75rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
  }
`;
export const SnippedBy = styled.p`
  display: flex;
  margin: 0;
  color: #828282;
  font-weight: 400;
  font-size: 0.75rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  white-space: break-spaces;
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
  }
`;
export const ThumbnailContainer = styled.div``;
export const Thumbnail = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 4px;
  position: absolute;
  @media (min-width: 992px) {
    width: 120px;
    height: 120px;
    border-radius: 5px;
  }
  @media (min-width: 1920px) {
    width: 150px;
    height: 150px;
    border-radius: 7px;
  }
  @media (min-width: 2560px) {
    width: 180px;
    height: 180px;
    border-radius: 7px;
  }
  @media (min-width: 3840px) {
    width: 270px;
    height: 270px;
    border-radius: 12px;
  }
`;
export const Info = styled.div`
  margin-left: 24px;
  max-height: 200px;
  overflow: hidden;
  width: 53%;
  @media (min-width: 992px) {
    max-height: 120px;
    height: 120px;
  }
  @media (min-width: 1920px) {
    margin-left: 32px;
    max-height: 150px;
    height: 150px;
  }
  @media (min-width: 2560px) {
    max-height: 180px;
    height: 180px;
    margin-left: 36px;
  }
  @media (min-width: 3840px) {
    max-height: 270px;
    height: 270px;
    margin-left: 54px;
  }
`;
export const Title = styled(Link)`
  line-height: 1.2;
  display: block;
  margin: 0;
  text-decoration: none;
  color: #0071e2;
  font-size: 1rem;
  font-family: Proxima Nova Lt;
  margin-bottom: 0.375rem;
  @media (min-width: 1920px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.77777777777rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.65rem;
    margin-bottom: 0.5rem;
  }
`;
export const PodcastName = styled(Link)`
  line-height: 1.2;
  display: block;
  margin: 0;
  color: #4f4f4f;
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  font-family: Proxima Nova Lt;
  text-decoration: none;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
  }
`;
export const EpisodeName = styled(Link)`
  line-height: 1.2;
  display: block;
  margin: 0;
  color: #4f4f4f;
  font-size: 0.875rem;
  font-weight: 400;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  margin-bottom: 1rem;
  text-decoration: none;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
  }
`;
export const Description = styled.p`
  margin: 0;
  color: #828282;
  font-weight: 400;
  font-size: 0.875rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
  }
`;

export const ShareLinkRow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`;

export const ShareLinkPositioning = styled.div`
  position: relative;
  background: #f2f2f2;
  border-radius: 3px;
`;

export const ShareLink = styled.p`
  padding: 4px;
  color: #4f4f4f;
  margin-left: 24px;
  outline: none;
  appearance: none;
  font-size: 0.75rem;
  border: none;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-weight: 400;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
    margin-left: 32px;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
    margin-left: 54px;
  }
`;

export const LinkIcon = styled(Linkk)`
  position: absolute;
  transform: rotate(-40deg);
  padding: 3px;
  margin-top: 1px;
  @media (min-width: 1920px) {
    padding: 1px;
    margin-top: 3px;
    margin-left: 2px;
  }
  @media (min-width: 2560px) {
    padding: 0px;
    margin-top: 6px;
    margin-left: 6px;
    && {
      font-size: 1.7rem;
    }
  }
  @media (min-width: 3840px) {
    margin-top: 8px;
    margin-left: 12px;
    && {
      font-size: 2.5rem;
    }
  }
`;

export const IconButtonn = styled(IconButton)`
  && {
    outline: none;
    margin-top: -10px;
    padding: 6px;
  }
  @media (min-width: 1920px) {
    && {
      margin-top: -13px;
      padding: 12px;
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
    }
  }
`;

export const ShareIconn = styled(ShareIcon)`
  cursor: pointer;
  && {
    font-size: 1rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
    }
  }
`;

export const ShareContainer = styled.div`
  display: none;
  @media (min-width: 576px) {
    display: flex;
    justify-content: center;
  }
`;

export const ShareText = styled.p`
  color: #4f4f4f;
  font-size: 0.75rem;
  font-family: "Proxima Nova Lt";
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
    margin-right: 8px;
  }
`;

export const DialogTitlee = styled(DialogTitle)``;

export const Dialogg = styled(Dialog)``;

export const DialogActionss = styled(DialogActions)``;

export const DialogContentt = styled(DialogContent)``;

export const DialogContentTextt = styled(DialogContentText)``;

export const Buttonn = styled(Button)`
  && {
    color: #59a7ed;
  }
`;

export const ThumbnailPlayContainer = styled(Link)`
  display: block;
  position: relative;
  width: 90px;
  height: 90px;
  @media (min-width: 992px) {
    width: 120px;
    height: 120px;
  }
  @media (min-width: 1920px) {
    width: 150px;
    height: 150px;
  }
  @media (min-width: 2560px) {
    width: 180px;
    height: 180px;
  }
  @media (min-width: 3840px) {
    width: 270px;
    height: 270px;
  }
`;

export const MobileShareContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  @media (min-width: 576px) {
    display: none;
  }
`;

export const LikeIcon = styled(FavoriteBorderIcon)`
  cursor: pointer;
  && {
    font-size: 1rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
    }
  }
`;

export const LikedIcon = styled(FavoriteIcon)`
  cursor: pointer;
  && {
    font-size: 1rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
    }
  }
`;

export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 0.75rem;
  @media (min-width: 1920px) {
    margin-top: 1rem;
  }
  @media (min-width: 2560px) {
    margin-top: 1.25rem;
  }
  @media (min-width: 3840px) {
    margin-top: 1.5rem;
  }
`;

export const CommentIcon = styled(ModeCommentOutlinedIcon)`
  cursor: pointer;
  margin-right: 1%;
  && {
    font-size: 1rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
    }
  }
`;

export const LikeNumber = styled.p`
  margin: 0;
  color: #333333;
  font-weight: 500;
  font-size: 0.75rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  margin-left: 2.75%;
  margin-right: 9%;
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
  }
`;

export const SignUpLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
`;

export const PlayIconn = styled.img`
  && {
    position: absolute;
    z-index: 5;
    width: 90px;
    height: 90px;
    cursor: pointer;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
    margin-left: 2%;
  }
  @media (min-width: 992px) {
    && {
      width: 120px;
      height: 120px;
    }
  }
  @media (min-width: 1920px) {
    && {
      width: 150px;
      height: 150px;
    }
  }
  @media (min-width: 2560px) {
    && {
      width: 180px;
      height: 180px;
    }
  }
  @media (min-width: 3840px) {
    && {
      width: 270px;
      height: 270px;
    }
  }
`;
