import styled from "styled-components";
import Button from "@material-ui/core/Button";
import ProximaNovaWoff from "../../../assets/fonts/ProximaNova-Semibold.woff";
import ProximaNova3 from "../../../assets/fonts/ProximaNova-Regular.otf";
import ProximaNovaWoff2 from "../../../assets/fonts/ProximaNova-Semibold.woff2";

export const Container = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  padding-top: 50px;
  @media (min-width: 992px) {
    padding-top: 5.555555555vh;
  }
  @font-face {
    font-family: "Proxima Nova Lt";
    src: url(${ProximaNovaWoff2}) format("woff2"),
      url(${ProximaNovaWoff}) format("woff");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova3}) format("woff2");
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  margin-top: 60px;

  @media (min-width: 992px) {
    width: 60%;
    margin-top: 132px;
  }
  @media (min-width: 1920px) {
    width: 60%;
    margin-top: 160px;
  }
  @media (min-width: 2560px) {
    margin-top: 180px;
  }
  @media (min-width: 3840px) {
    margin-top: 240px;
  }
`;

export const SquiggleVector = styled.img`
  display: none;
  @media (min-width: 992px) {
    display: inline-block;
    position: absolute;
    margin-left: calc(-44px - 0.75rem);
  }
  @media (min-width: 1920px) {
    margin-left: calc(-60px - 0.825rem);
    width: 60px;
  }
  @media (min-width: 2560px) {
    margin-left: calc(-86px - 1.25rem);
    width: 86px;
  }
  @media (min-width: 3840px) {
    margin-left: calc(-134px - 1.75rem);
    width: 134px;
  }
`;

export const CloudsVector = styled.img`
  position: absolute;
  right: 0;
  height: 40vh;
  margin-top: 0.5rem;
  @media (min-width: 576px) {
    top: 12vh;
    width: 54vw;
    height: initial;
  }
  @media (min-width: 992px) {
    top: 10vh;
    width: 46vw;
  }
  @media (min-width: 1200px) {
    top: 8vh;
    width: 44vw;
  }
  @media (min-width: 1200px) {
    top: 6vh;
  }
`;

export const WavesVector = styled.img`
  height: 58vh;
  position: absolute;
  right: 0;
  @media (min-width: 576px) {
    top: 25vh;
    width: 72vw;
    height: initial;
  }
  @media (min-width: 768px) {
    top: 16vh;
    width: 80vw;
  }
  @media (min-width: 992px) {
    top: 20vh;
    width: 72vw;
    opacity: 0.7;
  }
  @media (min-width: 1200px) {
    top: 8vh;
    width: 89vw;
  }
`;

export const HeadphonesVector = styled.img`
  display: none;
  @media (min-width: 992px) {
    display: inline-block;
    position: absolute;
    height: 110vh;
    right: 0;
  }
  @media (min-width: 1200px) {
    margin-top: 1rem;
    height: 100vh;
  }
  @media (min-width: 1920px) {
    height: 110vh;
  }
  @media (min-width: 1920px) {
    margin-top: 2rem;
  }
`;

export const HeaderH1 = styled.h1`
  font-family: Proxima Nova;
  color: #0071e2;
  font-size: 1.8333333333rem;
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    font-size: 3rem;
  }
  @media (min-width: 1920px) {
    font-size: 4rem;
  }
  @media (min-width: 2560px) {
    font-size: 5.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 8rem;
  }
`;

export const HeaderH1Span = styled.span`
  display: block;
  position: relative;
  z-index: 3;
`;

export const HeaderAltH1 = styled.h1`
  font-family: Proxima Nova;
  color: #95bddb;
  margin-top: 2rem;
  font-size: 1.375rem;
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    font-size: 2.25rem;
  }
  @media (min-width: 1280px) {
    margin-bottom: 8rem;
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1920px) {
    margin-bottom: 10rem;

    margin-top: 2.66666666667rem;
    font-size: 3rem;
  }
  @media (min-width: 2560px) {
    margin-top: 3.75rem;
    font-size: 4rem;
    margin-bottom: 13rem;
  }
  @media (min-width: 3840px) {
    margin-top: 5.5rem;
    font-size: 6rem;
    margin-bottom: 20rem;
  }
`;

export const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
  @media (min-width: 992px) {
    margin-top: 92px;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 1920px) {
    margin-top: 112px;
    margin-bottom: 2.5rem;
  }
  @media (min-width: 2560px) {
    margin-top: 144px;
    margin-bottom: 3.5rem;
  }
  @media (min-width: 3840px) {
    margin-top: 200px;
    margin-bottom: 5rem;
  }
`;

export const HeaderH2 = styled.h2`
  color: #0071e2;
  font-family: Proxima Nova;
  font-size: 1.125rem;
  margin: 0;
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    font-size: 1.75rem;
  }
  @media (min-width: 1920px) {
    font-size: 2.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 3.11111111111rem;
  }
  @media (min-width: 3840px) {
    font-size: 4.66666666667rem;
  }
`;

export const HeaderH3 = styled.h3`
  font-family: Proxima Nova Lt;
  color: #0071e2;
  margin-bottom: 0.25rem;
  font-weight: 600;
  font-size: 0.875rem;
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    margin-bottom: 1.5rem;

    font-size: 3rem;
  }
`;
export const PContent = styled.p`
  font-family: Proxima Nova Lt;
  color: #828282;
  font-weight: 400;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    margin-bottom: 1.5rem;
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
    margin-bottom: 4rem;
  }
`;
export const PrimaryButton = styled(Button)`
  && {
    color: white;
    font-family: Proxima Nova;
    text-transform: initial;
    background-color: #0071e2;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    width: 154px;
    font-weight: 600;
    font-size: 12px;
    position: relative;
    z-index: 3;

    &:hover {
      background-color: #007ac9;
    }
    @media (min-width: 992px) {
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 8px;
      font-size: 14px;
    }
    @media (min-width: 1920px) {
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 12px;
      width: 212px;
      font-size: 18px;
    }
    @media (min-width: 2560px) {
      padding-top: 16px;
      padding-bottom: 16px;
      border-radius: 16px;
      width: 272px;
      font-size: 24px;
    }
    @media (min-width: 3840px) {
      padding-top: 24px;
      padding-bottom: 24px;
      border-radius: 20px;
      width: 400px;
      font-size: 36px;
    }
  }
`;
export const ForTheListenerContainer = styled.div`
  margin-top: 40vh;

  @media (min-width: 576px) {
    margin-top: 22vw;
  }
  @media (min-width: 768px) {
    margin-top: 30vw;
  }
  @media (min-width: 992px) {
    margin-top: 23vw;
    margin-left: calc(44px + 0.75rem);
  }
  @media (min-width: 1440px) {
    margin-top: 20vw;
  }
  @media (min-width: 1920px) {
    margin-top: 16vw;
    margin-left: calc(60px + 0.825rem);
  }
  @media (min-width: 2560px) {
    margin-top: 21vw;
    margin-left: calc(86px + 1.25rem);
  }
  @media (min-width: 3840px) {
    margin-left: calc(134px + 1.75rem);
    margin-top: 22vw;
  }
`;
export const ForThePodcasterContainer = styled.div`
  @media (min-width: 992px) {
    margin-left: calc(44px + 0.75rem);
  }
  @media (min-width: 1920px) {
    margin-left: calc(60px + 0.825rem);
  }
  @media (min-width: 2560px) {
    margin-left: calc(86px + 1.25rem);
  }
  @media (min-width: 3840px) {
    margin-left: calc(134px + 1.75rem);
  }
`;
export const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
  @media (min-width: 992px) {
    margin-top: 52px;
  }
`;

export const TweetWallContainer = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  justify-content: center;

  @media (min-width: 992px) {
    justify-content: initial;
    margin-top: 92px;
  }
  @media (min-width: 1920px) {
    margin-top: 112px;
  }
  @media (min-width: 2560px) {
    margin-top: 144px;
  }
  @media (min-width: 3840px) {
    margin-top: 200px;
  }
`;
export const TweetWall = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  justify-items: center;

  @media (min-width: 768px) {
    grid-template-columns: 50% 50%;
    justify-items: revert;
    justify-content: revert;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 33% 33% 33%;
  }
  @media (min-width: 2560px) {
    justify-items: center;
  }
`;

export const Tweet = styled.div`
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;

  @media (min-width: 1920px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const TweetHeader = styled.h2`
  color: #0071e2;
  margin-top: revert;
  line-height: revert;
  margin-bottom: calc(2rem - 10px);
  font-family: Proxima Nova;
  font-size: 1.125rem;
  margin-right: auto;
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    margin-right: initial;
  }
  @media (min-width: 1920px) {
    margin-bottom: 2.5rem;
    font-size: 2.33333333333rem;
  }
  @media (min-width: 2560px) {
    margin-bottom: 3.5rem;
    font-size: 3.11111111111rem;
  }
  @media (min-width: 3840px) {
    margin-bottom: 5rem;

    font-size: 4.66666666667rem;
  }
`;

export const DisclaimerContainer = styled.div`
  text-align: right;
  color: #828282;
  margin-top: 12vh;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
  font-family: Proxima Nova Lt;

  @media (min-width: 576px) {
    margin-top: 9vh;
  }
  @media (min-width: 992px) {
    margin-top: 10em;
  }
  @media (min-width: 1920px) {
    margin-bottom: 2rem;
    margin-right: 1.33333333333rem;
  }
  @media (min-width: 2560px) {
    margin-bottom: 2.66666666667rem;
    margin-right: 1.77777777778rem;
    margin-top: 15em;
  }
  @media (min-width: 3840px) {
    margin-bottom: 4rem;
    margin-right: 2.66666666667rem;
    margin-top: 25em;
  }
`;
export const DisclaimerText = styled.h4`
  font-size: 1rem;
  margin: 0;
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;
export const PHLink = styled.a`
  z-index: 10;
  position: relative;
  top: 8px;
  @media (min-width: 576px) {
    top: 16px;
  }
`;
export const PHImg = styled.img`
  width: 250px;
  height: 54px;
  @media (min-width: 1920px) {
    width: 333px;
    height: 72px;
  }
  @media (min-width: 2560px) {
    width: 444px;
    height: 96px;
  }
  @media (min-width: 3840px) {
    width: 666px;
    height: 144px;
  }
`;
