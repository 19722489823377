import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as s from "./StyledCommentItem";
import getUser from "../../../../../api/user/getUser";
import { useStoreState } from "react-context-hook";
import { ReactComponent as DeleteIcon } from "../../../../../assets/EpisodePageAssets/delete.svg";
import deleteEpisodeComment from "../../../../../api/comments/deleteEpisodeComment";
import DefaultProfile from "../../../../../assets/DefaultProfile.svg";
import formatDate from "../../../../../utils/formatDate";

const CommentItem = ({ item }) => {
  const { user } = useStoreState();
  const [commenter, setCommenter] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (!loaded) {
      const getCommenter = async () => {
        const user = await getUser(item.UserID);
        setCommenter(user);
      };

      getCommenter();
      setLoaded(true);
    }
  }, [loaded, item.UserID]);

  return (
    <>
      {!deleted && (
        <s.CommentItemContainer>
          <s.RowContainer>
            <s.HorizontalCommentAuthorInfo>
              <s.CenteredRowContainer>
                <Link to={`/profile/${item.Username}`}>
                  <s.ProfileImage src={commenter.Img || DefaultProfile} />
                </Link>
              </s.CenteredRowContainer>

              <s.HorizontalContainer>
                <s.CommentAuthor>
                  <Link to={`/profile/${item.Username}`}>
                    <s.UsernameText>
                      {"@"}
                      {item.Username}
                    </s.UsernameText>
                  </Link>
                </s.CommentAuthor>
              </s.HorizontalContainer>
            </s.HorizontalCommentAuthorInfo>
            <s.CommentDisplay>{item.Text}</s.CommentDisplay>
          </s.RowContainer>

          <s.EndContainer>
            <s.DateText>{formatDate(item.Timestamp)}</s.DateText>

            {item.UserID === user.id && (
              <s.DeleteButton
                onClick={() => {
                  deleteEpisodeComment(
                    item.EpisodeID,
                    item.UserID,
                    item.CommentID
                  );
                  setDeleted(true);
                }}
              >
                <DeleteIcon />
              </s.DeleteButton>
            )}
          </s.EndContainer>
        </s.CommentItemContainer>
      )}
    </>
  );
};

export default CommentItem;
