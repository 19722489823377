import React, { useEffect, useState } from "react";
import DisplayProfile from "./components/DisplayProfile";
import SignupLoginPrompt from "./components/SignupLoginPrompt";
import UserNotFound from "./components/UserNotFound";
import { useStoreState } from "react-context-hook";
import PageContainer from "../../shared_components/PageContainer/PageContainer";
import Confetti from "react-confetti";
import getUserByUsername from "../../../api/user/getUserByUsername";

export default function Profile(props) {
  const { user } = useStoreState("user");
  const [propsUser, setPropsUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  let content;
  if ((user.id === "" || user.id == null) && props.username == null) {
    content = <SignupLoginPrompt />;
  } else if (props.username != null && propsUser.ID === "") {
    content = <UserNotFound />;
  } else {
    content = <DisplayProfile propsUser={propsUser} />;
  }

  useEffect(() => {
    const getPropsUser = async () => {
      const user = await getUserByUsername(props.username);
      setPropsUser(user);
      setLoaded(true);
    };
    getPropsUser();
  }, [props.username]);

  return (
    <PageContainer app={true} active={"profile"}>
      {props.new && (
        <Confetti
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
          }}
          width={1100}
          height={1000}
          recycle={false}
        />
      )}
      {loaded ? content : null}
    </PageContainer>
  );
}
