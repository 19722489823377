import axios from "axios";
import { API } from "../constants";

/**
 * Get notifications for user
 *
 * @param userID: the userID
 */
const getNotifications = async (userID) => {
  let api = API + "/api/notifications?UserID=" + userID;
  return axios.get(api).then((data) => {
    return data.data;
  });
};

export default getNotifications;
