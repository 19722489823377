import axios from "axios";
import { API } from "../constants";

/**
 * updateSnippet: function that makes a PUT request to update a snippet's title or description
 *
 * @param {*} snippet object
 * @param {*} token
 */
const updateSnippet = async (data, token) => {
  axios
    .put(API + "/api/episode/snippet/update", {
      body: {
        snippetID: data.snippetID,
        userID: data.userID,
        snippetTitle: data.snippetTitle,
        snippetDescription: data.snippetDescription,
      },
      token: token,
    })
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
};

export default updateSnippet;
