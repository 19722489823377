import React from "react";
import * as s from "./StyledUserNotFound";

export default function UserNotFound(props) {
  return (
    <s.Prompt>
      User not found
    </s.Prompt>
  );
}
