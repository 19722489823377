import { API } from "../constants";
import axios from "axios";

/**
 * Function to retrieve specific podcast information
 *
 * @param id - episode id to retrieve
 */
const getEpisode = async (id) => {
  let api = API + "/api/episode?EpisodeID=" + id;
  return axios.get(api).then((response) => {
    return response.data;
  });
  // return {
  //     EpisodeID: 1,
  //     Title:
  //         "Clippers Worse w/ Morris? Dillon Brooks Best Young Player? | The Ball Boys ep.9",
  //     Comments: [],
  //     Likes: [],
  //     Img:
  //         "https://storage.buzzsprout.com/variants/2it4kn76nkodstlpiiwvbnnsgzpe/8d66eb17bb7d02ca4856ab443a78f2148cafbb129f58a3c81282007c6fe24ff2?.jpg",
  //     URL:
  //         "https://www.buzzsprout.com/632482/2751727-clippers-worse-w-morris-dillon-brooks-best-young-player-the-ball-boys-ep-9.mp3?blob_id=9375685",
  //     PodcastID: 1,
  //     Description: "",
  //     EpisodeNumber: 9,
  //     SeasonNumber: 1
  // };
};

export default getEpisode;
