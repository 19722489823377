import React, { useState, useEffect } from "react";
import { useStoreState } from "react-context-hook";
import * as s from "./StyledNotifications";
import NotificationItem from "../NotificationItem/NotificationItem";
import getNotifications from "../../../../api/notifications/getNotifications";
import readNotifications from "../../../../api/notifications/readNotifications";

const Notifications = () => {
  const { loggedIn, user } = useStoreState();

  const [active, setActive] = useState(false);
  const [called, setCalled] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const updateUnreadCount = (notifications) => {
    let count = 0;
    // eventually unslice when we develop a "view all" notifications page
    notifications.slice(0, 4).forEach((item) => {
      if (!item.Read) {
        count = count + 1;
      }
    });
    setUnreadCount(count);
  };

  useEffect(() => {
    const callNotifications = async () => {
      if (loggedIn && !called) {
        const notificationItems = await getNotifications(user.id);
        setNotifications(notificationItems);
        setCalled(true);
        updateUnreadCount(notificationItems);
      }
    };
    callNotifications();

    setInterval(() => {
      callNotifications();
    }, 60000);
  });

  const markNotificationRead = async (item) => {
    await readNotifications(item.UserID, item.NotificationID);
    if (loggedIn) {
      const notificationItems = await getNotifications(user.id);
      setNotifications(notificationItems);
      updateUnreadCount(notificationItems);
    }
  };

  const renderNotificationItems = (notificationItems) => {
    return (
      <>
        {notificationItems.slice(0, 4).map((item) => (
          <NotificationItem
            markNotificationRead={() => markNotificationRead(item)}
            item={item}
          />
        ))}
      </>
    );
  };

  return (
    <s.NotificationComponent>
      <s.VerticalContainer>
        <s.NotifVerticalCenter>
          {active ? (
            <s.NotifFilled onClick={() => setActive(false)} />
          ) : (
            <s.NotifNone onClick={() => setActive(true)} />
          )}
        </s.NotifVerticalCenter>
      </s.VerticalContainer>
      {unreadCount !== 0 ? (
        <s.NotificationNumber onClick={() => setActive(!active)}>
          {unreadCount}
        </s.NotificationNumber>
      ) : (
        <s.NoNumber />
      )}
      {active && (
        <div style={{ position: "absolute" }}>
          <s.BlueTriangle />
          <s.WhiteTriangle />
          <s.NotificationContainer>
            {notifications.length === 0 ? (
              <s.VerticalContainer
                onClick={() => {
                  setActive(false);
                }}
              >
                <s.HorizontalContainer>
                  <s.CloseText>No notifications! Click to close.</s.CloseText>
                </s.HorizontalContainer>
              </s.VerticalContainer>
            ) : (
              <>
                <s.HeaderContainer>
                  <s.HeaderText>Notifications</s.HeaderText>
                  <s.ViewAllText
                    onClick={() => {
                      setActive(false);
                    }}
                  >
                    Close
                  </s.ViewAllText>
                </s.HeaderContainer>
                <s.NotificationsList>
                  {renderNotificationItems(notifications)}
                </s.NotificationsList>
              </>
            )}
          </s.NotificationContainer>
        </div>
      )}
    </s.NotificationComponent>
  );
};

export default Notifications;
