import React, { useContext, useState } from "react";
import * as s from "./StyledPlaybarDefault";
import { useGetAndSet, useStoreState } from "react-context-hook";
import EpisodePlayButton from "../../../../assets/episode play button.svg";
import EpisodePauseButton from "../../../../assets/episode pause button.svg";
import Scissors from "../../../../assets/scissors.svg";
import formatSeconds from "../../../../utils/formatSeconds";
import SnippetInfoModal from "./SnippetInfoModal";
import { AudioPlayerContext } from "./AudioPlayerContext";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export default function PlaybarDefault(props) {
  const [speedOpen, setSpeedOpen] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [playing, setPlaying] = useGetAndSet("playing");
  const {
    seconds,
    setSeconds,
    duration,
    setIsCreateSnippetState,
    setSnippetStart,
    setProgressInterval,
    setSeeking,
    playerSeekTo,
  } = useContext(AudioPlayerContext);

  const {
    currentPodcastID,
    currentEpisodeID,
    currentImage,
    currentSeries,
    currentTitle,
  } = useStoreState();

  React.useEffect(() => {
    props.setPlaybackRate(speed);
    // eslint-disable-next-line
  }, [speed]);

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === " " && event.target === document.body) {
        event.preventDefault();
        setPlaying(!playing);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const handleSpeedChange = (event) => {
    setSpeed(event.target.value);
  };

  const handleSpeedClose = () => {
    setSpeedOpen(false);
  };

  const handleSpeedOpen = () => {
    setSpeedOpen(true);
  };

  return (
    <s.Container>
      {currentImage === null ? (
        <div />
      ) : (
        <s.MobileTitle>
          <s.PodcastEpisodeLink
            to={
              "/podcasts/" +
              currentPodcastID +
              "/" +
              currentSeries +
              "/" +
              currentEpisodeID +
              "/" +
              currentTitle
            }
          >
            {currentTitle}
          </s.PodcastEpisodeLink>
        </s.MobileTitle>
      )}
      <s.PodcastInfoContainer>
        {currentImage === null ? (
          <div />
        ) : (
          <s.PodcastThumbnail alt={"loading..."} src={currentImage} />
        )}
        <s.PodcastLinks>
          <s.PodcastFirstRow>
            <s.PodcastEpisodeLink
              to={
                "/podcasts/" +
                currentPodcastID +
                "/" +
                currentSeries +
                "/" +
                currentEpisodeID +
                "/" +
                currentTitle
              }
            >
              {currentTitle}
            </s.PodcastEpisodeLink>
            {currentImage === null ? (
              <div />
            ) : (
              <s.Scissors
                src={Scissors}
                onClick={() => {
                  if (seconds === 0) {
                    props.handleWarning(true);
                    return;
                  }
                  setProgressInterval(200);
                  const clipTime =
                    seconds > props.maxSnippetTime
                      ? props.maxSnippetTime
                      : seconds;
                  // Rewind to start of clip interval.
                  playerSeekTo(seconds - clipTime);
                  setSeconds(seconds - clipTime);
                  setSnippetStart(seconds);
                  setIsCreateSnippetState(true);
                }}
              />
            )}
          </s.PodcastFirstRow>
          <div style={{ zIndex: 2 }}>
            <s.PodcastSeriesLink
              to={"/podcasts/" + currentPodcastID + "/" + currentSeries}
            >
              {currentSeries}
            </s.PodcastSeriesLink>
          </div>
        </s.PodcastLinks>
      </s.PodcastInfoContainer>

      <s.PlaybarControls>
        {currentImage != null && (
          <s.PlaybarPlaybackSpeed style={{ visibility: "hidden" }}>
            <s.SpeedButton onClick={handleSpeedOpen}>
              {speed + "x"}
            </s.SpeedButton>
          </s.PlaybarPlaybackSpeed>
        )}
        <s.PlaybarControlsContainer>
          <s.PlaybarControlsFlex>
            <s.PlaybarControlButton onClick={() => playerSeekTo(seconds - 30)}>
              <s.RewindIcon />
            </s.PlaybarControlButton>
            <s.PlaybarControlButton
              onClick={() => setPlaying(!playing)}
              style={{ padding: 4 }}
            >
              {" "}
              {playing && currentImage != null ? (
                <s.PlayPauseButton src={EpisodePauseButton} alt="pause" />
              ) : (
                <s.PlayPauseButton src={EpisodePlayButton} alt="play" />
              )}
            </s.PlaybarControlButton>
            <s.PlaybarControlButton onClick={() => playerSeekTo(seconds + 30)}>
              <s.FastForwardIcon />
            </s.PlaybarControlButton>
          </s.PlaybarControlsFlex>
        </s.PlaybarControlsContainer>

        {currentImage != null && (
          <s.PlaybarPlaybackSpeed>
            <s.SpeedButton onClick={handleSpeedOpen}>
              {speed + "x"}
            </s.SpeedButton>
            <Select
              open={speedOpen}
              onClose={handleSpeedClose}
              onOpen={handleSpeedOpen}
              value={speed}
              onChange={handleSpeedChange}
              style={{ visibility: "hidden", position: "absolute" }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value={2}>2x</MenuItem>
              <MenuItem value={1.75}>1.75x</MenuItem>
              <MenuItem value={1.5}>1.5x</MenuItem>
              <MenuItem value={1.25}>1.25x</MenuItem>
              <MenuItem value={1}>1x</MenuItem>
            </Select>
          </s.PlaybarPlaybackSpeed>
        )}
      </s.PlaybarControls>

      <s.InputRange inputActive={currentImage != null}>
        <s.InputElapsed>{formatSeconds(seconds)}</s.InputElapsed>
        <input
          type="range"
          min={0}
          max={duration}
          step="any"
          value={seconds}
          onMouseDown={() => setSeeking(true)}
          onChange={(e) => setSeconds(parseFloat(e.target.value))}
          onMouseUp={(e) => {
            setSeeking(false);
            playerSeekTo(parseFloat(e.target.value));
          }}
        />
        <s.InputDuration>{formatSeconds(duration)}</s.InputDuration>
      </s.InputRange>
      <SnippetInfoModal
        open={props.openSnippetInfo}
        handleClose={props.handleSnippetInfoClose}
        snippet={props.snippet}
      />
    </s.Container>
  );
}
