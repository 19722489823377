import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as s from "./StyledPodcastItem";
import ImageNotFound from "../../../../../assets/image_not_found.png";
import getPodcast from "../../../../../api/podcasts/getPodcast";

const PodcastItem = ({ id }) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getPodcastData = async () => {
      getPodcast(id).then((item) => {
        setData(item.Podcast);
      });
    };

    if (!loaded) {
      getPodcastData();
      setLoaded(true);
    }
  }, [loaded, id]);

  const cleanTitle = (title) => {
    return title && title.includes("#") ? title.replace("#", "") : title;
  };

  return (
    <div>
      {data && (
        <s.ItemContainer>
          <s.ItemImage>
            <Link
              to={{
                pathname: "/podcasts/" + data.ID + "/" + cleanTitle(data.Title),
              }}
            >
              <s.ItemImageImg
                alt={data.Title}
                src={error ? ImageNotFound : data.Img}
                onError={(e) => {
                  setError(true);
                }}
              />
            </Link>
          </s.ItemImage>
          <s.ItemDetail
            style={{
              marginTop: "1.777777777vh",
              color: "#262626",
            }}
          >
            {data.Title}
          </s.ItemDetail>
        </s.ItemContainer>
      )}
    </div>
  );
};

export default PodcastItem;
