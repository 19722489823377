function pad(string) {
  return ("0" + string).slice(-2);
}

export default (seconds) => {
  if (isNaN(seconds)) {
    return `${0}:${0}`;
  } else {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  }
};
