import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProximaNova from "../../../../../assets/fonts/ProximaNova-Regular.otf";

export const ItemContainer = styled.div``;

export const ItemImage = styled.div`
  height: 25vw;
  width: 25vw;
  position: relative;

  @media (min-width: 576px) {
    position: revert;
    height: initial;
    width: initial;
  }
`;

export const ItemDetail = styled.h3`
  text-align: start;
  font-size: 0.875rem;
  font-family: "Proxima Nova Lt";
  max-width: 25vw;
  @media (min-width: 576px) {
    max-width: 20vw;
  }
  @media (min-width: 992px) {
    max-width: 13.888888888vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666666rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.66666666667rem;
  }
`;

export const ItemImageImg = styled(LazyLoadImage)`
  border-radius: 10px;
  height: 25vw;
  width: 25vw;
  position: absolute;
  @media (min-width: 576px) {
    position: revert;
  }
  @media (min-width: 576px) {
    height: 20vw;
    width: 20vw;
  }
  @media (min-width: 992px) {
    height: 13.888888888vw;
    width: 13.888888888vw;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.625rem;
  @media (min-width: 1920px) {
    margin-top: 0.83333333333rem;
  }
  @media (min-width: 2560px) {
    margin-top: 1.11111111111rem;
  }
  @media (min-width: 3840px) {
    margin-top: 1.66666666667rem;
  }
`;

export const UserImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: -16px;

  @media (min-width: 576px) {
    position: revert;
  }
  @media (min-width: 1920px) {
    width: 42px;
    height: 42px;
  }
  @media (min-width: 2560px) {
    width: 56px;
    height: 56px;
  }
  @media (min-width: 3840px) {
    width: 86px;
    height: 86px;
  }
`;

export const UserText = styled.div`
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  width: 10vw;
  font-size: 0.75rem;
  font-weight: 400;
  margin-left: 0.5rem;
  color: #262626;

  @media (min-width: 1920px) {
    font-size: 1rem;
    margin-left: 0.66666666666rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
    margin-left: 0.88888888888rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
    margin-left: 1.33333333333rem;
  }
`;

export const MobileUserText = styled.div`
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  width: 25vw;
  font-size: 0.75rem;
  font-weight: 400;
  color: #262626;
`;
