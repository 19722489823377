import styled from "styled-components";
import { Link } from "react-router-dom";
import ProximaNova from "../../../assets/fonts/ProximaNova-Regular.otf";
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

export const EpisodeItemContainer = styled.div`
  display: flex;
  margin-bottom: 24px;

  @media (min-width: 1920px) {
    margin-bottom: 32px;
  }
  @media (min-width: 2560px) {
    margin-bottom: 42px;
  }
  @media (min-width: 3840px) {
    margin-bottom: 64px;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  position: relative;
  width: 54px;
  height: 54px;

  @media (min-width: 992px) {
    width: 104px;
    height: 104px;
  }
  @media (min-width: 1920px) {
    width: 138px;
    height: 138px;
  }
  @media (min-width: 2560px) {
    width: 184px;
    height: 184px;
  }
  @media (min-width: 3840px) {
    width: 276px;
    height: 276px;
  }
`;

export const Image = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 4px;
  position: absolute;

  @media (min-width: 992px) {
    width: 104px;
    height: 104px;
    border-radius: 5px;
  }
  @media (min-width: 1920px) {
    width: 138px;
    height: 138px;
    border-radius: 7px;
  }
  @media (min-width: 2560px) {
    width: 184px;
    height: 184px;
    border-radius: 7px;
  }
  @media (min-width: 3840px) {
    width: 276px;
    height: 276px;
    border-radius: 12px;
  }
`;

export const PauseIcon = styled.img`
  && {
    height: 54px;
    width: 54px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
  }
  @media (min-width: 992px) {
    && {
      width: 104px;
      height: 104px;
    }
  }
  @media (min-width: 1920px) {
    && {
      height: 138px;
      width: 138px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 184px;
      width: 184px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 276px;
      width: 276px;
    }
  }
`;

export const PlayIcon = styled.img`
  && {
    height: 54px;
    width: 54px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
    margin-left: 2%;
  }
  @media (min-width: 992px) {
    && {
      width: 104px;
      height: 104px;
    }
  }
  @media (min-width: 1920px) {
    && {
      height: 138px;
      width: 138px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 184px;
      width: 184px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 276px;
      width: 276px;
    }
  }
`;

export const Info = styled.div`
  margin-left: 10px;
  flex: 1;
  max-height: 180px;
  overflow: hidden;
  @media (min-width: 576px) {
    margin-left: 16px;
  }
  @media (min-width: 992px) {
    max-width: 560px;
    width: 560px;
    margin-left: 24px;
  }
  @media (min-width: 1200px) {
    max-height: 104px;
    height: 104px;
    max-width: 780px;
    width: 780px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (min-width: 1920px) {
    margin-left: 32px;
    max-width: 1020px;
    width: 1020px;
    max-height: 138px;
    height: 138px;
  }
  @media (min-width: 2560px) {
    margin-left: 42px;
    max-height: 184px;
    height: 184px;
    max-width: 1332px;
    width: 1332px;
  }
  @media (min-width: 3840px) {
    margin-left: 64px;
    max-height: 276px;
    height: 276px;
    max-width: 1974px;
    width: 1974px;
  }
`;

export const EpisodeName = styled(Link)`
  line-height: 1.2;
  display: block;
  margin: 0;
  color: #4f4f4f;
  font-size: 0.875rem;
  font-family: Proxima Nova;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
  }
`;

export const Description = styled.p`
  margin: 0;
  color: #828282;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: normal;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  overflow: auto;
  height: 70px;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (min-width: 992px) {
    height: 58px;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-top: 10px;
    height: 84px;
    margin-bottom: 10px;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
    margin-top: 14px;
    height: 108px;
    margin-bottom: 14px;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
    margin-top: 20px;
    height: 162px;
    margin-bottom: 20px;
  }
`;

export const LikeContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: start;
  width: 90px;

  @media (min-width: 992px) {
    width: 104px;
  }
  @media (min-width: 1920px) {
    width: 138px;
  }
  @media (min-width: 2560px) {
    width: 184px;
  }
  @media (min-width: 3840px) {
    width: 276px;
  }
`;

export const CommentIcon = styled(ModeCommentOutlinedIcon)`
  cursor: pointer;
  margin-right: 1%;
  && {
    font-size: 1rem;
    padding-top: 1px;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
      padding-top: 3px;
    }
  }
`;

export const LikeNumber = styled.p`
  margin: 0;
  color: #333333;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  margin-left: 0.25rem;
  margin-right: 0.625rem;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-left: 0.33333333333rem;
    margin-right: 0.83333333333rem;
    margin-bottom: 1px;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-left: 0.44444444444rem;
    margin-right: 1.11111111111rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333333rem;
    margin-left: 0.66666666666rem;
    margin-right: 1.66666666667rem;
  }
`;

export const LikeIcon = styled(FavoriteBorderIcon)`
  cursor: pointer;
  && {
    font-size: 1rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
    }
  }
`;

export const LikedIcon = styled(FavoriteIcon)`
  cursor: pointer;
  && {
    font-size: 1rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
    }
  }
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RightBottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media (min-width: 1920px) {
    margin-bottom: 1px;
  }
  @media (min-width: 2560px) {
    margin-bottom: 2px;
  }
  @media (min-width: 3840px) {
    margin-bottom: 3px;
  }
`;

export const DateSVG = styled.img`
  width: 14px;
  height: 14px;
  @media (min-width: 1920px) {
    width: 19px;
    height: 19px;
  }
  @media (min-width: 2560px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 3840px) {
    width: 36px;
    height: 36px;
    margin-bottom: 1px;
  }
`;
export const DateText = styled.h4`
  margin: 0;
  line-height: 1;
  color: #333333;
  font-weight: 500;
  font-size: 0.875rem;
  white-space: nowrap;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  margin-left: 0.3125rem;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-left: 0.41666666666rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-left: 0.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333333rem;
    margin-left: 0.83333333333rem;
  }
`;

export const HourglassEmptyIconn = styled(HourglassEmptyIcon)`
  && {
    font-size: 1rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
    }
  }
`;

export const CommentsLink = styled(Link)`
  color: inherit;
  line-height: 1;
`;
