import React from "react";
import TeamDisplay from "./components/TeamDisplay/TeamDisplay";
import Header from "../../shared_components/Header/Header";
import Footer from "../../shared_components/Footer/Footer";

const TeamPage = () => {
  return (
    <div>
      <Header />
      <TeamDisplay />
      <Footer />
    </div>
  );
};

export default TeamPage;
