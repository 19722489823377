// get user 1
// import defaultProfile from "../../assets/user.svg";
import { API } from "../constants";
import axios from "axios";

/**
 * Get the user given a user ID
 *
 * @param userID: the userID
 */
const getUser = async (userID) => {
  let api = API + "/api/users?UserID=" + userID;
  return axios
    .get(api)
    .then((response) => {
      // The response is a Response instance.
      // You parse the data into a useable format using `.json()`
      return response;
    })
    .then((data) => {
      console.log(data);
      // `data` is the parsed version of the JSON returned from the above endpoint.
      return data.data;
    });
  // return {
  //     Email: "Email",
  //     Name: "Author First Last",
  //     Username: "Username",
  //     Password: "Password",
  //     Bio: "Bio",
  //     Ps: "Ps",
  //     Img: ""
  // };
};

export default getUser;
