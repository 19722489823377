import styled from "styled-components";
import { Link } from "react-router-dom";
import ProximaNovaWoff from "../../../assets/fonts/ProximaNova-Semibold.woff";
import ProximaNovaWoff2 from "../../../assets/fonts/ProximaNova-Semibold.woff2";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 11.11111111vw;
  z-index: 10;
  left: 0;
  height: calc(100vh - 5.555555555vh - 90px);
  top: 5.555555555vh;
  border-right: 2px solid rgba(179, 222, 249, 0.5);
  background-color: white;
  @media (max-width: 991px) {
    display: none;
  }
  @media (min-width: 2560px) {
    height: calc(100vh - 5.555555555vh - 120px);
  }
`;

export const TabText = styled.h4`
  @font-face {
    font-family: "Proxima Nova Lt";
    src: url(${ProximaNovaWoff2}) format("woff2"),
      url(${ProximaNovaWoff}) format("woff");
    font-weight: 600;
    font-style: normal;
  }
  font: bold 0.8rem "Proxima Nova Lt";
  color: #828282;
  margin: 0;
  margin-right: 36px;
  margin-top: 2px;
  width: 100%;
  text-align: left;
  position: absolute;
  margin-left: 33%;
  max-width: 65%;
  ${({ $active }) =>
    $active &&
    `
  color: #2F80ED;`}

  @media (min-width: 1200px) {
    font-size: 1rem;
    margin-left: 31%;
  }
  @media (min-width: 1600px) {
    font-size: 1rem;
    margin-left: 29%;
  }
  @media (min-width: 1920px) {
    font-size: 1.4rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.85rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

// I wanted to do 6.25% for margin-right, but it was too small.
// Ended up doing a quarter between 6.25 and 12.5
export const ListenIcon = styled.div`
  margin-left: 12.5%;
  margin-right: 7.8125%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LibraryIcon = styled.div`
  margin-left: 13.75%;
  margin-right: 7.8125%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Tab = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-left: 0.555555555vw solid white;

  text-decoration: none;
  line-height: 22.5px;
  margin-top: 3.111111vh;

  height: 3vh;
  :hover {
    text-decoration: none;
  }

  ${({ active }) =>
    active &&
    `
  border-left: 0.555555555vw solid #2F80ED;

`}
`;

export const Listen = styled.img`
  height: 65%;
`;

export const Library = styled.img`
  height: 65%;
`;

export const FeedbackContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 0.5em;
`;

export const Feedback = styled.a`
  @font-face {
    font-family: "Proxima Nova Lt";
    src: url(${ProximaNovaWoff2}) format("woff2"),
      url(${ProximaNovaWoff}) format("woff");
    font-weight: 600;
    font-style: normal;
  }
  text-align: start;
  font: bold 0.8rem "Proxima Nova Lt";

  color: #2F80ED;
  text-decoration: underline;
  margin-bottom: 1.5em;
  cursor: pointer;
  display: block;

  @media (min-width: 1200px) {
    font-size: 12px;
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.25rem;
  }
  @media (min-width: 3840px) {
    font-size: 1.75rem;
  }
`;

export const Community = styled.img`
  height: 65%;
`;

export const CommunityIcon = styled.div`
  margin-left: 11%;
  height: 100%;
  display: flex;
  align-items: center;
`;
