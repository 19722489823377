import axios from "axios";
import { API } from "../constants";
/**
 * Adds a new snippet id to an audioboard's snippetIDs.
 *
 * @param snippetId - snippet id 
 * @param audioboardId - audioboard id
 * @param userId - audioboard id
 * @param token - token
 */
const addSnippetToAudioBoard = async (data) => {
  return axios
    .post(API + "/api/audioboard/addsnippet", {
      body: {
        userID: data.userID,
        audioboardID: data.audioboardID,
        snippetID: data.snippetID,
      },
      token: window.localStorage.getItem("token"),
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default addSnippetToAudioBoard;
