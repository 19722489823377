import axios from "axios";
import { API } from "../constants";

/**
 * deleteSnippet: function that makes a PUT request to delete a snippet
 *
 * @param {*} id - snippet id to delete
 * @param {*} token
 */
const deleteSnippet = async (id, token) => {
  axios
    .put(API + "/api/episode/snippet/delete", {
      body: {
        snippetID: id,
      },
      token: token,
    })
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
};

export default deleteSnippet;
