import React from "react";
import PodcastRecItem from "../PodcastRecItem/PodcastRecItem";
import Carousel from "react-material-ui-carousel";
import * as s from "./StyledPodcastRecs";
import { useMediaQuery } from "react-responsive";

const splitArray = (arr, size) => {
  var arr2 = arr.slice(0),
    arrays = [];

  while (arr2.length > 0) {
    arrays.push(arr2.splice(0, size));
  }

  return arrays;
};

const PodcastRecs = ({ podcasts }) => {
  // const splitPodcastsIntoTwo =

  const isTwoItems = useMediaQuery({ minWidth: 0, maxWidth: 575 });

  const isThreeItems = useMediaQuery({ minWidth: 576, maxWidth: 991 });

  const isFiveItems = useMediaQuery({ minWidth: 992 });

  // const twoItems = splitArray(podcasts, 2);
  const threeItems = splitArray(podcasts, 3);
  const fiveItems = splitArray(podcasts, 5);

  const Grid = ({ items }) => {
    return (
      <s.GridContainer>
        {items
          .filter((p) => p.Title.trim().length)
          .map((item) => (
            <PodcastRecItem data={item} />
          ))}
      </s.GridContainer>
    );
  };

  const threeButtonStyles = {
    style: {
      backgroundColor: "white",
      color: "#007bff",
      zIndex: "11 !important",
      boxShadow: "0 4px 6px 0 #DCDCDC, 0 2px 0px 0 #DCDCDC",
      right: "1vw !important",
    },
  };

  const fiveButtonStyles = {
    style: {
      backgroundColor: "white",
      color: "#007bff",
      zIndex: "11 !important",
      boxShadow: "0 4px 6px 0 #DCDCDC, 0 2px 0px 0 #DCDCDC",
      right: "0vw",
    },
  };

  const wrapperStyles = {
    style: {
      top: "8vh",
      height: "unset",
      "&:hover": {
        "& $button": {
          backgroundColor: "unset",
          filter: "unset",
          opacity: "1 !important",
        },
        opacity: "1 !important",
      },
    },
  };

  return (
    <s.CategoryContainer>
      <s.GroupHeadings>Recommended by the Castyr Team</s.GroupHeadings>

      {isTwoItems && <Grid items={podcasts} />}

      {isThreeItems && (
        <Carousel
          navButtonsAlwaysVisible={true}
          indicators={false}
          interval={100000}
          navButtonsProps={threeButtonStyles}
          navButtonsWrapperProps={wrapperStyles}
        >
          {threeItems.map((group) => {
            return <Grid items={group} />;
          })}
        </Carousel>
      )}

      {isFiveItems && (
        <Carousel
          navButtonsAlwaysVisible={true}
          indicators={false}
          interval={100000}
          navButtonsProps={fiveButtonStyles}
          navButtonsWrapperProps={wrapperStyles}
        >
          {fiveItems.map((group) => {
            return <Grid items={group} />;
          })}
        </Carousel>
      )}
    </s.CategoryContainer>
  );
};

export default PodcastRecs;
