import React from "react";
import PodcastItem from "../PodcastItem/PodcastItem";
import * as s from "./StyledPodcastCategory";

const PodcastCategory = ({ podcasts, header, categoryLoaded }) => {
  return (
    <s.CategoryContainer>
      <s.GroupHeadings>{header}</s.GroupHeadings>
      <s.GridContainer>
        {podcasts
          .filter((p) => p.Title.trim().length)
          .map((p) => {
            return <PodcastItem data={p} />;
          })}
      </s.GridContainer>
    </s.CategoryContainer>
  );
};

export default PodcastCategory;
