import { API } from "../constants";
import axios from "axios";

/**
 * Get the user given a username
 *
 * @param username: the username
 */
const getUserByUsername = async (username) => {
  let api = API + "/api/users/username?Username=" + username;
  return axios
    .get(api)
    .then((response) => {
      return response.data;
    })
};

export default getUserByUsername;
