import styled from "styled-components";
import ProximaNova from "../../../../../assets/fonts/ProximaNova-Regular.otf";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { withStyles } from "@material-ui/core/styles";
import { default as Linkk } from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import ShareIcon from "@material-ui/icons/Share";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 24px;

  @media (min-width: 1920px) {
    margin-bottom: 32px;
  }
  @media (min-width: 2560px) {
    margin-bottom: 42px;
  }
  @media (min-width: 3840px) {
    margin-bottom: 64px;
  }
`;
export const ThumbnailContainer = styled.div``;
export const Title = styled(Link)`
  line-height: 1.2;
  display: block;
  margin: 0;
  text-decoration: none;
  color: #0071e2;
  font-size: 1rem;
  font-family: Proxima Nova Lt;
  margin-bottom: 0.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;

  @media (min-width: 992px) {
    display: block;
    overflow: initial;
    -webkit-box-orient: initial;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (min-width: 1920px) {
    font-size: 1.33333333333rem;
    margin-bottom: 0.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.77777777777rem;
    margin-bottom: 0.44444444444rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.65rem;
    margin-bottom: 0.66666666666rem;
  }
`;
export const PodcastName = styled(Link)`
  line-height: 1.2;
  display: block;
  margin: 0;
  color: #4f4f4f;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  font-family: Proxima Nova Lt;
  text-decoration: none;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-bottom: 0.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
    margin-bottom: 0.44444444444rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
    margin-bottom: 0.66666666666rem;
  }
`;
export const EpisodeName = styled(Link)`
  line-height: 1.2;
  display: block;
  margin: 0;
  color: #828282;
  font-size: 0.875rem;
  font-family: Proxima Nova Lt;
  margin-bottom: 0.625rem;
  text-decoration: none;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-bottom: 0.83333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
    margin-bottom: 1.111111111111rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
    margin-bottom: 1.66666666667rem;
  }
`;
export const Description = styled.p`
  margin: 0;
  color: #828282;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: normal;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  @media (min-width: 992px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
  }
`;
export const ShareContainer = styled.div`
  margin-left: 8px;
  display: none;
  @media (min-width: 576px) {
    display: block;
  }
  @media (min-width: 992px) {
    margin-left: 50px;
  }
  @media (min-width: 1920px) {
    margin-left: 65px;
  }
  @media (min-width: 2560px) {
    margin-left: 80px;
  }
  @media (min-width: 3840px) {
    margin-left: 100px;
  }
`;
export const ShareText = styled.div`
  color: #717171;
  font-size: 0.875rem;
  margin-bottom: 2px;

  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
    margin-bottom: 4px;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
    margin-bottom: 6px;
  }
`;
export const DeleteContainer = styled.div`
  display: none;
  margin-left: 8px;

  @media (min-width: 576px) {
    display: flex;
  }
  @media (min-width: 992px) {
    margin-left: 16px;
  }
  @media (min-width: 1920px) {
    margin-left: 24px;
  }
  @media (min-width: 3840px) {
    margin-left: 36px;
  }
`;

export const DeleteIcon = withStyles({
  root: {
    fill: "#4f4f4f",
    cursor: "pointer",
    "@media (min-width: 1920px)": {
      fontSize: "1.65rem",
    },
    "@media (min-width: 2560px)": {
      fontSize: "2rem",
    },
    "@media (min-width: 3840px)": {
      fontSize: "3rem",
    },
  },
})(DeleteOutlineIcon);

export const ShareLinkRow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`;

export const ShareLinkPositioning = styled.div`
  position: relative;
  background: #f2f2f2;
  border-radius: 3px;
`;

export const ShareLink = styled.p`
  padding: 4px;
  color: #4f4f4f;
  margin-left: 24px;
  outline: none;
  appearance: none;
  font-size: 0.75rem;
  border: none;
  font-family: "Proxima Nova Lt";
  font-weight: 400;
  width: 192px;
  max-width: 192px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
    width: 256px;
    max-width: 256px;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
    width: 340px;
    max-width: 340px;
    margin-left: 32px;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
    width: 512px;
    max-width: 512px;
    margin-left: 54px;
  }
`;

export const ShareLinkMobile = styled.p`
  padding: 4px;
  color: #4f4f4f;
  margin-left: 24px;
  outline: none;
  appearance: none;
  font-size: 0.75rem;
  border: none;
  font-family: "Proxima Nova Lt";
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
    margin-left: 32px;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
    margin-left: 54px;
  }
`;

export const LinkIcon = styled(Linkk)`
  position: absolute;
  transform: rotate(-40deg);
  padding: 3px;
  margin-top: 1px;
  @media (min-width: 1920px) {
    padding: 1px;
    margin-top: 3px;
    margin-left: 2px;
  }
  @media (min-width: 2560px) {
    padding: 0px;
    margin-top: 6px;
    margin-left: 6px;
    && {
      font-size: 1.7rem;
    }
  }
  @media (min-width: 3840px) {
    margin-top: 8px;
    margin-left: 12px;
    && {
      font-size: 2.5rem;
    }
  }
`;

export const IconButtonn = styled(IconButton)`
  && {
    outline: none;
    margin-top: -12px;
    padding: 6px;
  }
  @media (min-width: 576px) {
    && {
      padding: 12px;
    }
  }
`;

export const ShareIconn = styled(ShareIcon)`
  cursor: pointer;
  && {
  }
  @media (min-width: 992px) {
    margin-top: 0px;
    && {
      display: none;
    }
  }
  @media (min-width: 1920px) {
  }
`;

export const MobileIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  @media (min-width: 576px) {
    display: none;
  }
`;

export const DialogTitlee = styled(DialogTitle)``;

export const Dialogg = styled(Dialog)``;

export const DialogActionss = styled(DialogActions)``;

export const DialogContentt = styled(DialogContent)``;

export const DialogContentTextt = styled(DialogContentText)``;

export const Buttonn = styled(Button)`
  && {
    color: #59a7ed;
  }
`;

export const ShareIconButton = styled(IconButton)`
  && {
    outline: none;
    margin-top: -12px;
    padding: 6px;
  }
  @media (min-width: 576px) {
    && {
      padding: 12px;
    }
  }
  @media (min-width: 992px) {
    margin-top: 0px;
    && {
      display: none;
    }
  }
`;

export const ThumbnailPlayContainer = styled(Link)`
  display: block;
  position: relative;
  width: 90px;
  height: 90px;
  @media (min-width: 992px) {
    width: 104px;
    height: 104px;
  }
  @media (min-width: 1920px) {
    width: 138px;
    height: 138px;
  }
  @media (min-width: 2560px) {
    width: 184px;
    height: 184px;
  }
  @media (min-width: 3840px) {
    width: 276px;
    height: 276px;
  }
`;

export const PlayImage = styled.img`
  position: absolute;
  z-index: 5;
  width: 90px;
  height: 90px;
  @media (min-width: 992px) {
    width: 104px;
    height: 104px;
  }
  @media (min-width: 1920px) {
    width: 138px;
    height: 138px;
  }
  @media (min-width: 2560px) {
    width: 184px;
    height: 184px;
  }
  @media (min-width: 3840px) {
    width: 276px;
    height: 276px;
  }
`;

export const Thumbnail = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 4px;
  position: absolute;

  @media (min-width: 992px) {
    width: 104px;
    height: 104px;
    border-radius: 5px;
  }
  @media (min-width: 1920px) {
    width: 138px;
    height: 138px;
    border-radius: 7px;
  }
  @media (min-width: 2560px) {
    width: 184px;
    height: 184px;
    border-radius: 7px;
  }
  @media (min-width: 3840px) {
    width: 276px;
    height: 276px;
    border-radius: 12px;
  }
`;

export const Info = styled.div`
  margin-left: 24px;
  max-width: 350px;
  width: 350px;
  max-height: 180px;
  overflow: hidden;

  @media (min-width: 992px) {
    max-height: 104px;
    height: 104px;
    max-width: 230px;
    width: 230px;
  }
  @media (min-width: 1200px) {
    max-width: 450px;
    width: 450px;
  }
  @media (min-width: 1920px) {
    margin-left: 32px;
    max-width: 600px;
    width: 600px;
    max-height: 138px;
    height: 138px;
  }
  @media (min-width: 2560px) {
    margin-left: 42px;
    max-height: 184px;
    height: 184px;
    max-width: 800px;
    width: 800px;
  }
  @media (min-width: 3840px) {
    margin-left: 64px;
    max-height: 276px;
    height: 276px;
    max-width: 1200px;
    width: 1200px;
  }
`;

export const PlayIcon = styled.img`
  && {
    height: 90px;
    width: 90px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
    margin-left: 2%;
  }
  @media (min-width: 992px) {
    && {
      width: 104px;
      height: 104px;
    }
  }
  @media (min-width: 1920px) {
    && {
      height: 138px;
      width: 138px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 184px;
      width: 184px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 276px;
      width: 276px;
    }
  }
`;
