import axios from "axios";
import { API } from "../constants";

/**
 * Delete a comment from an episode
 *
 * @param episodeID: episode ID
 * @param userID: user ID
 * @param commentID: comment ID
 */
const deleteEpisodeComment = async (episodeID, userID, commentID) => {
  return axios
    .put(API + "/api/episode/removecomment", {
      body: {
        EpisodeID: episodeID,
        UserID: userID,
        CommentID: commentID,
      },
      token: window.localStorage.getItem("token"),
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default deleteEpisodeComment;
