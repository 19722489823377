import React, { useState, useEffect } from "react";
import * as s from "./StyledSnippetLibrary";
import SnippetLibraryItem from "./SnippetLibraryItem/SnippetLibraryItem";
import getUserSnippets from "../../../../api/snippets/getUserSnippets";

export default function SnippetLibrary() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [snippets, setSnippets] = useState([]);
  const [reorderedSnippets, setReorderedSnippets] = useState([]);
  const [snippetCount, setSnippetCount] = useState(0);
  const deleteSnippetLocally = (index) => {
    let snippetCopy = [...reorderedSnippets];
    snippetCopy[index] = {};
    setReorderedSnippets(snippetCopy);
    setSnippetCount(snippetCount - 1);
  };

  useEffect(() => {
    getUserSnippets(window.localStorage.getItem("token")).then((response) => {
      setSnippets(response);
      setSnippetCount(response.length);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    setReorderedSnippets(
      []
        .concat(snippets)
        .sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))
    );
  }, [snippets]);

  if (!isLoaded) {
    return <div></div>;
  }
  const snippetsContent = reorderedSnippets.map((value, index) => {
    return (
      <SnippetLibraryItem
        snippet={value}
        key={index}
        index={index}
        delete={deleteSnippetLocally}
      />
    );
  });

  return (
    <s.Container>
      {snippets.length === 0 || snippetCount === 0 ? (
        <s.NoSnippets>
          No snippets found, try creating snippets by clicking the scissors icon
          while playing a podcast!
        </s.NoSnippets>
      ) : (
        <s.SnippetsContainer>{snippetsContent}</s.SnippetsContainer>
      )}
    </s.Container>
  );
}
