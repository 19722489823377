import React, { useState, useEffect } from "react";
import PageContainer from "../../shared_components/PageContainer/PageContainer";
import PodcastHeader from "./PodcastHeader/PodcastHeader";
import PodcastEpisodeList from "./PodcastEpisodeList/PodcastEpisodeList";
import getPodcast from "../../../api/podcasts/getPodcast";

const PodcastPage = (props) => {
  const id = props.id;
  const page = props.page;
  const [podcast, setPodcast] = useState({ Podcast: {}, Episode: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (podcast.Podcast.Title === undefined) {
      setPodcast({ Podcast: { Title: "" }, Episode: [] });
      getPodcast(id).then((podcastData) => {
        setPodcast(podcastData);
        setLoading(false);
      });
    }
  }, [id, podcast.Podcast.Title]);

  return (
    <PageContainer active={""}>
      <PodcastHeader podcast={podcast.Podcast} name={page} loading={loading} />
      <PodcastEpisodeList
        podcastID={id}
        defaultPic={podcast.Podcast.Img}
        episodes={podcast.Episode}
        name={page}
      />
    </PageContainer>
  );
};

export default PodcastPage;
