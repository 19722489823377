import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import IconButton from "@material-ui/core/IconButton";

export const Container = styled.div``;

export const Popoverr = withStyles(() => ({
  paper: {
    padding: "1rem",
    maxWidth: 300,
    "@media (min-width: 992px)": {
      maxWidth: 440,
    },
    "@media (min-width: 1920px)": {
      padding: "1.33333333333rem",
      borderRadius: 6,
      maxWidth: 580,
    },
    "@media (min-width: 2560px)": {
      padding: "1.77777777778rem",
      borderRadius: 8,
      maxWidth: 780,
    },
    "@media (min-width: 3840px)": {
      padding: "2.66666666667rem",
      borderRadius: 14,
      maxWidth: 1172,
    },
    "&&": {
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
}))(Popover);

export const InfoHeader = styled.h5`
  color: #4f4f4f;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;
  margin: 0;
  padding: 0;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333333rem;
  }
`;

export const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.375rem;
  @media (min-width: 2560px) {
    margin-top: 0.75rem;
  }
  @media (min-width: 3840px) {
    margin-top: 1.5rem;
  }
`;

export const PlaylistAddIconn = styled(PlaylistAddIcon)`
  && {
    fill: #828282;
  }
`;

export const AddText = styled.h3`
  color: #4f4f4f;
  font-family: "Proxima Nova";
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 300;
  margin-left: 0.375rem;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const AddContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  cursor: pointer;
  width: max-content;
  border-bottom: 1px solid #828282;
  @media (min-width: 2560px) {
    margin-top: 6px;
  }
  @media (min-width: 3840px) {
    margin-top: 12px;
  }
`;

export const IconButtonn = styled(IconButton)``;
