import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export const RightDrawer = withStyles((theme) => ({
  paper: {
    marginTop: 52, //50
    width: 250,
    height: "calc(100% - 105px - 52px)",
    borderLeft: "2px solid rgba(179, 222, 249, 0.5)",
    "&&": {
      zIndex: 0,
    },
    "@media (min-width: 576px)": {
      height: "calc(100% - 90px - 52px)",
    },
    "@media (min-width: 992px)": {
      marginTop: "5.555555555vh",
      height: "calc(100% - 90px - 5.555555555vh)",
    },
    "@media (min-width: 1200px)": {
      width: 280,
    },
    "@media (min-width: 1440px)": {
      width: 346,
    },
    "@media (min-width: 1920px)": {
      width: 460,
    },
    "@media (min-width: 2560px)": {
      height: "calc(100% - 120px - 5.555555555vh)",
      width: 614,
    },
    "@media (min-width: 3840px)": {
      width: 920,
    },
  },
}))(Drawer);

export const BottomDrawer = withStyles((theme) => ({
  paper: {
    marginBottom: 105,
    maxHeight: "calc(100% - 105px - 52px)",
    height: "calc(100% - 105px - 52px)",
    "&&": {
      zIndex: 1000,
    },
    "@media (min-width: 576px)": {
      marginBottom: 90,
      height: "calc(100% - 90px - 52px)",
      maxHeight: "calc(100% - 90px - 52px)",
    },
  },
}))(Drawer);

export const IconButtonn = styled(IconButton)``;

export const CloseIconn = styled(CloseIcon)`
  @media (min-width: 992px) {
    && {
      font-size: 1.75rem;
    }
  }
  @media (min-width: 1920px) {
    && {
      font-size: 2.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 3.11111111111rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 4.66666666667rem;
    }
  }
`;

export const CreateCommentContainer = styled.div`
  width: calc(100% - 16%);
  bottom: 5%;
  position: absolute;
  margin-left: 8%;
  margin-right: 8%;
  display: flex;
  flex-direction: column-reverse;
`;

export const CommentButton = withStyles((theme) => ({
  root: {
    color: "white",
    "&:hover": {
      backgroundColor: "#007ac9",
    },
    backgroundColor: "#2F80ED",
    textTransform: "none",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    fontWeight: "bold",
    borderRadius: 5,
    width: 92,
    height: 30,
    marginTop: 12,

    "@media (min-width: 992px)": {
      marginTop: 16,
    },
    "&&": {
      boxShadow: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.1666666rem",
      borderRadius: 6.66666666667,
      width: 122,
      height: 40,
      marginTop: 20,
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.5555555rem",
      borderRadius: 9,
      width: 164,
      height: 53.3333333333,
      marginTop: 24,
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.3333333rem",
      borderRadius: 13,
      width: 244,
      height: 80,
      marginTop: 36,
    },
  },
}))(Button);

export const CreateCommentText = withStyles({
  root: {
    flex: "1",
    "& .MuiInputBase-multiline": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      color: "#828282",
      fontSize: ".75rem",
      fontWeight: "400",
      paddingTop: "0.625rem",
      paddingLeft: "1rem",
      fontFamily: "Proxima Nova",
      "@media (min-width: 992px)": {
        fontSize: "0.875rem",
      },
      "@media (min-width: 1920px)": {
        fontSize: "1.16666666667rem",
      },
      "@media (min-width: 2560px)": {
        fontSize: "1.5rem",
        paddingTop: "1.11111111111rem",
        paddingLeft: "1.77777777778rem",
      },
      "@media (min-width: 3840px)": {
        fontSize: "2.25rem",
        paddingTop: "2rem",
        paddingLeft: "2.5rem",
      },
    },
    "& label.Mui-focused": {
      color: "#2F80ED",
    },
    "& .MuiFilledInput-root ": {
      backgroundColor: "#F2F2F2",
      borderRadius: 5,
      "@media (min-width: 1920px)": {
        borderRadius: 7,
      },
      "@media (min-width: 2560px)": {
        borderRadius: 9,
      },
      "@media (min-width: 3840px)": {
        borderRadius: 12,
      },
    },
    "& .MuiInputBase-input::placeholder ": {
      opacity: 1,
    },
  },
})(TextField);

export const CreateCommentAuthorPic = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
  @media (min-width: 1920px) {
    width: 42px;
    height: 42px;
    margin-right: 16px;
  }
  @media (min-width: 2560px) {
    width: 56px;
    height: 56px;
    margin-right: 20px;
  }
  @media (min-width: 3840px) {
    width: 84px;
    height: 84px;
    margin-right: 32px;
  }
`;

export const CreateCommentTextContainer = styled.div`
  display: flex;
`;

export const CommentButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ThreadText = styled.h3`
  color: #333333;
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const ThreadTitle = styled.h4`
  color: #828282;
  font-family: "Proxima Nova Lt";
  font-weight: bold;
  font-size: 0.875rem;
  margin: 0;
  margin-left: 9%;
  margin-top: -0.5rem;
  margin-right: 8%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 1920px) {
    font-size: 1.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.75rem;
    margin-top: -0.35rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.5rem;
    margin-top: -0.25rem;
  }
`;

export const ThreadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 9%;
  margin-right: 2%;
  margin-top: 3%;
`;

export const CommentsContainer = styled.div`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  height: calc(100% - 105px - 5% - 30px - 12px - 68px - 20px);
  overflow: scroll;
  @media (min-width: 576px) {
  }
  @media (min-width: 1920px) {
    height: calc(100% - 105px - 5% - 40px - 16px - 86px - 60px);
    margin-top: 40px;
  }
  @media (min-width: 2560px) {
    margin-top: 48px;
    height: calc(100% - 120px - 5% - 53px - 20px - 112px - 90px);
  }
  @media (min-width: 3840px) {
    margin-top: 68px;
    height: calc(100% - 120px - 5% - 80px - 32px - 165px - 172px);
  }
`;

export const FullHeightCommentsContainer = styled.div`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  overflow: scroll;
  height: calc(100% - 105px - 64px);

  @media (min-width: 576px) {
    height: calc(100% - 90px - 64px);
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1920px) {
    margin-top: 40px;
    height: calc(100% - 90px - 80px);
  }
  @media (min-width: 2560px) {
    height: calc(100% - 228px);
    margin-top: 48px;
  }
  @media (min-width: 3840px) {
    margin-top: 68px;
    height: calc(100% - 324px);
  }
`;

export const NoCommentsContainer = styled.div`
  display: flex;
  margin-left: 9%;
  margin-right: 9%;
  margin-bottom: 0.875rem;
  @media (min-width: 1920px) {
    margin-bottom: 1.5rem;
  }
  @media (min-width: 2560px) {
    margin-bottom: 1.75rem;
  }
  @media (min-width: 3840px) {
    margin-bottom: 2.5rem;
  }
`;

export const NoComments = styled.h4`
  color: #828282;
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 0.875rem;
  @media (min-width: 1920px) {
    font-size: 1.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.75rem;
    margin-top: -0.35rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.5rem;
    margin-top: -0.25rem;
  }
`;
