import React, { useState, useEffect } from "react";
import * as s from "./StyledAudioBoards";
import addAudioBoard from "../../../../../api/audioboards/addAudioBoard";
import getAudioBoards from "../../../../../api/audioboards/getAudioBoards";
import FocusedAudioBoard from "./FocusedAudioBoard/FocusedAudioBoard";
import { useStoreState } from "react-context-hook";
import deleteAudioBoard from "../../../../../api/audioboards/deleteAudioboard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function AudioBoards(props) {
  const { user } = useStoreState();
  const [audioboards, setAudioBoards] = useState([]);
  const [audioboard, setAudioBoard] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = React.useState("");
  const [color, setColor] = React.useState("#2f80ed");
  const pageUserId = props.pageUserId;
  const message =
    pageUserId !== ""
      ? "This user hasn't created any audioboards yet."
      : "You haven't created any audioboards yet.";

  useEffect(() => {
    getAudioBoards(pageUserId === "" ? user.id : pageUserId).then(
      (response) => {
        setAudioBoards(response);
        setLoaded(true);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUserId]);

  useEffect(() => {
    if (title.length > 0) {
      setIsDisabled(false);
    }
    if (title.length === 0) {
      setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (!loaded) {
    return <div></div>;
  }

  return (
    <s.Container>
      <s.CenterContainer>
        <s.SnippetsHeader>Snippets</s.SnippetsHeader>

        <s.Tabss value={value} onChange={handleChange}>
          <s.Tabb label="Audio Boards" />
          {pageUserId === "" && (
            <s.NewBoardContainer>
              <s.EditSaveButton onClick={() => setOpen(true)}>
                <s.AddIconn />
                New Board
              </s.EditSaveButton>
            </s.NewBoardContainer>
          )}
        </s.Tabss>

        <TabPanel value={value} index={0}>
          <s.AudioBoards>
            {audioboards.length > 0 ? (
              audioboards.map((board, index) => {
                return (
                  <s.AudioBoard key={index}>
                    <s.AudioBoardThumbnail
                      style={{ backgroundColor: board.ThumbnailColor }}
                      onClick={() => {
                        setValue(1);
                        setAudioBoard(audioboards[index]);
                      }}
                    ></s.AudioBoardThumbnail>
                    <s.AudioBoardInfo>
                      <s.AudioBoardInfoText
                        onClick={() => {
                          setValue(1);
                          setAudioBoard(audioboards[index]);
                        }}
                      >
                        {board.Title}
                      </s.AudioBoardInfoText>
                      {pageUserId === "" && (
                        <s.IconButtonn
                          size={"small"}
                          onClick={(event) => {
                            deleteAudioBoard(board.AudioBoardID, user.id).then(
                              () => {
                                const newBoards = audioboards.filter(
                                  (newBoard) =>
                                    newBoard.AudioBoardID !== board.AudioBoardID
                                );
                                setAudioBoards(newBoards);
                              }
                            );
                          }}
                        >
                          <s.DeleteIconn />
                        </s.IconButtonn>
                      )}
                    </s.AudioBoardInfo>
                  </s.AudioBoard>
                );
              })
            ) : (
              <s.NoAudioBoards>{message}</s.NoAudioBoards>
            )}
          </s.AudioBoards>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <FocusedAudioBoard
            audioboard={audioboard}
            pageUserId={pageUserId}
            close={() => {
              setValue(0);
            }}
          />
        </TabPanel>

        <s.Dialogg open={open} onClose={() => setOpen(false)}>
          <s.DialogTitlee disableTypography={true}>
            Create a New Audio Board
          </s.DialogTitlee>
          <s.DialogContentt>
            <s.FormLabel>Title</s.FormLabel>
            <s.FormTextInput
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              placeholder="Give your Audio Snippet Board a title like “Startup Stories”..."
            ></s.FormTextInput>
            <s.FormLabel>Add Description</s.FormLabel>
            <s.FormTextInput
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              placeholder="Give your Audio Snippet Board a brief description..."
            ></s.FormTextInput>
            <s.FormLabel>Add a Cover Image</s.FormLabel>
            <s.ColorPicker
              color={color}
              onChange={(color) => setColor(color)}
            />
            <s.PickedColorContainer>
              <s.PickedColor style={{ backgroundColor: color }}></s.PickedColor>
              <s.PickedColorText>{color}</s.PickedColorText>
            </s.PickedColorContainer>
          </s.DialogContentt>

          <s.DialogActionss>
            <s.SaveButton
              onClick={() => {
                setIsDisabled(true);
                addAudioBoard({
                  userID: user.id,
                  image: "",
                  title,
                  description,
                  thumbnailColor: color,
                }).then(() => {
                  setTitle("");
                  setDescription("");
                  getAudioBoards(user.id).then((response) => {
                    setAudioBoards(response);
                    setOpen(false);
                  });
                });
              }}
              disabled={isDisabled}
            >
              Save
            </s.SaveButton>
          </s.DialogActionss>
        </s.Dialogg>
      </s.CenterContainer>
    </s.Container>
  );
}
