import axios from "axios";
import { API } from "../constants";

/**
 * Allows someone to login
 *
 * @param userName
 * @param passWord
 */
const signIn = async (userName, passWord) => {
  return axios
    .post(API + "/api/users/signin", {
      Body: {
        username: userName,
        password: passWord,
      },
    })
    .then((response) => {
      window.localStorage.setItem("token", response.data.Token);
      // console.log(window.localStorage.getItem("token"));
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default signIn;
