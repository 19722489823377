import React, { useState, useEffect } from "react";
import * as s from "./StyledComments";
import getEpisodeComment from "../../../../../api/comments/getEpisodeComment";
import AddComment from "../AddComment/AddComment";
import CommentList from "../CommentList/CommentList";
import { useStoreState } from "react-context-hook";

const Comments = ({ episodeID }) => {
  const { loggedIn } = useStoreState();

  const [comments, setComments] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [added, setAdded] = useState(false);

  useEffect(() => {
    if (!loaded || added) {
      const getComments = async () => {
        const data = await getEpisodeComment(episodeID);
        setComments(data);
        console.log("comment data", data);
      };

      getComments();
      setLoaded(true);
      setAdded(false);
    }
  }, [loaded, added, episodeID]);

  return (
    <s.Container>
      <s.LeftContainer>
        <s.CommentHeader>Comments</s.CommentHeader>
        {loggedIn ? (
          <AddComment addComment={() => setAdded(true)} episodeID={episodeID} />
        ) : (
          <s.LoggetOutMessage>
            Sign up <s.LoggedOutLink to="/signup">here</s.LoggedOutLink> to post
            a comment!
          </s.LoggetOutMessage>
        )}
        <CommentList comments={comments} />
      </s.LeftContainer>
    </s.Container>
  );
};

export default Comments;
