import axios from "axios";
import { API } from "../constants";
/**
 * Adds a new snippet object to the snippet database and attaches a snippet id to the creator.
 *
 * @param episode - episode object
 * @param token - token
 */
const addSnippet = async (data, token) => {
  return axios
    .post(API + "/api/episode/snippet/add", {
      body: {
        podcastName: data.podcastName,
        episodeURL: data.episodeURL,
        episodeName: data.episodeName,
        episodeID: data.episodeID,
        podcastID: data.podcastID,
        userID: data.userID,
        username: data.username,
        snippetTitle: data.snippetTitle,
        snippetDescription: data.snippetDescription,
        startTime: data.startTime,
        endTime: data.endTime,
      },
      token: token,
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default addSnippet;
