import React, { useState, useEffect } from "react";
import * as s from "./StyledAddToAudioboard";
import { useStoreState } from "react-context-hook";
import getAudioBoards from "../../../../../api/audioboards/getAudioBoards";
import AudioBoardCheckbox from "./AudioboardCheckbox/AudioboardCheckbox";

export default function AddToAudioboard(props) {
  const { user } = useStoreState();
  const [audioboards, setAudioBoards] = useState([]);
  const [audioboardsLoaded, setAudioBoardsLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    getAudioBoards(user.id).then((response) => {
      setAudioBoards(response);
      setAudioBoardsLoaded(true);
    });
    // eslint-disable-next-line
  }, [open]);

  return (
    <s.Container>
      <s.AddContainer onClick={handleClick}>
        <s.IconButtonn size="small">
          <s.PlaylistAddIconn />
        </s.IconButtonn>
        <s.AddText>Add to Board</s.AddText>
      </s.AddContainer>

      <s.Popoverr
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {audioboardsLoaded &&
          (audioboards.length > 0 ? (
            <s.Checkboxes>
              {audioboards.map((audioboard, index) => {
                return (
                  <AudioBoardCheckbox
                    key={index}
                    audioboard={audioboard}
                    snippetId={props.snippetID}
                  />
                );
              })}
            </s.Checkboxes>
          ) : (
            <s.InfoHeader>No audioboards found.</s.InfoHeader>
          ))}
      </s.Popoverr>
    </s.Container>
  );
}
