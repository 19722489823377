import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Page_Content = styled.div`
  top: 50px;
  position: absolute;
  width: 80vw;

  @media (min-width: 992px) {
    width: calc(100vw - 15.55555555vw);
    top: 5.555555555vh;
    left: 11.11111111vw;
    margin-left: 2.222222222vw;
  }
`;
