import React, { useState, useEffect } from "react";
import * as s from "./StyledLikes";
import EpisodeItem from "../../../shared_components/EpisodeItem/EpisodeItem";
import { useGetAndSet } from "react-context-hook";
import getUserByToken from "../../../../api/user/getUserByToken";

const Likes = (props) => {
  // gets recently listened array from global state
  const [user, setUser] = useGetAndSet("user");
  const [undefinedCount, setUndefinedCount] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getUserByToken(setUser).then(setLoaded(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // function to render each recently played episode item
  const renderItems = () => {
    const items = user.likes.map((item, i) => {
      return (
        <EpisodeItem
          key={i}
          episodeID={item}
          incrementUndefined={() => setUndefinedCount(undefinedCount + 1)}
        />
      );
    });
    if (undefinedCount === items.length) {
      return (
        <s.NoLikesMessage>
          No liked episodes! Listen to podcast episodes{" "}
          <s.NoLikesLink to="/explore">here!</s.NoLikesLink>
        </s.NoLikesMessage>
      );
    } else {
      return items;
    }
  };
  if (!loaded) {
    return <s.LikesContainer>{}</s.LikesContainer>;
  }
  return <s.LikesContainer>{renderItems()}</s.LikesContainer>;
};

export default Likes;
