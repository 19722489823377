import React from "react";

import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

export const Checkboxx = withStyles({
  root: {
    "&$checked": {
      color: "#2f80ed",
    },
    "@media (min-width: 992px)": {
      "&& .MuiSvgIcon-root": { fontSize: "1.625rem" },
    },
    "@media (min-width: 1920px)": {
      "&& .MuiSvgIcon-root": { fontSize: "1.75rem" },
      padding: 12,
    },

    "@media (min-width: 2560px)": {
      "&& .MuiSvgIcon-root": { fontSize: "2rem" },
      padding: 14,
    },
    "@media (min-width: 3840px)": {
      "&& .MuiSvgIcon-root": { fontSize: "3rem" },
      padding: 18,
    },
  },

  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const Container = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 2560px) {
  }
  @media (min-width: 3840px) {
    margin-bottom: 0.5rem;
  }
`;

export const CheckboxText = styled.h5`
  color: #4f4f4f;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  margin-top: -0.5px;
  cursor: pointer;
  padding-right: 9px;
  overflow: auto;
  max-height: 60px;
  @media (min-width: 992px) {
  }
  @media (min-width: 1920px) {
    padding-right: 12px;
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    padding-right: 14px;
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    padding-right: 18px;
    font-size: 2.333333333333rem;
  }
`;
