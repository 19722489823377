import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as s from "./StyledPodcastRecItem";
import ImageNotFound from "../../../../../assets/image_not_found.png";
import { useMediaQuery } from "react-responsive";

const PodcastRecItem = ({ data }) => {
  const [error, setError] = useState(false);
  const cleanTitle = (data) => {
    return data.Title.includes("#") ? data.Title.replace("#", "") : data.Title;
  };

  const twoItems = useMediaQuery({ minWidth: 0, maxWidth: 575 });
  const moreItems = useMediaQuery({ minWidth: 576 });
  return (
    <s.ItemContainer>
      {twoItems && (
        <>
          <div>
            <s.ItemImage>
              <Link
                to={{
                  pathname: "/podcasts/" + data.ID + "/" + cleanTitle(data),
                }}
              >
                <s.ItemImageImg
                  alt={data.Title}
                  src={error ? ImageNotFound : data.Img}
                  onError={(e) => {
                    setError(true);
                  }}
                />
              </Link>

              <Link to={`/profile/${data.User.Username}`}>
                <s.UserImage
                  style={{
                    border: "2px solid white",
                    width: "34px",
                    height: "34px",
                  }}
                  src={data.User.Img}
                />
              </Link>
            </s.ItemImage>
          </div>
          <s.ItemDetail
            style={{
              marginTop: "1.777777777vh",
              color: "#262626",
            }}
          >
            {data.Title}
          </s.ItemDetail>
          <s.ItemDetail
            style={{
              marginTop: "0.0888888888vh",
              color: "#828282",
            }}
          >
            {data.Category}
          </s.ItemDetail>
          <s.MobileUserText>"{data.User.Text}"</s.MobileUserText>
        </>
      )}
      {moreItems && (
        <>
          {" "}
          <s.ItemImage>
            <Link
              to={{
                pathname: "/podcasts/" + data.ID + "/" + cleanTitle(data),
              }}
            >
              <s.ItemImageImg
                alt={data.Title}
                src={error ? ImageNotFound : data.Img}
                onError={(e) => {
                  setError(true);
                }}
              />
            </Link>
          </s.ItemImage>
          <s.ItemDetail
            style={{
              marginTop: "1.777777777vh",
              color: "#262626",
            }}
          >
            {data.Title}
          </s.ItemDetail>
          <s.ItemDetail
            style={{
              marginTop: "0.0888888888vh",
              color: "#828282",
            }}
          >
            {data.Category}
          </s.ItemDetail>
          <s.RowContainer>
            <Link to={`/profile/${data.User.Username}`}>
              <s.UserImage src={data.User.Img} />
            </Link>

            <s.UserText>"{data.User.Text}"</s.UserText>
          </s.RowContainer>
        </>
      )}
    </s.ItemContainer>
  );
};

export default PodcastRecItem;
