import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

export const AllPodcasts = styled.div`
  margin-bottom: 140px;
  @media (min-width: 2560px) {
    margin-bottom: 160px;
  }
`;

export const LoadingContainer = styled.div`
  display: ${(props) => (props.loading ? "block" : "none")};
`;

export const LoadedContainer = styled.div`
  display: ${(props) => (props.loading ? "none" : "block")};
`;

export const PodcastHeader = styled.div`
  color: #333333;
  font-size: 1.5rem;
  font-family: Proxima Nova Lt;
  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const PodcastHeaderContainer = styled.div`
  display: flex;
  margin-top: 2.4vh;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
  }
`;

/**
 * Material UI styles
 */

export const StyledMenu = withStyles({
  list: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  paper: {
    border: "1px solid #EDEDED",
    marginTop: "0px",
    paddingTop: 0,
    minWidth: "9.097222222vw",
    borderRadius: "5px",
    boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.05)",
    height: "50vh",
    "@media (min-width: 1920px)": {
      borderRadius: "6px",
    },
    "@media (min-width: 2560px)": {
      border: "2px solid #EDEDED",
      borderRadius: "8px",
    },
    "@media (min-width: 3840px)": {
      borderRadius: "10px",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "#393939",
    fontSize: "0.875rem",
    fontFamily: "Proxima Nova Lt",
    lineHeight: 1.25,
    paddingLeft: 12,
    paddingRight: 12,
    "&:focus": {
      backgroundColor: "#E0E0E0",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666666rem",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.66666666667rem",
    },
  },
}))(MenuItem);

export const MenuButton = withStyles((theme) => ({
  root: {
    color: "white",
    "&:hover": {
      backgroundColor: "#007ac9",
    },
    backgroundColor: "#2F80ED",
    textTransform: "none",
    borderRadius: "5px",
    width: "9.097222222vw",
    minWidth: "132px",
    height: "3vh",
    minHeight: "24px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "4px",
    marginBottom: "8px",
    fontWeight: "bold",
    "@media (min-width: 992px)": {
      marginLeft: "1.944444444vw",
      marginTop: 0,
      marginBottom: 0,
    },
    "&&": {
      boxShadow: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.1666666rem",
      borderRadius: "6px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.5555555rem",
      borderRadius: "8px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.3333333rem",
      borderRadius: "10px",
    },
  },
}))(Button);

export const VertCenterLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
`;

export const CenterLoading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LoadingGif = styled.img`
  height: 75px;
  width: 75px;
`;

export const InfoText = styled.p`
  color: #828282;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  font-family: "Proxima Nova Lt";
  margin-bottom: 1rem;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-bottom: 1.33333333333rem;
    margin-top: 0.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-bottom: 1.77777777778rem;
    margin-top: 0.44444444444rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333333rem;
    margin-bottom: 2.66666666667rem;
    margin-top: 0.66666666666rem;
  }
`;
