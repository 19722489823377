import React from "react";
import * as s from "./StyledSideMenu";
import ListenActive from "../../../assets/listen active.svg";
import ListenNonActive from "../../../assets/listen not active.svg";
import LibraryActive from "../../../assets/library_active.svg";
import LibraryNonActive from "../../../assets/library_not_active.svg";
import CommunityActive from "../../../assets/community_active.svg";
import CommunityNonActive from "../../../assets/community_not_active.svg";

export default function SideMenu({ selected }) {
  const isExplore = selected === "explore";
  const isLibrary = selected === "library";
  const isCommunity = selected === "community";

  return (
    <s.Container>
      <s.Tab $active={isExplore} to="/explore">
        <s.ListenIcon>
          <s.Listen src={isExplore ? ListenActive : ListenNonActive} />
        </s.ListenIcon>
        <s.TabText $active={isExplore}>Listen</s.TabText>
      </s.Tab>
      <s.Tab $active={isLibrary} to="/library">
        <s.LibraryIcon>
          <s.Library src={isLibrary ? LibraryActive : LibraryNonActive} />
        </s.LibraryIcon>
        <s.TabText $active={isLibrary}>Library</s.TabText>
      </s.Tab>
      <s.Tab $active={isCommunity} to="/community">
        <s.CommunityIcon>
          <s.Community
            src={isCommunity ? CommunityActive : CommunityNonActive}
          />
        </s.CommunityIcon>
        <s.TabText $active={isCommunity}>Community</s.TabText>
      </s.Tab>
      <s.FeedbackContainer>
        <s.Feedback
          href="https://castyr.rapidr.io/contribute/castyr-beta/"
          target="_blank"
        >
          Send Feedback
        </s.Feedback>
        <s.Feedback
          href="https://airtable.com/shrd6LVo7WGn5vAac"
          target="_blank"
        >
          Request a Podcast
        </s.Feedback>
        <s.Feedback
          href="https://airtable.com/shrG6q5ejrYQXQIc5"
          target="_blank"
        >
          Claim a Podcast
        </s.Feedback>
      </s.FeedbackContainer>
    </s.Container>
  );
}
