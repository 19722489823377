import axios from "axios";
import { API } from "../constants";

/**
 * Adding listening history for a user
 *
 * @param userID: the userID
 */
const getHistory = async (userID) => {
  let api = API + "/api/history?UserID=" + userID;
  return axios.get(api).then((data) => {
    return data.data;
  });
};

export default getHistory;
