import React, { useState } from "react";
import * as s from "./StyledAudioboardCheckbox";
import { useStoreState } from "react-context-hook";
import addSnippetToAudioBoard from "../../../../../../api/audioboards/addSnippetToAudioBoard";
import removeSnippetFromAudioBoard from "../../../../../../api/audioboards/removeSnippetFromAudioBoard";

export default function AudioBoardCheckbox(props) {
  const audioboard = props.audioboard;
  const snippetId = props.snippetId;
  const { user } = useStoreState();
  const snippetInAudioboard = audioboard.SnippetIDs.includes(snippetId);
  const [checked, setChecked] = useState(snippetInAudioboard);
  const handleChange = () => {
    if (checked) {
      setChecked(false);
      removeSnippetFromAudioBoard({
        userID: user.id,
        audioboardID: audioboard.AudioBoardID,
        snippetID: snippetId,
      });
    } else {
      setChecked(true);
      addSnippetToAudioBoard({
        userID: user.id,
        audioboardID: audioboard.AudioBoardID,
        snippetID: snippetId,
      });
    }
  };

  return (
    <s.Container>
      <s.Checkboxx checked={checked} onChange={handleChange}></s.Checkboxx>
      <s.CheckboxText onClick={handleChange}>{audioboard.Title}</s.CheckboxText>
    </s.Container>
  );
}
