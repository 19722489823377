import styled from "styled-components";
import { Link } from "react-router-dom";

export const Comment = styled.div`
  display: flex;
  margin-left: 9%;
  margin-right: 7%;
  margin-bottom: 0.875rem;
  @media (min-width: 1920px) {
    margin-bottom: 1.5rem;
  }
  @media (min-width: 2560px) {
    margin-bottom: 1.75rem;
  }
  @media (min-width: 3840px) {
    margin-bottom: 2.5rem;
  }
`;

export const CommentAuthorPicLink = styled(Link)``;

export const CommentAuthorPic = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 50%;
  @media (min-width: 1920px) {
    width: 42px;
    height: 42px;
    margin-right: 18px;
  }
  @media (min-width: 2560px) {
    width: 56px;
    height: 56px;
    margin-right: 22px;
  }
  @media (min-width: 3840px) {
    width: 84px;
    height: 84px;
    margin-right: 34px;
  }
`;

export const CommentTexts = styled.div``;

export const CommentsAuthor = styled(Link)`
  margin: 0;
  color: #4f4f4f;
  font-family: Proxima Nova Lt;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  margin-top: 0.125rem;
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-bottom: 0.3rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-bottom: 0.35rem;
    margin-top: 0.16666666666rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
    margin-bottom: 0.55rem;
    margin-top: 0.22222222222rem;
  }
`;

export const CommentText = styled.p`
  font-family: Proxima Nova;
  font-weight: 300;
  margin: 0;
  color: #828282;
  font-size: 0.875rem;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;
