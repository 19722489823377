import React, { useRef } from "react";
import PlaybarDefault from "./components/PlaybarDefault";
import PlaybarCreateSnippet from "./components/PlaybarCreateSnippet";
import AudioPlayer from "./components/AudioPlayer";
import { AudioPlayerContext } from "./components/AudioPlayerContext";
import * as s from "./StyledPlaybar";
import { useLocation } from "react-router-dom";

export default function Playbar(props) {
  const [openSnippetInfo, setOpenSnippetInfo] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [response, setResponse] = React.useState({});

  // Audio player states
  const [controls, setControls] = React.useState(false);
  const [volume, setVolume] = React.useState(1);
  const [muted, setMuted] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [playbackRate, setPlaybackRate] = React.useState(1);
  const [progressInterval, setProgressInterval] = React.useState(1000);
  const [loop, setLoop] = React.useState(false);
  const [seeking, setSeeking] = React.useState(false);
  const [seconds, setSeconds] = React.useState(0);
  const [isCreateSnippetState, setIsCreateSnippetState] = React.useState(false);
  const [snippetStart, setSnippetStart] = React.useState(0);
  const player = useRef();
  const playerSeekTo = (number) => {
    if (player.current === undefined) {
      return;
    }
    player.current.seekTo(number);
  };

  const maxSnippetTime = 300;

  const location = useLocation();
  const shouldHide =
    location.pathname === "/" ||
    location.pathname === "/team" ||
    location.pathname === "/login" ||
    location.pathname === "/signup";
  const handleSnippetInfoOpen = (resp) => {
    if (resp.added) {
      setOpenSnippetInfo(true);
      setResponse(resp);
    }
  };
  const handleSnippetInfoClose = () => {
    setOpenSnippetInfo(false);
  };
  const handleWarning = () => {
    setOpenWarning(true);
  };
  const handleWarningClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenWarning(false);
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        controls,
        setControls,
        volume,
        setVolume,
        muted,
        setMuted,
        duration,
        setDuration,
        playbackRate,
        setPlaybackRate,
        progressInterval,
        setProgressInterval,
        loop,
        setLoop,
        seeking,
        setSeeking,
        seconds,
        setSeconds,
        isCreateSnippetState,
        setIsCreateSnippetState,
        snippetStart,
        setSnippetStart,
        playerSeekTo,
        player,
      }}
    >
      <s.PlaybarContainer>
        <div style={shouldHide ? { display: "none" } : {}}>
          {isCreateSnippetState ? (
            <PlaybarCreateSnippet
              {...props}
              handleSnippetInfoOpen={handleSnippetInfoOpen}
              handleWarning={handleWarning}
              maxSnippetTime={maxSnippetTime}
            />
          ) : (
            <PlaybarDefault
              {...props}
              handleSnippetInfoOpen={handleSnippetInfoOpen}
              handleSnippetInfoClose={handleSnippetInfoClose}
              openSnippetInfo={openSnippetInfo}
              handleWarning={handleWarning}
              maxSnippetTime={maxSnippetTime}
              snippet={response}
              setPlaybackRate={setPlaybackRate}
            />
          )}
        </div>
        <s.AlertContainer
          open={openWarning}
          autoHideDuration={4000}
          onClose={handleWarningClose}
        >
          <s.Alert onClick={handleWarningClose}>
            Snippets must be longer than 0 seconds.
          </s.Alert>
        </s.AlertContainer>
        <AudioPlayer />
      </s.PlaybarContainer>
    </AudioPlayerContext.Provider>
  );
}
