import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import XIcon from "@material-ui/icons/Close";

export const CardContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const CardContent = styled.div`
  background-color: white;

  border-radius: 4px;
  width: 300px;

  @media (min-width: 576px) {
    width: 450px;
  }
  @media (min-width: 1920px) {
    width: 600px;
  }
  @media (min-width: 2560px) {
    width: 800px;
  }
  @media (min-width: 3840px) {
    width: 1200px;
  }
`;

export const CardTitleRow = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  @media (min-width: 1920px) {
    margin-top: 1.33333333333rem;
  }
  @media (min-width: 2560px) {
    margin-top: 1.77777777778rem;
  }
  @media (min-width: 3840px) {
    margin-top: 2.66666666667rem;
  }
`;

export const CardTitle = styled.h2`
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;
  text-align: center;
  color: #4f4f4f;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const FlexboxSpacing = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
`;

export const CloseButton = styled(IconButton)`
  && {
    margin-top: -1rem;
    outline: none;
  }
  @media (min-width: 1920px) {
    && {
      margin-top: -1.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      margin-top: -1.77777777778rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      margin-top: -2.66666666667rem;
    }
  }
`;

export const CloseIcon = styled(XIcon)`
  && {
    fill: #4f4f4f;
    width: 22px;
    height: 22px;
  }
  @media (min-width: 1920px) {
    && {
      width: 30px;
      height: 30px;
    }
  }
  @media (min-width: 2560px) {
    && {
      width: 40px;
      height: 40px;
    }
  }
  @media (min-width: 3840px) {
    && {
      width: 58px;
      height: 58px;
    }
  }
`;

export const CardContentRow = styled.div`
  width: 650px;
  background-color: white;
  padding: 50px;
  border-radius: 4px;
`;
export const CardInfoContainer = styled.div`
  display: flex;
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 1rem;

  @media (min-width: 1920px) {
    height: 466px;
    margin-bottom: 1.25rem;
  }
  @media (min-width: 2560px) {
    height: 622px;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 3840px) {
    height: 932px;
    margin-bottom: 2rem;
  }
`;

export const ShareLinkRow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 1.5rem;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  @media (min-width: 1920px) {
    margin-top: 1.25rem;
  }
  @media (min-width: 2560px) {
    margin-top: 1.5rem;
  }
  @media (min-width: 3840px) {
    margin-top: 2rem;
  }
`;
