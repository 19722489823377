import axios from "axios";
import { API } from "../constants";

// Secure route, requires token

const updateUser = async (user) => {
  axios
    .put(API + "/api/users/update", {
      Body: {
        bio: user.Bio,
        img: user.Img,
        name: user.Name,
        username: user.Username,
      },
      Token: window.localStorage.getItem("token"),
    })
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
};

export default updateUser;
