import React from "react";
import * as s from "./StyledSnippetInfoModal";
import Modal from "@material-ui/core/Modal";
import { useStoreState } from "react-context-hook";
import updateSnippet from "../../../../api/snippets/updateSnippet";
import AddToAudioboard from "./AddToAudioboard/AddToAudioboard";

export default function SnippetInfoModal(props) {
  const { currentImage, loggedIn } = useStoreState();
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState();
  const [timer, setTimer] = React.useState();
  const [saveStatus, setSaveStatus] = React.useState(false);
  const { user } = useStoreState();

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleFocus = (event) => event.target.select();
  const url = window.location.host + "/s/" + props.snippet.snippetID;

  const token = window.localStorage.getItem("token");

  const triggerUpdate = () => {
    const episode = {
      snippetID: props.snippet.snippetID,
      userID: user.id ? user.id.toString() : "",
      snippetTitle: title,
      snippetDescription: description,
    };
    updateSnippet(episode, token).then(() => {
      setSaveStatus(false);
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerUpdate();
    }

    setSaveStatus(true);

    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        triggerUpdate();
      }, 1500)
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      disableScrollLock={true}
    >
      <s.CardContainer>
        {" "}
        <s.CardContent>
          <s.CardTitleRow>
            <s.FlexboxSpacing />
            <s.CardTitle>Saved to Library</s.CardTitle>
            <s.FlexboxSpacing>
              <s.CloseButton onClick={props.handleClose}>
                <s.CloseIcon />
              </s.CloseButton>
            </s.FlexboxSpacing>
          </s.CardTitleRow>

          <s.CardInfoContainer>
            <s.SnippetThumbnailContainer>
              <s.SnippetThumbnail alt={"loading..."} src={currentImage} />
            </s.SnippetThumbnailContainer>

            <s.SnippetInfoContainer>
              <s.ShareLinkRow>
                <s.ShareLinkTitle>Share Link</s.ShareLinkTitle>
                <s.ShareLinkPositioning>
                  <s.LinkIcon />
                  <s.CopyButton
                    size="small"
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                    }}
                  >
                    Copy
                  </s.CopyButton>
                  <s.ShareLink
                    value={url}
                    onFocus={handleFocus}
                    onChange={() => {}}
                    type="text"
                  ></s.ShareLink>
                </s.ShareLinkPositioning>
              </s.ShareLinkRow>

              <s.InputRow>
                <s.InputRowTitle>Title</s.InputRowTitle>
                <s.TextInput
                  value={title}
                  placeholder="Give this snippet a title"
                  onChange={handleTitleChange}
                  onBlur={triggerUpdate}
                  onKeyDown={handleKeyDown}
                />
              </s.InputRow>

              <s.InputRow>
                <s.InputRowTitle>Add Description</s.InputRowTitle>
                <s.TextInput
                  value={description}
                  placeholder="Give this snippet a description"
                  onChange={handleDescriptionChange}
                  onBlur={triggerUpdate}
                  onKeyDown={handleKeyDown}
                />
              </s.InputRow>

              {loggedIn && (
                <s.InputRow>
                  <s.InputRowTitle>Add to Audio Board</s.InputRowTitle>
                  <AddToAudioboard snippetID={props.snippet.snippetID} />
                </s.InputRow>
              )}

              {saveStatus ? (
                <s.SaveStatus>Saving...</s.SaveStatus>
              ) : (
                <s.SaveStatus>
                  Saved <s.Checkmark>✓</s.Checkmark>
                </s.SaveStatus>
              )}
            </s.SnippetInfoContainer>
          </s.CardInfoContainer>
        </s.CardContent>
      </s.CardContainer>
    </Modal>
  );
}
