import React from "react";
import * as s from "./StyledTeamItem";
import Linkedinlogo from "../../../../../assets/linkedin-brands.svg";
import { Link } from "react-router-dom";

export default class TeamItem extends React.Component {
  render() {
    return (
      <s.ItemContainer>
        <s.PersonName>{this.props.name}</s.PersonName>

        <s.PersonRole>{this.props.role}</s.PersonRole>
        <s.HorizontalCenterContainer>
          <s.VerticalCenterContainer>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.linkedin}
            >
              <s.LinkedInImage src={Linkedinlogo} alt="linkedin" />
            </a>
          </s.VerticalCenterContainer>
        </s.HorizontalCenterContainer>

        <s.FavoritePodcast>Favorite Podcast</s.FavoritePodcast>

        <Link to={this.props.podURL} style={{ textDecoration: "none" }}>
          <s.PodcastTitle>{this.props.pod}</s.PodcastTitle>
        </Link>
      </s.ItemContainer>
    );
  }
}
