import React from "react";
import * as s from "./StyledFocusedAudioBoard";
import AudioBoardSnippet from "./AudioBoardSnippets/AudioBoardSnippet";

export default function AudioBoards(props) {
  const pageUserId = props.pageUserId;
  const audioboard = props.audioboard;
  const isUserViewingOwnPage = pageUserId === "";
  const snippetIDs = audioboard.SnippetIDs;
  const close = props.close;
  let message = "No snippets found on this audioboard.";
  message =
    message +
    (isUserViewingOwnPage
      ? " Add a snippet to an audioboard on the snippet's page."
      : "");
  snippetIDs.reverse();

  return (
    <s.FocusedAudioBoardContainer>
      <s.FocusedAudioBoard>
        <s.FocusedAudioBoardTitleRow>
          <s.FocusedAudioBoardTitle>
            {audioboard.Title}
          </s.FocusedAudioBoardTitle>
          <s.IconButtonn onClick={close}>
            <s.CloseIconn />
          </s.IconButtonn>
        </s.FocusedAudioBoardTitleRow>
        <s.FocusedAudioBoardDescription>
          {audioboard.Description}
        </s.FocusedAudioBoardDescription>

        {snippetIDs.length > 0 ? (
          snippetIDs.map((snippetID, index) => {
            return <AudioBoardSnippet key={index} snippetID={snippetID} />;
          })
        ) : (
          <s.NoAudioBoardSnippets>{message}</s.NoAudioBoardSnippets>
        )}
      </s.FocusedAudioBoard>
    </s.FocusedAudioBoardContainer>
  );
}
