import styled from "styled-components";
import { Link } from "react-router-dom";
import ProximaNova from "../../../assets/fonts/ProximaNova-Regular.otf";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const LoginContainer = styled.div`
  width: 84%;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1920px) {
    margin-top: 100px;
  }
  @media (min-width: 2560px) {
    margin-top: 135px;
  }
  @media (min-width: 3840px) {
    margin-top: 175px;
  }
`;

export const WelcomeText = styled.p`
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  color: #2f80ed;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
  @media (min-width: 992px) {
    font-size: 32px;
  }
  @media (min-width: 1920px) {
    font-size: 40px;
    margin-bottom: 1.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 50px;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 3840px) {
    font-size: 68px;
    margin-bottom: 2rem;
  }
`;

export const InputContainer = styled.div`
  margin-top: 8px;
  width: 250px;
  margin: auto;
  @media (min-width: 576px) {
    width: 300px;
  }
  @media (min-width: 992px) {
    width: 450px;
  }
  @media (min-width: 1768px) {
    width: 550px;
  }
  @media (min-width: 2560px) {
    width: 750px;
  }
  @media (min-width: 3840px) {
    width: 1000px;
  }
`;

export const Inputs = styled.input`
  width: 100%;
  height: 36px;
  border: 1px solid #95bddb;
  border-radius: 50px;
  font-size: 14px;
  padding-left: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #828282;
  outline: none;
  font-family: Proxima Nova;
  @media (min-width: 992px) {
    height: 42px;
    margin-bottom: 24px;
    font-size: 16px;
  }
  @media (min-width: 1768px) {
    height: 52px;
    margin-bottom: 28px;
    font-size: 20px;
  }
  @media (min-width: 2560px) {
    height: 68px;
    margin-bottom: 36px;
    font-size: 28px;
    padding-left: 36px;
  }
  @media (min-width: 3840px) {
    height: 90px;
    margin-bottom: 50px;
    font-size: 36px;
    border-width: 3px;
  }
`;

export const LoginButton = styled.button`
  background: #2F80ED;
  color: white;
  border-radius: 50px;
  border: none;
  text-direction: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 36px;
  font-weight bold;
  cursor: pointer; 
  margin: auto;
  font-size: 16px;
  box-shadow: 3px 3px 10px #00000029;
  margin-top: 8px;
  margin-bottom: 16px;
  outline: none;
  font-family: Proxima Nova;
  &:focus {
    outline: none;
  }
  @media (min-width: 992px) {
    height: 42px;
    font-size: 18px;
    width: 140px;
    margin-bottom: 18px;
  }
  @media (min-width: 1768px) {
    height: 52px;
    font-size: 22px;
    width: 160px;
    margin-bottom: 20px;
  }
  @media (min-width: 2560px) {
    height: 68px;
    font-size: 30px;
    width: 220px;
    margin-bottom: 28px;
  }
  @media (min-width: 3840px) {
    height: 90px;
    font-size: 40px;
    width: 280px;
    margin-bottom: 32px;
  }
`;

export const GoogleButton = styled.button`
  background: white;
  border-radius: 50px;
  color: #2F80ED;
  width: 110px;
  height: 36px;
  font-size: 16px;
  font-weight bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  cursor: pointer; 
  box-shadow: 3px 3px 10px #00000029;
  margin: auto;
  border: 1px solid #2F80ED;
  outline: none;
  font-family: Proxima Nova;
  &:focus {
    outline: none;
  }
  @media (min-width: 992px) {
    height: 42px;
    font-size: 18px;
    width: 140px;
  }
  @media (min-width: 1768px) {
    height: 52px;
    font-size: 22px;
    width: 160px;
  }
  @media (min-width: 2560px) {
    height: 68px;
    font-size: 30px;
    width: 220px;
  }
  @media (min-width: 3840px) {
    border-width: 3px;
    height: 90px;
    font-size: 40px;
    width: 280px;
  }
`;

export const GoogleIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const BottomText = styled.div`
  margin-top: 20px;
  color: #2f80ed;
  font: normal 16px Proxima Nova;
  text-align: center;
  @media (min-width: 992px) {
    font-size: 18px;
  }
  @media (min-width: 1768px) {
    font-size: 20px;
  }
  @media (min-width: 2560px) {
    font-size: 28px;
    margin-top: 32px;
  }
  @media (min-width: 3840px) {
    font-size: 38px;
    margin-top: 36px;
  }
`;

export const StyledLink = styled(Link)`
  color: #2f80ed;
  font: bold;
  text-decoration: underline;
`;

export const Error = styled.div`
  color: red;
  font: normal 16px Proxima Nova;
  text-align: center;
  margin-bottom: 8px;
  @media (min-width: 992px) {
    font-size: 18px;
  }
  @media (min-width: 1768px) {
    font-size: 20px;
    margin-bottom: 12px;
  }
  @media (min-width: 2560px) {
    font-size: 28px;
    margin-bottom: 24px;
  }
  @media (min-width: 3840px) {
    font-size: 38px;
    margin-bottom: 32px;
  }
`;
