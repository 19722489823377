import styled from "styled-components";
import ProximaNova from "../../../../../assets/fonts/ProximaNova-Regular.otf";

export const ItemContainer = styled.div`
  height: 140px;
  width: 140px;
  background: #ffffff;
  border: 2px solid #0071e2;
  box-sizing: border-box;
  border-radius: 10px;

  @media (min-width: 576px) {
    height: 200px;
    width: 200px;
  }
`;

export const LinkedInImage = styled.img`
  height: 25px;
  width: 25px;
`;

export const PersonName = styled.div`
  display: flex;
  justify-content: center;
  font: Bold 16px Proxima Nova;
  color: #2f80ed;
  margin-top: 15px;
  @media (min-width: 576px) {
    font: Bold 18px Proxima Nova;
  }
`;

export const PersonRole = styled.div`
  display: flex;
  justify-content: center;
  font: Bold 12px Proxima Nova;
  color: #95bddb;
  text-align: center;
  @media (min-width: 576px) {
    margin-top: 10px;
    font: Bold 14px Proxima Nova;
  }
`;

export const FavoritePodcast = styled.div`
  display: flex;
  justify-content: center;
  font: Bold 12px Proxima Nova;
  color: #95bddb;
  @media (min-width: 576px) {
    margin-top: 10px;
    font: Bold 14px Proxima Nova;
  }
`;

export const PodcastTitle = styled.div`
  display: flex;
  justify-content: center;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #828282;
  text-decoration: underline;

  @media (min-width: 576px) {
    font-size: 14px;
  }
`;

export const HorizontalCenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (min-width: 576px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
`;

export const VerticalCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
