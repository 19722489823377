import axios from "axios";
import { API } from "../constants";

/**
 * deleteAudioboard: function that makes a PUT request to delete an audioboard
 *
 * @param {*} id - audioboard id to delete
 * @param {*} token
 */
const deleteAudioboard = async (id, userID) => {
  axios
    .post(API + "/api/audioboard/delete", {
      body: {
        audioboardID: id,
        userID: userID
      },
      token: window.localStorage.getItem("token"),
    })
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
};

export default deleteAudioboard;
