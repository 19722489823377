import React from "react";
import * as s from "./StyledSignupLoginPrompt";

export default function SignupLoginPrompt(props) {
  return (
    <s.Prompt>
      To view your profile{" "}
      <s.SignupLink to="/signup">create an account first.</s.SignupLink>
    </s.Prompt>
  );
}
