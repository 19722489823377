import styled from "styled-components";
import ProximaNova from "../../../../../assets/fonts/ProximaNova-Regular.otf";

export const HeaderContainer = styled.div`
  margin-bottom: 10vw;
  @media (min-width: 3840px) {
    margin-bottom: 17.5vw;
  }
`;

export const HeaderText = styled.div`
  margin-top: 10vh;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #2f80ed;

  margin-left: 8vw;
  @media (min-width: 576px) {
    margin-left: 12vw;
  }
`;

export const HeaderContent = styled.div`
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  width: 84vw;

  margin-left: 8vw;

  @media (min-width: 576px) {
    margin-left: 12vw;
  }
`;

export const TeamContainer = styled.div`
  display: grid;
  grid-template-columns: 50vw 50vw;
  grid-row-gap: 5vh;
  justify-items: center;
  margin-top: 5vh;
  margin-bottom: 5vh;

  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fit, 40%);
    grid-gap: 3.111111111vh;
    justify-items: baseline;
    margin-top: 10vh;
    margin-left: 12vw;
    width: 84vw;
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, 17%);
    grid-column-gap: 10vh;
  }
  @media (min-width: 1600px) {
    grid-template-columns: repeat(auto-fit, 20%);
  }
`;
