import React from "react";
import * as s from "./StyledSnippetNotFound";

export default function SnippetNotFound() {
  return (
    <s.Prompt>
      Snippet not found
    </s.Prompt>
  );
}
