import axios from "axios";
import { API } from "../constants";
/**
 * User unsubscribes to podcast on the databse
 *
 * @param podcastID - podcast ID
 * @param userID - user ID
 */
const unsubscribePodcast = async (podcastID, userID) => {
  return axios
    .post(API + "/api/podcast/unsubscribe", {
      body: {
        podcastID: podcastID,
        userID: userID,
      },
      token: window.localStorage.getItem("token"),
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default unsubscribePodcast;
