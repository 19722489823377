import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ItemContainer = styled.div``;

export const ItemImage = styled.div``;

export const ItemDetail = styled.h3`
  text-align: start;
  font-size: 0.875rem;
  font-family: "Proxima Nova Lt";
  max-width: 25vw;
  @media (min-width: 576px) {
    max-width: 20vw;
  }
  @media (min-width: 992px) {
    max-width: 13.888888888vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666666rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.66666666667rem;
  }
`;

export const ItemImageImg = styled(LazyLoadImage)`
  border-radius: 10px;
  height: 25vw;
  width: 25vw;
  @media (min-width: 576px) {
    height: 20vw;
    width: 20vw;
  }
  @media (min-width: 992px) {
    height: 13.888888888vw;
    width: 13.888888888vw;
  }
`;
