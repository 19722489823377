import React from "react";
import * as s from "./StyledFollowInfoModal";
import Modal from "@material-ui/core/Modal";
import FollowInfoItem from "./FollowInfoItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: "1000 !important",
  },
}));

export default function FollowInfoModal(props) {
  const classes = useStyles();
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      BackdropProps={{ className: classes.backdrop }}
      keepMounted={true}
    >
      <s.CardContainer>
        <s.CardContent style={{ zIndex: 1005, position: "relative" }}>
          <s.CardTitleRow>
            <s.FlexboxSpacing />
            <s.CardTitle>{props.type}</s.CardTitle>
            <s.FlexboxSpacing>
              <s.CloseButton onClick={props.handleClose}>
                <s.CloseIcon />
              </s.CloseButton>
            </s.FlexboxSpacing>
          </s.CardTitleRow>

          <s.CardInfoContainer>
            <s.ColumnContainer>
              {props.data &&
                props.data.map((item) => {
                  return (
                    <FollowInfoItem
                      profileID={item}
                      following={props.following}
                      handleFollow={props.handleFollow}
                      handleUnfollow={props.handleUnfollow}
                      closeModal={props.closeModal}
                      key={item}
                    />
                  );
                })}
            </s.ColumnContainer>
          </s.CardInfoContainer>
        </s.CardContent>
      </s.CardContainer>
    </Modal>
  );
}
