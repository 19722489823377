import React, { useState } from "react";
import * as s from "./StyledAddComment";
import { useStoreState } from "react-context-hook";
import addEpisodeComment from "../../../../../api/comments/addEpisodeComment";

const AddComment = ({ episodeID, addComment }) => {
  const { user } = useStoreState();
  const [text, setText] = useState("");

  const handlePost = () => {
    if (text.trim() !== "") {
      addEpisodeComment(episodeID, user.id, text.trim(), user.username).then(
        (response) => {
          if (response.added) {
            setText("");
            addComment();
          }
        }
      );
    }
  };

  return (
    <s.VerticalContainer>
      <s.RowContainer>
        <s.HorizontalCommentAuthorInfo>
          <s.CenteredRowContainer>
            <s.ProfileImage src={user.image} />
          </s.CenteredRowContainer>

          <s.HorizontalContainer>
            <s.CommentAuthor>
              <s.UsernameText>
                {"@"}
                {user.username}
              </s.UsernameText>
            </s.CommentAuthor>
          </s.HorizontalContainer>
        </s.HorizontalCommentAuthorInfo>

        <s.CommentInput
          placeholder="Add a comment..."
          value={text}
          onInput={(e) => {
            if (e.target.value.length > 300) {
              alert("Please limit your character count to 300");
            } else {
              setText(e.target.value);
            }
          }}
          type="text"
          maxlength="300"
        />
      </s.RowContainer>

      <s.EndContainer>
        <s.PostButton onClick={() => handlePost()}>POST</s.PostButton>
      </s.EndContainer>
    </s.VerticalContainer>
  );
};

export default AddComment;
