import React, { useState, useEffect } from "react";
import PageContainer from "../../shared_components/PageContainer/PageContainer";
import EpisodeHeader from "./components/EpisodeHeader/EpisodeHeader";
import getEpisode from "../../../api/podcasts/getEpisode";
import getPodcast from "../../../api/podcasts/getPodcast";
// import DisqusComponent from "./components/DisqusComponent";

export default function EpisodePage(props) {
  // const page = props.page;
  const pid = props.pid;
  const eid = props.eid;

  const [podcast, setPodcast] = useState({});
  const [episode, setEpisode] = useState({});

  /**
   * Gets the episode and podcast load it into local state
   */
  useEffect(() => {
    if (episode.Name === undefined || podcast.Title === undefined) {
      setEpisode({ Name: "loading..." });
      setPodcast({ Title: "loading..." });
      getEpisode(eid).then((episode) => {
        setEpisode(episode);
      });
      getPodcast(pid).then((podcast) => {
        setPodcast(podcast.Podcast);
      });
    }
  }, [eid, episode.Name, pid, podcast.Title]);

  return (
    <div>
      <PageContainer active={""}>
        <EpisodeHeader podcast={podcast} episode={episode} eid={eid} />
        {/* <DisqusComponent disqusShortname={disqusShortname} disqusConfig={disqusConfig} /> */}
      </PageContainer>
    </div>
  );
}
