import React from "react";
import PageContainer from "../../shared_components/PageContainer/PageContainer";
import PodcastContainer from "./components/PodcastContainer/PodcastContainer";

const ExplorePage = (props) => {
  return (
    <div>
      <PageContainer app={true} active={"explore"}>
        <PodcastContainer data={[]} />
      </PageContainer>
    </div>
  );
};

export default ExplorePage;
