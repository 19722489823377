import styled from "styled-components";
import { Link } from "react-router-dom";
import ProximaNova from "../../../../../../../assets/fonts/ProximaNova-Regular.otf";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { default as Linkk } from "@material-ui/icons/Link";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";

export const SnippetContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  @media (min-width: 1920px) {
    margin-bottom: 38px;
  }
  @media (min-width: 2560px) {
    margin-bottom: 48px;
  }
  @media (min-width: 3840px) {
    margin-bottom: 64px;
  }
`;

export const ThumbnailContainer = styled.div``;

export const Thumbnail = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 4px;
  position: absolute;
  @media (min-width: 992px) {
    width: 120px;
    height: 120px;
    border-radius: 5px;
  }
  @media (min-width: 1920px) {
    width: 150px;
    height: 150px;
    border-radius: 7px;
  }
  @media (min-width: 2560px) {
    width: 180px;
    height: 180px;
    border-radius: 7px;
  }
  @media (min-width: 3840px) {
    width: 270px;
    height: 270px;
    border-radius: 12px;
  }
`;

export const ThumbnailPlayContainer = styled(Link)`
  display: block;
  position: relative;
  width: 90px;
  height: 90px;
  @media (min-width: 992px) {
    width: 120px;
    height: 120px;
  }
  @media (min-width: 1920px) {
    width: 150px;
    height: 150px;
  }
  @media (min-width: 2560px) {
    width: 180px;
    height: 180px;
  }
  @media (min-width: 3840px) {
    width: 270px;
    height: 270px;
  }
`;

export const PlayIcon = styled.img`
  && {
    position: absolute;
    z-index: 5;
    width: 90px;
    height: 90px;
    cursor: pointer;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
    margin-left: 2%;
  }
  @media (min-width: 992px) {
    && {
      width: 120px;
      height: 120px;
    }
  }
  @media (min-width: 1920px) {
    && {
      width: 150px;
      height: 150px;
    }
  }
  @media (min-width: 2560px) {
    && {
      width: 180px;
      height: 180px;
    }
  }
  @media (min-width: 3840px) {
    && {
      width: 270px;
      height: 270px;
    }
  }
`;

export const TitleContainer = styled.div`
  line-height: 1.2;
  @media (min-width: 992px) {
    line-height: 1.5;
  }
`;
export const Info = styled.div`
  margin-left: 24px;
  max-height: 90px;
  height: 90px;
  overflow: hidden;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 992px) {
    max-height: 120px;
    height: 120px;
  }
  @media (min-width: 1440px) {
    width: 53%;
  }
  @media (min-width: 1920px) {
    margin-left: 32px;
    max-height: 150px;
    height: 150px;
  }
  @media (min-width: 2560px) {
    max-height: 180px;
    height: 180px;
    margin-left: 36px;
  }
  @media (min-width: 3840px) {
    max-height: 270px;
    height: 270px;
    margin-left: 64px;
  }
`;

export const TitleLink = styled(Link)`
  display: inline-block;
  width: 100%;
`;

export const Title = styled.h3`
  line-height: 1.2;
  margin: 0;
  text-decoration: none;
  color: #4f4f4f;
  font-size: 0.875rem;

  font-family: Proxima Nova Lt;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: 992px) {
    text-overflow: revert;
    white-space: revert;
    overflow: revert;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.75rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.625rem;
  }
`;

export const EpisodeName = styled(Link)`
  line-height: 1.2;
  display: block;
  margin: 0;
  color: #828282;
  font-size: 0.75rem;
  font-family: "Proxima Nova Lt";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  @media (min-width: 992px) {
    margin-top: 0.25rem;
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-top: 0.375rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.125rem;
    margin-top: 0.5rem;
  }
`;

export const Description = styled.p`
  margin: 0;
  color: #828282;
  font-weight: 400;
  font-size: 0.75rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  @media (min-width: 992px) {
    margin-top: 0.25rem;
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-top: 0.375rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.125rem;
    margin-top: 0.5rem;
  }
`;

export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LikeIcon = styled(FavoriteBorderIcon)`
  cursor: pointer;
  margin-right: 3px;

  && {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
      margin-right: 4px;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
      margin-right: 5px;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
      margin-right: 7px;
    }
  }
`;

export const LikedIcon = styled(FavoriteIcon)`
  cursor: pointer;
  margin-right: 3px;
  && {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
      margin-right: 4px;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
      margin-right: 5px;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
      margin-right: 7px;
    }
  }
`;

export const CommentIcon = styled(ModeCommentOutlinedIcon)`
  cursor: pointer;
  margin-right: 4px;
  && {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.33333333333rem;
      margin-right: 6px;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 1.77777777778rem;
      margin-right: 7px;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 2.66666666667rem;
      margin-right: 9px;
    }
  }
`;

export const ShareLinkPositioning = styled.div`
  position: relative;
  background: #f2f2f2;
  border-radius: 3px;
  height: min-content;
  @media (min-width: 1920px) {
  }
  @media (min-width: 2560px) {
  }
  @media (min-width: 3840px) {
  }
`;

export const ShareLinkContainer = styled.div`
  display: none;
  @media (min-width: 1440px) {
    margin-left: 48px;
    margin-right: 52px;
    display: block;
  }

  @media (min-width: 1920px) {
    margin-left: 68px;
    margin-right: 52px;
  }
  @media (min-width: 2560px) {
    margin-left: 84px;
  }
  @media (min-width: 3840px) {
    margin-left: 160px;
    margin-right: 120px;
  }
`;

export const LinkIcon = styled(Linkk)`
  position: absolute;
  transform: rotate(-40deg);
  padding: 3px;
  margin-top: 1px;
  margin-left: 4px;
  @media (min-width: 1920px) {
    padding: 1px;
    margin-top: 5px;
  }
  @media (min-width: 2560px) {
    padding: 0px;
    margin-top: 7px;
    margin-left: 9px;
    && {
      font-size: 1.7rem;
    }
  }
  @media (min-width: 3840px) {
    margin-top: 8px;
    margin-left: 14px;
    && {
      font-size: 2.5rem;
    }
  }
`;

export const ShareLink = styled.p`
  padding: 4px;
  padding-left: 0px;
  color: #717171;
  margin-left: 28px;
  outline: none;
  appearance: none;
  font-size: 0.75rem;
  border: none;
  white-space: nowrap;
  margin-bottom: 0;
  max-width: 180px;
  margin-right: 12px;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-weight: 400;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
    max-width: 236px;
    margin-left: 33px;
    margin-right: 16px;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
    margin-left: 42px;
    max-width: 296px;
    margin-right: 20px;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
    margin-left: 62px;
    max-width: 460px;
    margin-right: 32px;
  }
`;

export const ShareLinkText = styled.h4`
  margin: 0;
  color: #828282;
  font-size: 0.875rem;
  line-height: revert;
  font-family: "Proxima Nova Lt";
  margin-bottom: 0.125rem;
  margin-top: 0.25rem;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-top: 0.375rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
  }
`;

export const LikeNumber = styled.p`
  margin: 0;
  color: #333333;
  font-weight: 500;
  font-size: 0.875rem;
  width: 1rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    width: 1.375rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555555rem;
    width: 1.75rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.35rem;
    width: 2.875rem;
  }
`;

export const SignUpLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
`;

export const CommentsLink = styled(Link)`
  color: #4f4f4f;
`;
