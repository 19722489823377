import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

export const FocusedAudioBoardContainer = styled.div`
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  @media (min-width: 1920px) {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
  }
  @media (min-width: 2560px) {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
  }
  @media (min-width: 3840px) {
    box-shadow: 0px 13px 13px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }
`;

export const FocusedAudioBoard = styled.div`
  overflow-y: auto;
  padding-left: 8px;
  padding-right: 8px;
  height: 350px;
  @media (min-width: 576px) {
    padding-left: 30px;
    padding-right: 30px;
    height: 400px;
  }
  @media (min-width: 1920px) {
    height: 466px;
    padding-left: 36px;
    padding-right: 36px;
  }
  @media (min-width: 2560px) {
    height: 622px;
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (min-width: 3840px) {
    height: 920px;
    padding-left: 60px;
    padding-right: 60px;
  }
`;

export const FocusedAudioBoardTitle = styled.h3`
  color: #2f80ed;
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 1.125rem;
  margin: 0;
  margin-top: 16px;

  @media (min-width: 1920px) {
    font-size: 1.5rem;
    margin-top: 18px;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
    margin-top: 23px;
  }
  @media (min-width: 3840px) {
    font-size: 2.8rem;
    margin-top: 30px;
  }
`;

export const FocusedAudioBoardDescription = styled.h4`
  color: #a3a3a3;
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 0.875rem;
  margin-top: -0.25rem;
  margin-bottom: 1.75rem;

  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-bottom: 2.25rem;
    margin-top: 0.125rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-bottom: 2.875rem;
    margin-top: 0.25rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
    margin-bottom: 3.675rem;
    margin-top: 0.375rem;
  }
`;

export const CloseIconn = styled(CloseIcon)`
  margin-top: 4px;
  && {
    fill: #818089;
  }

  @media (min-width: 992px) {
    && {
      font-size: 1.75rem;
    }
  }
  @media (min-width: 1920px) {
    && {
      font-size: 2.33333333333rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 3.11111111111rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 4.66666666667rem;
    }
  }
`;

export const FocusedAudioBoardTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconButtonn = styled(IconButton)`
  margin-top: 2px;
  margin-right: -6px;
`;

export const NoAudioBoardSnippets = styled.h4`
  color: #828282;
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 0.875rem;
  margin-top: 28px;
  @media (min-width: 1920px) {
    font-size: 1.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.75rem;
    margin-top: -0.35rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.5rem;
    margin-top: -0.25rem;
  }
`;
