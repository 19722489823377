import React, { useState, useEffect } from "react";
import * as s from "./StyledSnippetComments";
import { useStoreState } from "react-context-hook";
import defaultProfile from "../../../../assets/DefaultProfile.svg";
import addSnippetComment from "../../../../api/snippets/addSnippetComment";
import SnippetComment from "./SnippetComment/SnippetComment";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const SnippetComments = ({
  open,
  setOpen,
  comments,
  activeSnippetID,
  activeSnippetTitle,
  loading,
  setActiveSnippetID,
  setShouldRefetchComments,
}) => {
  const { width } = useWindowDimensions();
  const [description, setDescription] = useState("");
  const { loggedIn, user } = useStoreState();
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  let rows = 3;
  if (width >= 3840) {
    rows = 7;
  } else if (width >= 2560) {
    rows = 5;
  } else if (width >= 1920) {
    rows = 4;
  }

  let commentsContainer;
  if (loggedIn) {
    commentsContainer = (
      <s.CommentsContainer>
        {comments.length > 0 ? (
          comments.map((value, index) => {
            return <SnippetComment key={index} comment={value} />;
          })
        ) : (
          <s.NoCommentsContainer>
            <s.NoComments>No comments.</s.NoComments>
          </s.NoCommentsContainer>
        )}
      </s.CommentsContainer>
    );
  } else {
    commentsContainer = (
      <s.FullHeightCommentsContainer>
        {comments.length > 0 ? (
          comments.map((value, index) => {
            return <SnippetComment key={index} comment={value} />;
          })
        ) : (
          <s.NoCommentsContainer>
            <s.NoComments>No comments.</s.NoComments>
          </s.NoCommentsContainer>
        )}
      </s.FullHeightCommentsContainer>
    );
  }

  let drawer;
  if (width >= 576) {
    drawer = (
      <s.RightDrawer variant="persistent" anchor="right" open={open}>
        <s.ThreadContainer>
          <s.ThreadText>Thread</s.ThreadText>
          <div>
            <s.IconButtonn
              size={"small"}
              onClick={() => {
                setOpen(false);
                setActiveSnippetID("");
              }}
            >
              <s.CloseIconn />
            </s.IconButtonn>
          </div>
        </s.ThreadContainer>
        <s.ThreadTitle>{activeSnippetTitle}</s.ThreadTitle>

        {!loading && commentsContainer}
        {loggedIn && (
          <s.CreateCommentContainer>
            <s.CommentButtonContainer>
              <s.CommentButton
                onClick={() => {
                  if (description.length === 0) {
                    return;
                  }
                  addSnippetComment({
                    commentText: description,
                    snippetID: activeSnippetID,
                    userID: user.id,
                  }).then(() => {
                    setShouldRefetchComments(true);
                    setDescription("");
                  });
                }}
              >
                Comment
              </s.CommentButton>
            </s.CommentButtonContainer>
            <s.CreateCommentTextContainer>
              <s.CreateCommentAuthorPic
                src={user.image ? user.image : defaultProfile}
              />
              <s.CreateCommentText
                multiline
                rows={rows}
                value={description}
                onChange={handleDescriptionChange}
                InputProps={{ disableUnderline: true }}
                placeholder={"Type your reply here..."}
                inputProps={{ maxLength: 300 }}
                variant="filled"
              ></s.CreateCommentText>
            </s.CreateCommentTextContainer>
          </s.CreateCommentContainer>
        )}
      </s.RightDrawer>
    );
  } else {
    drawer = (
      <s.BottomDrawer variant="persistent" anchor="bottom" open={open}>
        <s.ThreadContainer>
          <s.ThreadText>Thread</s.ThreadText>
          <div>
            <s.IconButtonn
              onClick={() => {
                setOpen(false);
                setActiveSnippetID("");
              }}
            >
              <s.CloseIconn />
            </s.IconButtonn>
          </div>
        </s.ThreadContainer>
        <s.ThreadTitle>{activeSnippetTitle}</s.ThreadTitle>

        {!loading && commentsContainer}

        {loggedIn && (
          <s.CreateCommentContainer>
            <s.CommentButtonContainer>
              <s.CommentButton
                onClick={() => {
                  if (description.length === 0) {
                    return;
                  }
                  addSnippetComment({
                    commentText: description,
                    snippetID: activeSnippetID,
                    userID: user.id,
                  }).then(() => {
                    setShouldRefetchComments(true);
                    setDescription("");
                  });
                }}
              >
                Comment
              </s.CommentButton>
            </s.CommentButtonContainer>
            <s.CreateCommentTextContainer>
              <s.CreateCommentAuthorPic
                src={user.image ? user.image : defaultProfile}
              />
              <s.CreateCommentText
                multiline
                rows={rows}
                value={description}
                onChange={handleDescriptionChange}
                InputProps={{ disableUnderline: true }}
                placeholder={"Type your reply here..."}
                inputProps={{ maxLength: 300 }}
                variant="filled"
              ></s.CreateCommentText>
            </s.CreateCommentTextContainer>
          </s.CreateCommentContainer>
        )}
      </s.BottomDrawer>
    );
  }

  return drawer;
};

export default SnippetComments;
