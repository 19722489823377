import axios from "axios";
import { API } from "../constants";

/**
 * Add a comment to an episode
 *
 * @param episodeID: episode ID
 * @param userID: user ID
 * @param commentText: comment text
 * @param username: username
 */
const addEpisodeComment = async (episodeID, userID, commentText, username) => {
  return axios
    .post(API + "/api/episode/addcomment", {
      body: {
        EpisodeID: episodeID,
        UserID: userID,
        CommentText: commentText,
        Username: username,
      },
      token: window.localStorage.getItem("token"),
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default addEpisodeComment;
