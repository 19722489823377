import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconContainer from "@material-ui/core/IconButton";
import More from "@material-ui/icons/MoreVert";
import MaterialMenu from "@material-ui/core/Menu";
import MaterialMenuItem from "@material-ui/core/MenuItem";
import Notifications from "./Notifications/Notifications";

export const HeaderContainer = withStyles({
  root: {
    borderBottom: "solid 2px rgba(179, 222, 249, 0.5)",
    backgroundColor: "white",
    boxShadow: "none",
    marginRight: "calc(100% - 100vw)",
    width: "100vw",
    top: 0,
    left: "auto",
    right: 0,
    position: "fixed",
  },
})(AppBar);

export const LandingHeaderContainer = withStyles({
  root: {
    boxShadow: "none",
    borderBottom: "solid 2px rgba(179, 222, 249, 0.5)",
    width: "100%",
    top: 0,
    left: "auto",
    right: 0,
    position: "absolute",
    backgroundColor: "transparent",
  },
})(AppBar);

export const Header = withStyles({
  root: {
    height: 50,
    minHeight: 50,
    justifyContent: "space-between",
    marginLeft: 12,
    marginRight: 18,
    "@media (min-width: 992px)": {
      marginLeft: 36,
      marginRight: 36,
      height: "5.555555555vh",
      minHeight: "5.555555555vh",
    },
    "@media (min-width: 2560px)": {
      marginLeft: 54,
      marginRight: 54,
    },
  },
})(Toolbar);

export const Logo = styled.img`
  height: 60%;
  cursor: pointer;
  @media (min-width: 576px) {
    height: 55%;
  }
`;

export const ProfileImage = styled.img`
  cursor: pointer;
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 6px;
  margin-left: 8px;
  object-fit: cover;
  @media (min-width: 992px) {
    margin-right: 0;
  }
  @media (min-width: 1920px) {
    width: 42px;
    height: 42px;
  }
  @media (min-width: 2560px) {
    width: 56px;
    height: 56px;
  }
  @media (min-width: 3840px) {
    width: 84px;
    height: 84px;
  }
`;

export const RightSideContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`;

export const LinkItem = styled.div`
  margin-left: 2em;
  font-size: 1.25rem;
  color: #95bddb;
  font-family: Proxima Nova;
  display: inline-block;
  cursor: pointer;

  :hover {
    text-decoration: none;
    color: #2f80ed;
    transform: translateY(-2px);
    transition-duration: 0.3s;
  }
  @media (min-width: 1920px) {
    font-size: 1.4rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.85rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const IconButton = withStyles({
  root: {
    display: "block",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 992px)": { display: "none" },
  },
})(IconContainer);

export const MoreIcon = styled(More)`
  color: #2f80ed;
`;

export const Menu = withStyles({
  paper: {
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
})(MaterialMenu);

export const MenuItem = withStyles({
  root: {
    color: "#95bddb",
    fontWeight: "bold",
    fontFamily: "Proxima Nova",

    "&:hover": {
      color: "#2F80ED",
      textDecoration: "none",
      transform: "translateY(-2px)",
      transitionDuration: "0.2s",
    },
  },
})(MaterialMenuItem);

export const MenuItdem = styled(MaterialMenuItem)`
  :hover {
    color: #2f80ed;
  }
`;

export const Notifs = styled(Notifications)`
  display: inline-block;
  width: 45px;
  height: 50px;
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
