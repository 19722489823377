import { API } from "../constants";
import axios from "axios";

/**
 * Update the listening history of a user, should be called every 30 sec
 *
 * @return list of [{PodcastID, Title, Creators, Img, Description, [Category], [EpisodeIDs]}]
 */
const getAllPodcasts = async () => {
  let api = API + "/api/podcast/all";
  return axios.get(api).then((response) => {
    return response.data;
  });

  // return [{
  //         PodcastID: 1,
  //         Title: "The Ball Boys",
  //         Creators: [1, 2],
  //         Img: "https://storage.buzzsprout.com/variants/2it4kn76nkodstlpiiwvbnnsgzpe/8d66eb17bb7d02ca4856ab443a78f2148cafbb129f58a3c81282007c6fe24ff2?.jpg",
  //         Description: "A show where two college kids share their thoughts and opinions on the NBA from the sidelines. Welcome to The Ball Boys, from Brown University.",
  //         Category: ["Sports"],
  //         EpisodeIDs: [1, 2, 3, 4]
  //     },
  //     {
  //         PodcastID: 2,
  //         Title: "BPRadio",
  //         Creators: [3, 4],
  //         Img: "http://i1.sndcdn.com/artworks-7ZDpih3vtyN1Iymd-Dv6gyg-original.jpg",
  //         Description: "A show where two college kids share their thoughts and opinions on the NBA from the sidelines. Welcome to The Ball Boys, from Brown University.",
  //         Category: ["Sports"],
  //         EpisodeIDs: [5, 6, 7, 8]
  //     },
  //     {
  //         PodcastID: 3,
  //         Title: "Unearthed",
  //         Creators: [3, 4],
  //         Img: "https://ssl-static.libsyn.com/p/assets/7/2/d/d/72dd01b1acd243b9/COVER-02.jpg",
  //         Description: "A show where two college kids share their thoughts and opinions on the NBA from the sidelines. Welcome to The Ball Boys, from Brown University.",
  //         Category: ["Sports"],
  //         EpisodeIDs: [5, 6, 7, 8]
  //     },
  //     {
  //         PodcastID: 4,
  //         Title: "Back of the Chart",
  //         Creators: [3, 4],
  //         Img: "https://pbcdn1.podbean.com/imglogo/ep-logo/pbblog4108029/_DSC9382-Sq.jpg",
  //         Description: "A show where two college kids share their thoughts and opinions on the NBA from the sidelines. Welcome to The Ball Boys, from Brown University.",
  //         Category: ["Sports"],
  //         EpisodeIDs: [5, 6, 7, 8]
  //     }
  // ];
};

export default getAllPodcasts;
