import React, { useEffect, useState } from "react";
import * as s from "./StyledPodcastHeader";
import ImageNotFound from "../../../../assets/image_not_found.png";
import subscribePodcast from "../../../../api/podcasts/subscribePodcast";
import unsubscribePodcast from "../../../../api/podcasts/unsubscribePodcast";
import getUser from "../../../../api/user/getUser";
import { useHistory } from "react-router-dom";
import { useStoreState } from "react-context-hook";

const PodcastAuthor = ({ id }) => {
  const [creator, setCreator] = useState({ Name: "", Username: "" });

  const getPodcastAuthor = async (id) => {
    getUser(id).then((item) => {
      setCreator(item);
    });
  };

  useEffect(() => {
    getPodcastAuthor(id);
  }, [id]);

  return (
    <s.PodcastAuthorLink to={`/profile/${creator.Username}`}>
      {creator.Name}
    </s.PodcastAuthorLink>
  );
};

const PodcastHeader = (props) => {
  const history = useHistory();
  const podcast = props.podcast;
  const [subscribed, setSubscribed] = useState(false);
  const { user, loggedIn } = useStoreState();

  useEffect(() => {
    if (podcast.Subscribers && podcast.Subscribers.includes(user.id)) {
      setSubscribed(true);
    }
  }, [podcast.Subscribers, user.id]);

  const handleSubscribe = () => {
    if (!loggedIn) {
      history.push("/signup");
    } else {
      subscribePodcast(podcast.ID, user.id);
      setSubscribed(true);
    }
  };

  const handleUnsubscribe = () => {
    unsubscribePodcast(podcast.ID, user.id);
    setSubscribed(false);
  };

  const renderAuthors = () => {
    if (podcast.Creators && podcast.Creators.length > 0) {
      return (
        <div>
          {" "}
          {podcast.Creators.map((id) => {
            return <PodcastAuthor id={id} />;
          })}{" "}
        </div>
      );
    }
  };

  return (
    <div>
      {!props.loading && (
        <s.PodcastInfoContainer>
          <s.PodcastImageContainer>
            <s.PodcastImage src={podcast.Img ? podcast.Img : ImageNotFound} />
          </s.PodcastImageContainer>

          <s.PodcastTextInfo>
            <s.SpaceBetweenContainer>
              <s.PodcastInfoName>{podcast.Title}</s.PodcastInfoName>
              {loggedIn & subscribed ? (
                <s.UnsubscribeButton onClick={() => handleUnsubscribe()}>
                  SUBSCRIBED
                </s.UnsubscribeButton>
              ) : (
                <s.SubscribeButton onClick={() => handleSubscribe()}>
                  SUBSCRIBE
                </s.SubscribeButton>
              )}
            </s.SpaceBetweenContainer>
            <div>{renderAuthors()}</div>
            <s.PodcastInfoAbout>About</s.PodcastInfoAbout>
            <s.PodcastInfoDescription
              dangerouslySetInnerHTML={{ __html: podcast.Description }}
            />
          </s.PodcastTextInfo>
          <s.PodcastLatestEpisodes>Latest Episodes</s.PodcastLatestEpisodes>
        </s.PodcastInfoContainer>
      )}
    </div>
  );
};

export default PodcastHeader;
