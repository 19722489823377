import styled from "styled-components";
import { Link } from "react-router-dom";

export const GridContainer = styled.div`
  margin-top: 5vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, 50%);
  grid-row-gap: 3.111111111vh;
  justify-items: center;
  margin-bottom: 4.444444444vh;
  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fit, 33.333333333%);
  }
  @media (min-width: 992px) {
    grid-column-gap: 2.222222222vw;
    grid-template-columns: repeat(auto-fit, 13.888888888vw);
  }
`;

export const NoSubscriptionsMessage = styled.h3`
  font-size: 0.875rem;
  color: #4f4f4f;
  margin-top: 0.75rem;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    margin-top: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    margin-top: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
    margin-top: 2rem;
  }
`;

export const NoSubscriptionsLink = styled(Link)`
  color: #2f80ed;
  text-decoration: underline;
`;
