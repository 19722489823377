import axios from "axios";
import { API } from "../constants";

/**
 * Add a comment to an episode
 *
 * @param userID
 * @param followerID
 */
const followUser = async (userID, followerID) => {
  return axios
    .post(API + "/api/users/follow", {
      body: {
        userID: userID,
        followerID: followerID,
      },
      token: window.localStorage.getItem("token"),
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default followUser;
