import styled from "styled-components";

export const Prompt = styled.div`
  font-size: 14px;
  color: #4f4f4f;
  margin-top: 3vh;
  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

