import styled from "styled-components";

export const PodcastListContainer = styled.div`
  margin-bottom: 140px;
  @media (min-width: 992px) {
    margin-left: 5.13888889vw;
    width: 65vw;
  }
  @media (min-width: 1920px) {
    margin-bottom: 160px;
  }
`;
