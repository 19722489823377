import React, { useState, useEffect } from "react";
import getSnippet from "../../../api/snippets/getSnippet";
import getEpisode from "../../../api/podcasts/getEpisode";
import getPodcast from "../../../api/podcasts/getPodcast";
import getUser from "../../../api/user/getUser";
import PageContainer from "../../shared_components/PageContainer/PageContainer";
import Snippet from "./components/Snippet";
import SnippetNotFound from "./components/SnippetNotFound";

export default function SnippetPage(props) {
  const [snippet, setSnippet] = useState({});
  const [episode, setEpisode] = useState({});
  const [podcast, setPodcast] = useState({});
  const [user, setUser] = useState({});
  const [isLoaded1, setIsLoaded1] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [isLoaded3, setIsLoaded3] = useState(false);

  useEffect(() => {
    getSnippet(props.id).then((response) => {
      setSnippet(response);
      getEpisode(response.EpisodeID).then((response2) => {
        setEpisode(response2);
        setIsLoaded1(true);
      });
      getUser(response.UserID).then((response3) => {
        setUser(response3);
        setIsLoaded2(true);
      });
      getPodcast(response.PodcastID).then((response4) => {
        setPodcast(response4);
        setIsLoaded3(true);
      });
    });
  }, [props.id]);

  const content = snippet.SnippetID ? (
    <Snippet
      snippet={snippet}
      episode={episode}
      user={user}
      podcast={podcast}
    />
  ) : (
    <SnippetNotFound />
  );
  return (
    <div>
      <PageContainer active={""}>
        {isLoaded1 && isLoaded2 && isLoaded3 ? content : null}
      </PageContainer>
    </div>
  );
}
