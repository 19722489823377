import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import ProximaNova from "../../../../../assets/fonts/ProximaNova-Regular.otf";

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColumnContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

export const ItemImage = styled.div``;

export const ItemTitle = styled(Link)`
  font-family: "Proxima Nova Lt";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #4f4f4f;
  :hover {
    text-decoration: underline;
  }
`;

export const ItemCategory = styled.p`
  font-family: "Proxima Nova Lt";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140.62%;
  color: #828282;
`;

export const ItemDescription = styled.p`
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0;
  line-height: 1.4;
  color: #828282;
  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const ItemImageImg = styled(LazyLoadImage)`
  border-radius: 5px;
  height: 20vw;
  width: 20vw;
  @media (min-width: 576px) {
    height: 15vw;
    width: 15vw;
  }
  @media (min-width: 992px) {
    height: 10vw;
    width: 10vw;
  }
`;
