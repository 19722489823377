import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Add } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
// import ProximaNova from "../../../../assets/fonts/ProximaNova-Regular.otf";

export const Container = styled.div`
  margin-top: 4vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  @media (min-width: 576px) {
    width: 80%;
  }
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
  }
  @media (min-width: 2560px) {
    // margin-bottom: 150px;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 992px) {
    margin-left: 2vw;
    width: 85%;
    margin-top: 7.5vh;
  }
  @media (min-width: 1920px) {
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 84px;
  @media (min-width: 1920px) {
    min-height: 104px;
  }
  @media (min-width: 2560px) {
    min-height: 132px;
  }
  @media (min-width: 3840px) {
    min-height: 196px;
  }
`;

export const FollowButtonContainer = styled.div`
  display: ${(props) => (props.loggedIn ? "block" : "none")};
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Name = withStyles({
  root: {
    marginBottom: 8,
    marginTop: -2,
    width: "75%",

    "& .MuiInputBase-input": {
      color: "#2F80ED",
      fontSize: "1rem",
      fontWeight: "bold",
      letterSpacing: "normal",
      height: "auto",
      lineHeight: "normal",
      padding: "0",
      paddingTop: 2,
      fontFamily: "Proxima Nova",
      "@media (min-width: 992px)": {
        fontSize: "1.5rem",
      },
      "@media (min-width: 1920px)": {
        fontSize: "2rem",
      },
      "@media (min-width: 2560px)": {
        fontSize: "2.66666666667rem",
      },
      "@media (min-width: 3840px)": {
        fontSize: "4rem",
      },
    },
    "& label.Mui-focused": {
      color: "#2F80ED",
    },
    "& .MuiFilledInput-root ": {
      backgroundColor: "#eff9ff",
    },
  },
})(TextField);

export const Username = withStyles({
  root: {
    width: "75%",
    marginTop: -2,
    "& .MuiFilledInput-adornedStart": {
      paddingLeft: 0,
    },
    "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)": {
      marginTop: 6,
    },
    "& .MuiInputBase-input": {
      color: "#4f4f4f",
      fontSize: ".75rem",
      fontWeight: "bold",
      letterSpacing: "normal",
      height: "auto",
      lineHeight: "normal",
      padding: "0",
      paddingTop: 2,
      fontFamily: "Proxima Nova",
      "@media (min-width: 992px)": {
        fontSize: "0.875rem",
      },
      "@media (min-width: 1920px)": {
        fontSize: "1.16666666667rem",
      },
      "@media (min-width: 2560px)": {
        fontSize: "1.55555555556rem",
      },
      "@media (min-width: 3840px)": {
        fontSize: "2.33333333333rem",
      },
    },
    "& label.Mui-focused": {
      color: "#2F80ED",
    },
    "& .MuiFilledInput-root ": {
      backgroundColor: "#eff9ff",
    },
  },
})(TextField);

export const About = styled.h3`
  color: #2f80ed;
  font-size: 1rem;
  padding-bottom: 4px;
  font-family: Proxima Nova;
  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const EditSaveButton = withStyles((theme) => ({
  root: {
    color: "white",
    outline: "none",
    backgroundColor: "#2F80ED",
    textTransform: "none",
    borderRadius: "30px",
    width: "110px",
    minWidth: "110px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "4px",
    marginBottom: "8px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#007ac9",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "150px",
      minWidth: "150px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "200px",
      minWidth: "200px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "300px",
      minWidth: "300px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
  disabled: {
    backgroundColor: "rgba(0, 0, 0, 0.26)",
  },
}))(Button);

export const FollowButton = withStyles((theme) => ({
  root: {
    color: "white",
    outline: "none",
    backgroundColor: "#0071E2",
    textTransform: "none",
    borderRadius: "30px",
    width: "110px",
    minWidth: "110px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "4px",
    marginBottom: "8px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#007ac9",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "150px",
      minWidth: "150px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "200px",
      minWidth: "200px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "300px",
      minWidth: "300px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
}))(Button);

export const UnfollowButton = withStyles((theme) => ({
  root: {
    color: "white",
    outline: "none",
    backgroundColor: "#BDBDBD",
    textTransform: "none",
    borderRadius: "30px",
    width: "110px",
    minWidth: "110px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "4px",
    marginBottom: "8px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#4F4F4f",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "150px",
      minWidth: "150px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "200px",
      minWidth: "200px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "300px",
      minWidth: "300px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
}))(Button);

export const LogoutButton = withStyles((theme) => ({
  root: {
    color: "#2F80ED",
    outline: "none",
    backgroundColor: "white",
    border: "1px solid #2F80ED",
    textTransform: "none",
    borderRadius: "30px",
    width: "110px",
    minWidth: "110px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "4px",
    marginBottom: "8px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#ebf5ff",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "150px",
      minWidth: "150px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "200px",
      minWidth: "200px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
      border: "2px solid #2F80ED",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "300px",
      minWidth: "300px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
}))(Button);

export const InfoContainer = styled.div`
  width: 100%;
  @media (min-width: 992px) {
    margin-left: 32px;
  }
  @media (min-width: 1920px) {
    margin-left: 48px;
  }
  @media (min-width: 2560px) {
    margin-left: 64px;
  }
  @media (min-width: 3840px) {
    margin-left: 96px;
  }
`;

export const MobileProfilePictureContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.75em;
  @media (min-width: 992px) {
    margin-top: 0;
  }
`;

export const MobileProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const DesktopProfilePicture = styled.img`
  width: 208px;
  height: 208px;
  border-radius: 50%;
  display: none;
  object-fit: cover;
  @media (min-width: 992px) {
    display: block;
  }
  @media (min-width: 1920px) {
    width: 278px;
    height: 278px;
  }
  @media (min-width: 2560px) {
    width: 370px;
    height: 370px;
  }
  @media (min-width: 3840px) {
    width: 554px;
    height: 554px;
  }
`;

export const Description = withStyles({
  root: {
    marginBottom: ".5rem",
    width: "100%",
    marginTop: -2,
    "& .MuiInputBase-multiline": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      color: "#828282",
      fontSize: ".75rem",
      fontWeight: "200",
      padding: 0,
      paddingBottom: 4,
      paddingTop: 2,
      lineHeight: "1.4",
      fontFamily: "Proxima Nova Lt",
      "@media (min-width: 992px)": {
        fontSize: "0.875rem",
      },
      "@media (min-width: 1920px)": {
        fontSize: "1.16666666667rem",
      },
      "@media (min-width: 2560px)": {
        fontSize: "1.55555555556rem",
      },
      "@media (min-width: 3840px)": {
        fontSize: "2.33333333333rem",
      },
    },
    "& label.Mui-focused": {
      color: "#2F80ED",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2F80ED",
    },
    "& .MuiFilledInput-root ": {
      backgroundColor: "#eff9ff",
    },
    "& .MuiInputBase-input::placeholder ": {
      opacity: 1,
    },
  },
})(TextField);

export const AddIcon = styled(Add)`
  color: #2f80ed;
  position: absolute;
  right: 9%;
  bottom: 20%;
  cursor: pointer;
  stroke: #fff;
  stroke-width: 0.5px;

  @media (min-width: 992px) {
    &&& {
      display: block;
    }
  }
  && {
    display: none;
    font-size: 54px;
  }
`;

export const MobileAddIcon = styled(Add)`
  color: #2f80ed;
  position: absolute;
  right: 5%;
  bottom: 15%;
  cursor: pointer;
  stroke: #fff;
  stroke-width: 0.5px;
  @media (min-width: 992px) {
    && {
      display: none;
    }
  }
  && {
    font-size: 48px;
  }
`;

export const DesktopPictureContainer = styled.div`
  position: relative;
  height: min-content;
`;

export const MobilePictureContainer = styled.div`
  position: relative;
`;

export const Prefix = withStyles({
  root: {
    "& .MuiTypography-root": {
      fontSize: ".75rem",
      fontWeight: "bold",
      letterSpacing: "normal",
      height: "auto",
      color: "#4f4f4f",
      lineHeight: "normal",
      fontFamily: "Proxima Nova",
      marginTop: -4,
      "@media (min-width: 992px)": {
        fontSize: "0.875rem",
      },
      "@media (min-width: 1920px)": {
        fontSize: "1.16666666667rem",
      },
      "@media (min-width: 2560px)": {
        fontSize: "1.55555555556rem",
      },
      "@media (min-width: 3840px)": {
        fontSize: "2.33333333333rem",
      },
    },
  },
  positionStart: {
    marginRight: 0,
  },
})(InputAdornment);

export const DesktopFollowContainer = styled.div`
  margin-top: 2vh;
  display: none;
  flex-direction: column;
  align-items: center;
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const MobileFollowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const CreatorTagContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
`;

export const FollowItem = styled.a`
  text-align: center;
  width: max-content;
  display: block;
  :hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

export const FollowText = styled.div`
  font-family: "Proxima Nova Lt";
  font-style: normal;
  color: #4f4f4f;
  font-size: 0.75rem;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const UsernameSuccess = styled.p`
  font-family: "Proxima Nova Lt";
  font-style: normal;
  color: #33ce1e;
  font-size: 0.75rem;
  margin: 0;
  margin-top: 0.125rem;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const UsernameError = styled.p`
  font-family: "Proxima Nova Lt";
  font-style: normal;
  color: #e93700;
  font-size: 0.75rem;
  margin: 0;
  margin-top: 0.125rem;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const ContentHeader = styled.div`
  color: #2f80ed;
  height: auto;
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: normal;
  padding-top: 2px;
  letter-spacing: normal;
  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const PodcastContainer = styled.div`
  margin-top: 10px;
`;
