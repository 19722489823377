import styled from "styled-components";

export const CategoryContainer = styled.div`
  color: #2F80ED;
  font-size: 1.125rem;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const GroupHeadings = styled.div`
  color: #2F80ED;
  font-size: 1.125rem;
  text-transform: uppercase;
  margin-bottom: 1.777777777vh;
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 50%);
  grid-row-gap: 3.111111111vh;
  justify-items: center;
  margin-bottom: 4.444444444vh;
  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fit, 33.333333333%);
  }
  @media (min-width: 992px) {
    grid-column-gap: 2.222222222vw;
    grid-template-columns: repeat(auto-fit, 13.888888888vw);
  }
`;
