import React from "react";
import * as s from "./StyledPageContainer";
import Header from "../Header/Header";
import SideMenu from "../SideMenu/SideMenu";

export default function PageContainer(props) {
  return (
    <s.Container>
      <Header app={props.app} noPics={true} active_link={props.active} />
      <SideMenu selected={props.active} />
      <s.Page_Content>{props.children}</s.Page_Content>
    </s.Container>
  );
}
