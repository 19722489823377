import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  margin-top: 36px;
  margin-bottom: 150px;
  @media (min-width: 2560px) {
    margin-bottom: 180px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CommentHeader = styled.div`
  font: normal normal bold 35px/42px Proxima Nova;
  letter-spacing: 0px;
  color: #2f80ed;
  text-align: center;
  @media (min-width: 992px) {
    font-size: 1.5rem;
    text-align: start;
  }
  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const ErrorMsg = styled.div`
  color: #4f4f4f;
  font-size: 0.75rem;
  margin-top: 4px;
  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    margin-top: 8px;
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    margin-top: 16px;
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    margin-top: 24px;
    font-size: 2.33333333333rem;
  }
`;

export const LoggetOutMessage = styled.div`
  width: 100%;
  font-size: 14px;
  color: #4f4f4f;
  line-height: 25px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  @media (min-width: 992px) {
    text-align: start;
  }
`;

export const LoggedOutLink = styled(Link)`
  color: #2f80ed;
  text-decoration: underline;
`;
