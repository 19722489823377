import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Forward30 from "@material-ui/icons/Forward30";
import Replay30 from "@material-ui/icons/Replay30";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import ProximaNova from "../../../../assets/fonts/ProximaNova-Regular.otf";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 1000;
  background-color: #293d4a;
  height: 105px;
  @media (min-width: 576px) {
    height: 90px;
  }
  @media (min-width: 2560px) {
    height: 120px;
  }
`;

export const PlaybarControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlaybarControlButton = styled(IconButton)`
  && {
    outline: none;
    padding: 4px;
  }
`;

export const PlaybarRangeLabel = styled.p`
  color: white;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  margin-top: 12px;
`;

export const FastForwardIcon = styled(Forward30)`
  && {
    fill: white;
    width: 40px;
    height: 40px;
  }
  @media (min-width: 2560px) {
    && {
      width: 45px;
      height: 45px;
    }
  }
`;

export const RewindIcon = styled(Replay30)`
  && {
    fill: white;
    width: 40px;
    height: 40px;
    @media (min-width: 2560px) {
      && {
        width: 45px;
        height: 45px;
      }
    }
  }
`;

export const PlayPauseButton = styled.img`
  height: 60px;
  width: 60px;
  @media (min-width: 2560px) {
    width: 80px;
    height: 80px;
  }
`;

export const Scissors = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 12px;
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    filter: brightness(50%);
  }
  @media (min-width: 1920px) {
    height: 22px;
    width: 22px;
    margin-top: 6px;
  }
  @media (min-width: 2560px) {
    height: 26px;
    width: 26px;
    margin-left: 16px;
    margin-top: 8px;
  }
  @media (min-width: 3840px) {
    height: 28px;
    width: 28px;
    margin-left: 24px;
  }
`;

export const PodcastLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  cursor: default;
`;

export const PodcastFirstRow = styled.div`
  display: flex;
  column-direction: row;
`;

export const PodcastEpisodeLink = styled(Link)`
  text-decoration: none;
  color: #54646e;
  font-size: 18px;
  max-width: 20vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (min-width: 992px) {
    font-size: 24px;
  }
  @media (min-width: 2560px) {
    font-size: 28px;
  }
`;

export const PodcastSeriesLink = styled(Link)`
  text-decoration: none;
  color: #54646e;
  @media (min-width: 2560px) {
    font-size: 20px;
  }
`;

export const PodcastSeriesLinkContainer = styled.div`
  z-index: 2;
`;

export const SaveContainer = styled.div`
  flex: 1;
  margin-left: 1vw;
  margin-top: -10px;
  position: relative;
  text-align: left;
  @media (min-width: 2560px) {
    font-size: 20px;
    margin-top: -10px;
  }
`;

export const PodcastInfoContainer = styled.div`
  display: none;
  position: absolute;
  padding-left: 10px;
  padding-top: 7px;
  @media (min-width: 991px) {
    display: flex;
  }
`;

export const PodcastThumbnail = styled.img`
  border-color: #293d4a;
  border-radius: 8px;
  height: 76px;
  width: 76px;
  @media (min-width: 2560px) {
    height: 106px;
    width: 106px;
  }
`;

export const MobileTitle = styled.div`
  color: white;
  text-align: center;
  background: #293d4a;
  position: fixed;
  width: 100vw;
  padding-bottom: 15px;
  margin-top: -25px;
  padding-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10%;
  padding-right: 10%;
  bottom: 84px;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const InputRange = styled.div`
  display: flex;
  color: white;
  margin-top: -4px;
  z-index: 3;

  @media (min-width: 2560px) {
    margin-top: -5px;
  }
`;

export const SliderProgress = styled.div`
  display: flex;
  color: white;
  position: absolute;
  top: 13px;
  left: 0;

  input[type="range"] {
    -webkit-appearance: none;
    background-color: #6e8da1;
    height: 6px;
    overflow: hidden;
    border-radius: 12px;
    width: 50vw;
    outline: none;
  }

  input[type="range"]::-moz-range-thumb {
    width: 0;
    border: 0;
  }

  input[type="range"]::-moz-range-track {
    background-color: #6e8da1;
  }

  input[type="range"]::-moz-range-progress {
    background-color: white;
    height: inherit;
    border-radius: 12px;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 10px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: white;
    border-radius: 50%;
    box-shadow: -4000px 0 0 4000px white;
    height: 10px;
    width: 0;
    border: 0;
  }

  input[type="range"]::-ms-fill-upper {
    background-color: #6e8da1;
  }

  input[type="range"]::-ms-fill-lower {
    background-color: white;
  }

  @media (min-width: 991px) {
    input[type="range"] {
      width: 33.333333vw;
    }
  }

  @media (min-width: 1600px) {
    input[type="range"] {
      width: 25vw;
    }
  }

  @media (min-width: 2560px) {
    margin-top: -5px;
  }
`;

export const CancelContainer = styled.div`
  flex: 1;
  margin-right: 1vw;
  margin-top: -10px;
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled.div`
  cursor: pointer;
  text-align: right;
  @media (min-width: 2560px) {
    font-size: 20px;
    margin-top: -10px;
  }
  &:hover {
    filter: brightness(60%);
  }
`;

export const SaveButton = styled.div`
  cursor: pointer;
  width: min-content;
  border: 1px solid;
  border-radius: 18px;
  padding-right: 22px;
  padding-left: 22px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: -2px;
  &:hover {
    color: #007bff;
  }
`;

export const SnippetSliderContainer = styled.div`
  margin-top: -13px;
  position: relative;

  @media (min-width: 991px) {
    width: 33.333333vw;
  }
  @media (min-width: 1600px) {
    width: 25vw;
  }
`;

export const SnippetSlider = withStyles({
  root: {
    color: "#007bff",
  },
  thumb: {
    marginTop: -3,
    zIndex: 2,
    color: "white",
  },
  active: {},
  track: {
    height: 6,
    border: "1px solid white",
    paddingTop: 10,
    paddingBottom: 10,
    color: "transparent",
    marginTop: -8,
  },
  rail: {
    opacity: 1,
    height: 6,
    borderRadius: 12,
  },
})(Slider);
