import styled from "styled-components";
import ProximaNova from "../../../../assets/fonts/ProximaNova-Regular.otf";
import ShareIcon from "@material-ui/icons/Share";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { default as Linkk } from "@material-ui/icons/Link";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";

export const Container = styled.div`
  margin-top: 20px;
  @media (min-width: 992px) {
    margin-left: 8px;
  }
  @media (min-width: 1440px) {
    margin-left: 0px;
  }
  @media (min-width: 1920px) {
    margin-top: 28px;
  }
  @media (min-width: 2560px) {
    margin-top: 36px;
  }
  @media (min-width: 3840px) {
    margin-top: 52px;
  }
`;

export const SnippetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  margin-top: 4vh;
  margin-bottom: 150px;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: normal;
  }
  @media (min-width: 2560px) {
    margin-bottom: 180px;
  }
`;

export const SnippetInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 52px;

  @media (min-width: 992px) {
    align-items: normal;
    margin-left: 28px;
    margin-top: 0;
  }
  @media (min-width: 1920px) {
    margin-left: 32px;
  }
  @media (min-width: 2560px) {
    margin-left: 40px;
  }
  @media (min-width: 3840px) {
    margin-left: 52px;
  }
`;

export const Thumbnail = styled.img`
  outline: none;
  position: absolute;
  border-radius: 5px;
  height: 208px;
  width: 208px;

  @media (min-width: 992px) {
    border-radius: 10px;
  }
  @media (min-width: 1920px) {
    height: 278px;
    width: 278px;
  }
  @media (min-width: 2560px) {
    height: 370px;
    width: 370px;
  }
  @media (min-width: 3840px) {
    height: 554px;
    width: 554px;
  }
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 208px;
  height: 208px;
  min-width: 208px;

  @media (min-width: 992px) {
    justify-content: normal;
    margin-left: 106px;
  }

  @media (min-width: 1920px) {
    height: 278px;
    margin-left: 133px;
    width: 278px;
    min-width: 278px;
  }
  @media (min-width: 2560px) {
    height: 370px;
    margin-left: 177px;
    width: 370px;
    min-width: 370px;
  }
  @media (min-width: 3840px) {
    height: 554px;
    margin-left: 266px;
    width: 554px;
    min-width: 554px;
  }
`;

export const PodcastName = styled(Link)`
  color: #4f4f4f;
  font-size: 0.96rem;
  font-family: "Proxima Nova Lt";
  padding: 0;
  margin: 0;
  margin-top: 8px;
  line-height: 1.2;
  display: block;

  @media (min-width: 992px) {
    font-size: 1.125rem;
    margin-top: 8px;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
    margin-top: 12px;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const EpisodeName = styled(Link)`
  color: #4f4f4f;
  font-size: 0.96rem;
  padding: 0;
  margin: 0;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-weight: 400;
  margin-top: 2px;
  line-height: 1.2;
  display: block;

  @media (min-width: 992px) {
    font-size: 1.125rem;
    margin-top: 4px;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
    margin-top: 6px;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
    margin-top: 8px;
  }
`;

export const SnippetName = styled.h2`
  color: #2f80ed;
  font-size: 1.125rem;
  font-family: "Proxima Nova";
  margin-top: 12px;
  padding: 0;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const SnippetDescription = styled.h4`
  color: #4f4f4f;
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 0.96rem;
  margin-top: 20px;

  @media (min-width: 992px) {
    display: block;
    color: #4f4f4f;
    font-size: 1.125rem;
    margin-top: 28px;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
    margin-top: 32px;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
    margin-top: 36px;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
    margin-top: 56px;
  }
`;

export const SnippetDescriptionDescription = styled.p`
  color: #828282;
  font-size: 0.75rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-weight: 400;

  @media (min-width: 576px) {
    width: 350px;
  }

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1440px) {
    width: 500px;
  }
  @media (min-width: 1920px) {
    font-size: 1.15rem;
    width: 650px;
  }
  @media (min-width: 2560px) {
    font-size: 1.5rem;
    width: 800px;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333rem;
    width: 1250px;
  }
`;

export const SnippetHeader = styled.div``;

export const PodcastNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 208px;
  @media (min-width: 1920px) {
    width: 278px;
  }
  @media (min-width: 2560px) {
    width: 370px;
  }
  @media (min-width: 3840px) {
    width: 554px;
  }
`;

export const PodcastNameFullWidthContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SnippetNotice = styled.h1`
  color: #333333;
  font-size: 1.125rem;
  font-family: "Proxima Nova Lt";
  margin: 0;

  @media (min-width: 992px) {
    font-size: 1.5rem;
    color: #4f4f4f;
  }
  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const SnippetAuthorNotice = styled.h3`
  color: #828282;
  font-size: 0.75rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-weight: 400;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.15rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.5rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.333333333rem;
  }
`;

export const SnippetAuthor = styled.span`
  color: #828282;
  text-decoration: underline;
  font-weight: bold;
  font-family: "Proxima Nova";
`;
export const SnippetAuthorLink = styled(Link)`
  color: #828282;
  text-decoration: underline;
  font-weight: bold;
  font-family: "Proxima Nova";
  &:hover {
    color: #2f80ed;
  }
`;

export const InputDuration = styled.div`
  flex: 1;
  color: white;
  position: relative;
  text-align: left;
  margin-left: 4%;
  font-size: 14px;
  margin-bottom: 6px;
  @media (min-width: 1920px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
  @media (min-width: 2560px) {
    font-size: 24px;
    margin-bottom: 12px;
  }
  @media (min-width: 3840px) {
    font-size: 36px;
    margin-bottom: 20px;
  }
`;

export const InputRange = styled.div`
  display: flex;
  color: #bdbdbd;
  position: absolute;
  bottom: 0;
  width: 208px;

  input[type="range"] {
    -webkit-appearance: none;
    background-color: #fff;
    height: 6px;
    overflow: hidden;
    border-radius: 12px;
    width: 70%;
    z-index: 3;
    margin-top: 7px;
    margin-left: 7%;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 6px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #bdbdbd;
    border-radius: 50%;
    box-shadow: -155px 0 0 150px #bdbdbd;
    height: 6px;
    width: 0;
    border: 0;
  }

  input[type="range"]::-moz-range-thumb {
    background: #bdbdbd;
    border-radius: 50%;
    box-shadow: -505px 0 0 500px #bdbdbd;
    height: 6px;
    width: 0px;
    border: 0;
  }

  input[type="range"]::-moz-range-track {
    background-color: #fff;
  }

  input[type="range"]::-moz-range-progress {
    background-color: #bdbdbd;
    height: 6px;
  }

  input[type="range"]::-ms-fill-upper {
    background-color: #fff;
  }

  input[type="range"]::-ms-fill-lower {
    background-color: #bdbdbd;
  }

  @media (min-width: 1920px) {
    width: 278px;
    input[type="range"] {
      height: 8px;
      margin-top: 10px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 8px;
    }

    input[type="range"]::-webkit-slider-thumb {
      height: 8px;
    }

    input[type="range"]::-moz-range-thumb {
      height: 8px;
    }

    input[type="range"]::-moz-range-progress {
      height: 8px;
    }
  }
  @media (min-width: 2560px) {
    width: 370px;
    input[type="range"] {
      height: 10px;
      margin-top: 13px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 10px;
    }

    input[type="range"]::-webkit-slider-thumb {
      height: 10px;
    }

    input[type="range"]::-moz-range-thumb {
      height: 10px;
    }

    input[type="range"]::-moz-range-progress {
      height: 10px;
    }
  }
  @media (min-width: 3840px) {
    width: 554px;
    input[type="range"] {
      height: 14px;
      margin-top: 21px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 14px;
    }

    input[type="range"]::-webkit-slider-thumb {
      height: 14px;
    }

    input[type="range"]::-moz-range-thumb {
      height: 14px;
    }

    input[type="range"]::-moz-range-progress {
      height: 14px;
    }
  }
`;

export const ShareText = styled.h4`
  color: #4f4f4f;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;

  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const ShareIconn = styled(ShareIcon)`
  margin-left: 12px;
  margin-top: -2px;
  cursor: pointer;
  && {
    font-size: 1.3rem;
  }
  @media (min-width: 992px) {
    margin-top: 0px;
  }
  @media (min-width: 1920px) {
    margin-top: 2px;
    margin-left: 16px;
    && {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 2560px) {
    margin-top: 3px;
    margin-left: 22px;
    && {
      font-size: 1.9rem;
    }
  }
  @media (min-width: 3840px) {
    margin-top: 5px;
    margin-left: 32px;
    && {
      font-size: 2.75rem;
    }
  }
`;

export const DialogTitlee = styled(DialogTitle)``;

export const Dialogg = styled(Dialog)``;

export const DialogActionss = styled(DialogActions)``;

export const DialogContentt = styled(DialogContent)``;

export const DialogContentTextt = styled(DialogContentText)``;

export const Buttonn = styled(Button)`
  && {
    color: #59a7ed;
  }
`;

export const ShareLinkPositioning = styled.div`
  position: relative;
  background: #f2f2f2;
  border-radius: 3px;
`;

export const LinkIcon = styled(Linkk)`
  position: absolute;
  transform: rotate(-40deg);
  padding: 3px;
  margin-top: 1px;
  @media (min-width: 1920px) {
    padding: 1px;
    margin-top: 3px;
    margin-left: 2px;
  }
  @media (min-width: 2560px) {
    padding: 0px;
    margin-top: 6px;
    margin-left: 6px;
    && {
      font-size: 1.7rem;
    }
  }
  @media (min-width: 3840px) {
    margin-top: 8px;
    margin-left: 12px;
    && {
      font-size: 2.5rem;
    }
  }
`;

export const ShareLink = styled.p`
  padding: 4px;
  color: #4f4f4f;
  margin-left: 24px;
  outline: none;
  appearance: none;
  font-size: 0.75rem;
  border: none;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  font-weight: 400;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
    margin-left: 32px;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
    margin-left: 54px;
  }
`;

export const PauseIcon = styled.img`
  && {
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 208px;
    width: 208px;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
  }

  @media (min-width: 1920px) {
    && {
      height: 278px;
      width: 278px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 370px;
      width: 370px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 554px;
      width: 554px;
    }
  }
`;

export const PlayIcon = styled.img`
  && {
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 208px;
    width: 208px;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
    margin-left: 2%;
  }

  @media (min-width: 1920px) {
    && {
      height: 278px;
      width: 278px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 370px;
      width: 370px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 554px;
      width: 554px;
    }
  }
`;

export const CommentIcon = styled(ModeCommentOutlinedIcon)`
  cursor: pointer;
  && {
    font-size: 1.3rem;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 2rem;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 3rem;
    }
  }
`;

export const LikeIcon = styled(FavoriteBorderIcon)`
  cursor: pointer;
  && {
    font-size: 1.3rem;
    margin-right: 4px;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.5rem;
      margin-bottom: 2px;
      margin-right: 6px;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 2rem;
      margin-bottom: 4px;
      margin-right: 8px;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 3rem;
      margin-bottom: 8px;
      margin-right: 12px;
    }
  }
`;

export const LikedIcon = styled(FavoriteIcon)`
  cursor: pointer;
  && {
    font-size: 1.3rem;
    margin-right: 2.75%;
    margin-right: 4px;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1920px) {
    && {
      font-size: 1.5rem;
      margin-bottom: 2px;
      margin-right: 6px;
    }
  }
  @media (min-width: 2560px) {
    && {
      font-size: 2rem;
      margin-bottom: 4px;
      margin-right: 8px;
    }
  }
  @media (min-width: 3840px) {
    && {
      font-size: 3rem;
      margin-bottom: 8px;
      margin-right: 12px;
    }
  }
`;

export const LikeRow = styled.div`
  z-index: 4;
  width: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  @media (min-width: 992px) {
    margin-left: 106px;
  }
  @media (min-width: 1920px) {
    width: 278px;
    margin-left: 133px;
    margin-top: 16px;
  }
  @media (min-width: 2560px) {
    width: 370px;
    margin-left: 177px;
    margin-top: 20px;
  }
  @media (min-width: 3840px) {
    width: 554px;
    margin-left: 266px;
    margin-top: 28px;
  }
`;

export const UnderThumbnailContainer = styled.div`
  z-index: 4;
  width: 208px;

  margin-top: 10px;

  @media (min-width: 992px) {
    margin-left: 106px;
  }
  @media (min-width: 1920px) {
    width: 278px;
    margin-left: 133px;
  }
  @media (min-width: 2560px) {
    width: 370px;
    margin-left: 177px;
  }
  @media (min-width: 3840px) {
    width: 554px;
    margin-left: 266px;
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LikeText = styled.h4`
  color: #4f4f4f;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;
  margin-right: 8.5%;
  margin-bottom: 0;

  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const CommentText = styled.h4`
  color: #4f4f4f;
  font-family: "Proxima Nova Lt";
  font-size: 0.875rem;
  margin-bottom: 0;

  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const SignUpLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
`;

export const SnippetNoticeRow = styled.div`
  display: flex;
  margin-bottom: 0.375rem;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 992px) {
    margin-right: 10%;
  }
`;
