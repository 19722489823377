import axios from "axios";
import { API } from "../constants";
/**
 * Function to retrieve snippets from the community
 */
const getCommunitySnippet = async () => {
  let api = API + "/api/snippet/general";

  return axios.get(api).then((response) => {
    return response.data;
  });
};

export default getCommunitySnippet;
