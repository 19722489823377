import axios from "axios";
import { API } from "../constants";

/**
 * Function to retrieve specific podcast information
 *
 * @param id - podcast id to retrieve
 */
const getPodcast = async (id) => {
  let api = API + "/api/podcast?PodcastID=" + id;

  return axios.get(api).then((response) => {
    return response.data;
  });
};

export default getPodcast;
