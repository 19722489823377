import axios from "axios";
import { API } from "../constants";

/**
 * getUserSnippets: function that makes a POST request to get a user's snippets
 *
 * @param {*} token
 */
const getUserSnippets = async (token) => {
  let api = API + "/api/episode/snippet/all?Token=" + token;

  return axios.get(api).then((response) => {
    return response.data;
  });
};

export default getUserSnippets;
