import React, { useState, useRef, useEffect } from "react";
import * as s from "./StyledSnippet";
import ImageNotFound from "../../../../assets/image_not_found.png";
import ReactPlayer from "react-player";
import { useSetStoreValue, useStoreState } from "react-context-hook";
import formatSeconds from "../../../../utils/formatSeconds";
import moment from "moment";
import likeSnippet from "../../../../api/snippets/likeSnippet";
import unlikeSnippet from "../../../../api/snippets/unlikeSnippet";
import AddToAudioboard from "./AddToAudioboard/AddToAudioboard";
import PlayIcon from "../../../../assets/PlayIcon.svg";
import PauseIcon from "../../../../assets/PauseIcon.svg";

export default function Snippet(props) {
  const snippet = props.snippet;
  const episode = props.episode;
  const podcast = props.podcast;
  const snippetUser = props.user;
  const setPlaying = useSetStoreValue("playing");
  const { loggedIn, user } = useStoreState();
  const [url, setUrl] = useState(null);
  const [snippetPlaying, setSnippetPlaying] = useState(false);
  const [initialSeek, setInitialSeek] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const player = useRef();
  const duration = snippet.EndTime - snippet.StartTime;
  const [open, setOpen] = React.useState(false);
  const handleFocus = (event) => event.target.select();
  const urll = window.location.host + "/s/" + snippet.SnippetID;
  const [liked, setLiked] = React.useState(false);
  const [isLikeInArray, setIsLikeInArray] = React.useState(false);

  useEffect(() => {
    if (snippet.Likes && user.id && snippet.Likes.includes(user.id)) {
      setLiked(true);
      setIsLikeInArray(snippet.Likes.includes(user.id));
    }
  }, [snippet, user.id]);

  useEffect(() => {
    setPlaying(false);
    setUrl(snippet.EpisodeURL);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let thumbnail = "";
  if ("Podcast" in podcast) {
    thumbnail = episode.Img
      ? episode.Img
      : podcast.Podcast.Img
      ? podcast.Podcast.Img
      : ImageNotFound;
  } else {
    thumbnail = episode.Img ? episode.Img : ImageNotFound;
  }

  const handleProgress = (state) => {
    if (snippet.EndTime <= state.playedSeconds) {
      setSnippetPlaying(false);
      player.current.seekTo(snippet.StartTime);
    }
    setSeconds(state.playedSeconds - snippet.StartTime);
  };

  const handleReady = () => {
    if (!initialSeek) {
      player.current.seekTo(snippet.StartTime);
      setInitialSeek(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleLike = () => {
    if (liked) {
      unlikeSnippet(snippet.SnippetID, user.id);
      setLiked(false);
    } else {
      likeSnippet(snippet.SnippetID, user.id);
      setLiked(true);
    }
  };

  let likeHeart = liked ? (
    <s.LikedIcon onClick={handleLike} />
  ) : (
    <s.LikeIcon onClick={handleLike} />
  );

  if (!loggedIn) {
    likeHeart = (
      <s.SignUpLink to="/signup">
        <s.LikeIcon />
      </s.SignUpLink>
    );
  }

  let likeCount;
  if (isLikeInArray && liked) {
    likeCount = 0;
  } else if (isLikeInArray && !liked) {
    likeCount = -1;
  } else if (!isLikeInArray && liked) {
    likeCount = 1;
  } else if (!isLikeInArray && !liked) {
    likeCount = 0;
  }

  return (
    <s.Container>
      <ReactPlayer
        ref={player}
        url={url}
        playing={snippetPlaying}
        style={{ display: "none" }}
        progressInterval={250}
        onProgress={handleProgress}
        onReady={handleReady}
      />
      <s.SnippetNoticeRow>
        <s.SnippetNotice>Audio Snippet</s.SnippetNotice>
        <AddToAudioboard snippet={snippet} />
      </s.SnippetNoticeRow>
      <s.SnippetAuthorNotice>
        Created by{" "}
        {snippet.UserID ? (
          <s.SnippetAuthorLink to={"/profile/" + snippetUser.Username}>
            @{snippetUser.Username ? snippetUser.Username : "anonymous"}
          </s.SnippetAuthorLink>
        ) : (
          <s.SnippetAuthor>
            @{snippetUser.Username ? snippetUser.Username : "anonymous"}
          </s.SnippetAuthor>
        )}{" "}
        on {moment(new Date(snippet.CreatedOn)).format("ll")}.
      </s.SnippetAuthorNotice>
      <s.SnippetContainer>
        <div>
          <s.ThumbnailContainer>
            <s.Thumbnail src={thumbnail} />
            {snippetPlaying ? (
              <s.PauseIcon
                src={PauseIcon}
                onClick={() => {
                  setSnippetPlaying(false);
                }}
              />
            ) : (
              <s.PlayIcon
                src={PlayIcon}
                onClick={() => {
                  setSnippetPlaying(true);
                }}
              />
            )}

            <s.InputRange>
              <input
                type="range"
                min={0}
                max={duration}
                step="any"
                value={seconds}
                onChange={(e) => {
                  const val =
                    parseFloat(e.target.value) < 1
                      ? 0
                      : parseFloat(e.target.value);
                  player.current.seekTo(snippet.StartTime + val);
                }}
              />
              <s.InputDuration>{formatSeconds(duration)}</s.InputDuration>
            </s.InputRange>
          </s.ThumbnailContainer>
          <s.LikeRow>
            {likeHeart}
            <s.CommentText>{snippet.Likes.length + likeCount}</s.CommentText>
          </s.LikeRow>
          <s.UnderThumbnailContainer>
            <s.ShareContainer>
              <s.ShareText>Share</s.ShareText>
              <s.ShareIconn onClick={handleClickOpen} />
            </s.ShareContainer>
            <s.Dialogg onClose={handleClose} open={open}>
              <s.DialogTitlee>Share</s.DialogTitlee>
              <s.DialogContentt>
                <s.DialogContentTextt>
                  <s.ShareLinkPositioning>
                    <s.LinkIcon />
                    <s.ShareLink onFocus={handleFocus}>{urll}</s.ShareLink>
                  </s.ShareLinkPositioning>
                </s.DialogContentTextt>
              </s.DialogContentt>
              <s.DialogActionss>
                <s.Buttonn onClick={handleClose}>Close</s.Buttonn>
              </s.DialogActionss>
            </s.Dialogg>
          </s.UnderThumbnailContainer>
        </div>

        <s.SnippetInfo>
          <s.SnippetHeader>
            <s.SnippetName>
              {snippet.SnippetTitle
                ? snippet.SnippetTitle
                : "This snippet has no title."}
            </s.SnippetName>
            <s.PodcastName
              to={"/podcasts/" + snippet.PodcastID + "/" + snippet.PodcastName}
            >
              {snippet.PodcastName}
            </s.PodcastName>
            <s.EpisodeName
              to={
                "/podcasts/" +
                snippet.PodcastID +
                "/" +
                snippet.PodcastName +
                "/" +
                snippet.EpisodeID +
                "/" +
                snippet.EpisodeName
              }
            >
              {snippet.EpisodeName}
            </s.EpisodeName>
          </s.SnippetHeader>
          <s.SnippetDescription>Description</s.SnippetDescription>
          <s.SnippetDescriptionDescription>
            {snippet.SnippetDescription
              ? snippet.SnippetDescription
              : "This snippet has no description."}
          </s.SnippetDescriptionDescription>
        </s.SnippetInfo>
      </s.SnippetContainer>
    </s.Container>
  );
}
