import React from "react";
import * as s from "./StyledSnippetComment";
import defaultProfile from "../../../../../assets/DefaultProfile.svg";

const SnippetComment = (props) => {
  const comment = props.comment;
  return (
    <s.Comment>
      <s.CommentAuthorPicLink to={"/profile/" + comment.Username}>
        <s.CommentAuthorPic
          src={comment.UserAvatar ? comment.UserAvatar : defaultProfile}
        />
      </s.CommentAuthorPicLink>
      <s.CommentTexts>
        <s.CommentsAuthor to={"/profile/" + comment.Username}>
          @{comment.Username}
        </s.CommentsAuthor>
        <s.CommentText>{comment.Text}</s.CommentText>
      </s.CommentTexts>
    </s.Comment>
  );
};

export default SnippetComment;
