import React, { useEffect, useState } from "react";
import * as s from "./StyledFollowInfoItem";
import { useStoreState } from "react-context-hook";
import { Link } from "react-router-dom";
import DefaultProfile from "../../../../../assets/DefaultProfile.svg";
import getUser from "../../../../../api/user/getUser";

const FollowInfoItem = (props) => {
  const { user } = useStoreState();
  const profileID = props.profileID;
  const [profile, setProfile] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      const getProfile = async () => {
        const user = await getUser(profileID);
        setProfile(user);
      };

      getProfile();
      setLoaded(true);
    }
  }, [loaded, profileID]);

  return (
    <s.ProfileContainer>
      <s.ProfileInformation>
        <s.ColumnCenterContainer>
          <s.ProfilePicture src={profile.Img || DefaultProfile} />
        </s.ColumnCenterContainer>

        <s.ColumnContainer>
          <s.LinkContainer>
            <Link
              to={`/profile/${profile.Username}`}
              onClick={()=>props.closeModal()}
            >
              <s.NameText>{profile.Name}</s.NameText>
            </Link>
            <Link
              to={`/profile/${profile.Username}`}
              onClick={()=>props.closeModal()}
            >
              <s.UsernameText>
                {"@"}
                {profile.Username}
              </s.UsernameText>
            </Link>
          </s.LinkContainer>
        </s.ColumnContainer>
      </s.ProfileInformation>
      <s.ColumnCenterContainer>
        <s.ButtonContainer self={profileID === user.id}>
          {props.following && props.following.includes(profileID) ? (
            <s.UnfollowButton
              onClick={() => props.handleUnfollow(profileID, user.id)}
            >
              Unfollow
            </s.UnfollowButton>
          ) : (
            <s.FollowButton
              onClick={() => props.handleFollow(profileID, user.id)}
            >
              Follow
            </s.FollowButton>
          )}
        </s.ButtonContainer>
      </s.ColumnCenterContainer>
    </s.ProfileContainer>
  );
};

export default FollowInfoItem;
