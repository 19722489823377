import React, { useState, useEffect, useRef, useContext } from "react";
import * as s from "./StyledPlaybarCreateSnippet";
import Scissors from "../../../../assets/scissors.svg";
import {
  useGetAndSet,
  useStoreState,
} from "react-context-hook";
import EpisodePlayButton from "../../../../assets/episode play button alt.svg";
import EpisodePauseButton from "../../../../assets/episode pause button alt.svg";
import formatSeconds from "../../../../utils/formatSeconds";
import addSnippet from "../../../../api/snippets/addSnippet";
import { AudioPlayerContext } from "./AudioPlayerContext";

export default function PlaybarCreateSnippet(props) {
  const [playing, setPlaying] = useGetAndSet("playing");

  const [playImageLoaded, setPlayImageLoaded] = useState(false);

  const {
    currentPodcastID,
    currentEpisodeID,
    currentImage,
    currentSeries,
    currentTitle,
    url,
    user,
  } = useStoreState();

  const {
    seconds,
    setSeconds,
    setIsCreateSnippetState,
    setProgressInterval,
    snippetStart,
    playerSeekTo,
  } = useContext(AudioPlayerContext);

  const clipTime =
    snippetStart > props.maxSnippetTime ? props.maxSnippetTime : snippetStart;

  const startClipTime = clipTime - 30 < 0 ? 0 : clipTime - 30;
  const [clipInterval, setClipInterval] = useState([startClipTime, clipTime]);

  const secondsRef = useRef(seconds);
  secondsRef.current = seconds;
  const clipIntervalRef = useRef(clipInterval);
  clipIntervalRef.current = clipInterval;

  useEffect(() => {
    playerSeekTo(snippetStart - clipTime + clipIntervalRef.current[0]);
    setSeconds(snippetStart - clipTime + clipIntervalRef.current[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clipIntervalRef.current[0] === clipIntervalRef.current[1]) {
        return;
      }
      if (
        secondsRef.current >=
        snippetStart - clipTime + clipIntervalRef.current[1]
      ) {
        playerSeekTo(snippetStart - clipTime + clipIntervalRef.current[0]);
        setSeconds(snippetStart - clipTime + clipIntervalRef.current[0]);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [snippetStart, clipTime, playerSeekTo, setSeconds]);

  return (
    <s.Container>
      {currentImage === null ? (
        <div />
      ) : (
        <s.MobileTitle>
          <s.PodcastEpisodeLink
            to={
              "/podcasts/" +
              currentPodcastID +
              "/" +
              currentSeries +
              "/" +
              currentEpisodeID +
              "/" +
              currentTitle
            }
          >
            {currentTitle}
          </s.PodcastEpisodeLink>
        </s.MobileTitle>
      )}
      <s.PodcastInfoContainer>
        {currentImage === null ? (
          <div />
        ) : (
          <s.PodcastThumbnail alt={"loading..."} src={currentImage} />
        )}
        <s.PodcastLinks>
          <s.PodcastFirstRow>
            <s.PodcastEpisodeLink
              to={
                "/podcasts/" +
                currentPodcastID +
                "/" +
                currentSeries +
                "/" +
                currentEpisodeID +
                "/" +
                currentTitle
              }
            >
              {currentTitle}
            </s.PodcastEpisodeLink>
            {currentImage === null ? (
              <div />
            ) : (
              <s.Scissors
                src={Scissors}
                onClick={() => {
                  setProgressInterval(1000);
                  setIsCreateSnippetState(false);
                  playerSeekTo(snippetStart);
                  setSeconds(snippetStart);
                }}
              />
            )}
          </s.PodcastFirstRow>
          <s.PodcastSeriesLinkContainer>
            <s.PodcastSeriesLink
              to={"/podcasts/" + currentPodcastID + "/" + currentSeries}
            >
              {currentSeries}
            </s.PodcastSeriesLink>
          </s.PodcastSeriesLinkContainer>
        </s.PodcastLinks>
      </s.PodcastInfoContainer>
      <s.PlaybarControls>
        <s.PlaybarRangeLabel>
          {formatSeconds(snippetStart + clipInterval[0] - clipTime)}
        </s.PlaybarRangeLabel>
        <s.PlaybarControlButton onClick={() => setPlaying(!playing)}>
          {" "}
          {playing && currentImage !== null ? (
            <s.PlayPauseButton src={EpisodePauseButton} alt="pause" />
          ) : (
            <div>
              {playImageLoaded ? null : (
                <div
                  style={{
                    background: "#293D4A",
                    height: "60px",
                    width: "60px",
                  }}
                />
              )}

              <s.PlayPauseButton
                style={playImageLoaded ? {} : { display: "none" }}
                src={EpisodePlayButton}
                alt="play"
                onLoad={() => setPlayImageLoaded(true)}
              />
            </div>
          )}
        </s.PlaybarControlButton>
        <s.PlaybarRangeLabel>
          {formatSeconds(snippetStart + clipInterval[1] - clipTime)}
        </s.PlaybarRangeLabel>
      </s.PlaybarControls>

      <s.InputRange>
        <s.CancelContainer>
          <s.CancelButton
            onClick={() => {
              setProgressInterval(1000);
              setIsCreateSnippetState(false);
              playerSeekTo(snippetStart);
              setSeconds(snippetStart);
            }}
          >
            CANCEL
          </s.CancelButton>
        </s.CancelContainer>

        <s.SnippetSliderContainer>
          <s.SnippetSlider
            value={clipInterval}
            min={0}
            max={clipTime}
            onChange={(event, newValue) => {
              if (newValue[0] - newValue[1] >= 0) {
                // Only update if they change the distance to be above length of 1.
                props.handleWarning();
                return;
              }
              if (clipInterval[0] !== newValue[0]) {
                playerSeekTo(newValue[0] + snippetStart - clipTime);
                setSeconds(newValue[0] + snippetStart - clipTime);
              }
              setClipInterval(newValue);
            }}
          />

          <s.SliderProgress>
            <input
              type="range"
              min={0}
              max={clipTime}
              step="any"
              value={seconds - snippetStart + clipTime}
              readOnly
            />
          </s.SliderProgress>
        </s.SnippetSliderContainer>

        <s.SaveContainer>
          <s.SaveButton
            onClick={() => {
              playerSeekTo(snippetStart);
              setSeconds(snippetStart);
              setProgressInterval(1000);
              let startTime;
              let endTime;
              if (snippetStart <= props.maxSnippetTime) {
                startTime = clipInterval[0];
                endTime = clipInterval[1];
              } else {
                startTime =
                  clipInterval[0] + snippetStart - props.maxSnippetTime;
                endTime = clipInterval[1] + snippetStart - props.maxSnippetTime;
              }
              const token = window.localStorage.getItem("token");
              const snippet = {
                podcastName: currentSeries,
                episodeURL: url,
                episodeName: currentTitle,
                episodeID: currentEpisodeID,
                podcastID: currentPodcastID,
                snippetTitle: "",
                snippetDescription: "",
                userID: user.id ? user.id.toString() : "",
                username: user.username ? user.username.toString() : "",
                startTime: startTime,
                endTime: endTime,
              };
              addSnippet(snippet, token).then((response) =>
                props.handleSnippetInfoOpen(response)
              );

              setIsCreateSnippetState(false);
            }}
          >
            SAVE
          </s.SaveButton>
        </s.SaveContainer>
      </s.InputRange>
    </s.Container>
  );
}
