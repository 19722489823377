import styled from "styled-components";
import ProximaNova from "../../../../../assets/fonts/ProximaNova-Regular.otf";

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CenteredRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CommentAuthor = styled.div`
  text-align: center;
  font: normal normal 600 1rem/1.5rem proxima-nova, sans-serif;
  color: #717171;
`;

export const CommentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  border-bottom: 0.5px solid #dadada;
`;

export const CommentDisplay = styled.div`
  font: normal normal 400 14px proxima-nova, sans-serif;
  line-height: 20px;
  margin-left: 15px;
  color: #828282;
  width: 100%;
  background: white 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  outline: none;
  border: none;
`;

export const DateText = styled.p`
  margin-top: 5px;
  margin-right: 5px;
  font: normal normal 400 14px proxima-nova, sans-serif;
  line-height: 20px;
  color: #828282;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalCommentAuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75px;
`;

export const ProfileImage = styled.img`
  background-color: #ebebeb;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  @media (min-width: 576px) {
    margin-left: 50px;
    margin-right: 50px;
  }
`;

export const UsernameText = styled.p`
  margin: 0;
  color: #333333;
  font-weight: 500;
  font-size: 0.75rem;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
  }
  margin-top: 5px;
`;

export const EndContainer = styled.div`
  height: 34px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const DeleteButton = styled.button`
  margin-top: -5px;
  background: none;
  border: none;
  outline: none;
`;
