import styled from "styled-components";
import { ReactComponent as NotificationsFilled } from "../../../../assets/notifications_filled.svg";
import { ReactComponent as NotificationsNone } from "../../../../assets/notifications_none.svg";

export const NotificationComponent = styled.div`
  height: 100%;
  display: none;
  @media (min-width: 576px) {
    display: block;
  }
`;

export const NotificationNumber = styled.div`
  position: relative;
  bottom: 48px;
  left: 16px;
  background: #ff0b0b;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  color: white;
  text-align: center;
  font-size: 0.7rem;
  z-index: 10;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (min-width: 992px) {
    bottom: 40px;
  }
`;

export const NoNumber = styled.div`
  position: relative;
  bottom: 48px;
  left: 16px;
  background: transparent;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  color: white;
  text-align: center;
  z-index: 10;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (min-width: 992px) {
    bottom: 40px;
  }
`;

export const BlueTriangle = styled.div`
  width: 0;
  height: 0;
  position: relative;
  right: 12px;
  bottom: 11px;
  border-left: 27px solid transparent;
  border-right: 27px solid transparent;
  border-bottom: 18px solid #d6efff;
`;

export const WhiteTriangle = styled.div`
  width: 0;
  height: 0;
  position: relative;
  right: 12px;
  bottom: 26px;
  border-left: 27px solid transparent;
  border-right: 27px solid transparent;
  border-bottom: 18px solid white;
`;

export const VerticalContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  padding-top: 20px;
`;

export const HeaderText = styled.div`
  color: #2f80ed;
  font: normal normal 600 1.2rem proxima-nova, sans-serif;
`;

export const ViewAllText = styled.button`
  color: #2f80ed;
  font: normal normal 600 1.1rem proxima-nova, sans-serif;
  border: none;
  background: none;
`;

export const CloseText = styled.button`
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  color: #4f4f4f;
  line-height: 25px;
  border: none;
  background: none;
  font-family: Proxima Nova Lt;
`;

export const NotificationContainer = styled.div`
  width: 275px;
  height: 310px;
  margin-top: -30px;
  margin-left: -160px;
  border: 2px solid rgba(179, 222, 249, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  background: white;
  @media (min-width: 576px) {
    margin-left: -190px;
  }
  @media (min-width: 992px) {
    margin-left: -175px;
  }
`;

export const NotificationsList = styled.ul`
  background: white;
  height: 77%;
  padding-left: 0;
  list-style-type: none;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotifFilled = styled(NotificationsFilled)`
  cursor: pointer;
  height: 28px;
  margin-right: 4px;
  @media (min-width: 992px) {
    margin-right: 8px;
  }
  @media (min-width: 1920px) {
    height: 34px;
  }
  @media (min-width: 2560px) {
    height: 100%;
    margin-right: 24px;
  }
  @media (min-width: 3840px) {
    margin-right: 36px;
    width: 54px;
  }
`;

export const NotifNone = styled(NotificationsNone)`
  cursor: pointer;
  height: 28px;
  margin-right: 4px;
  @media (min-width: 992px) {
    margin-right: 8px;
  }
  @media (min-width: 1920px) {
    height: 34px;
  }
  @media (min-width: 2560px) {
    height: 100%;
    margin-right: 24px;
  }
  @media (min-width: 3840px) {
    margin-right: 36px;
    width: 54px;
  }
`;

export const NotifVerticalCenter = styled.div`
  display: flex;
  align-items: center;
`;
