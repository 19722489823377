import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";

export const PlaybarContainer = styled.div`
  width: 100%;
`;

export const Alert = styled.div`
  color: rgb(97, 26, 21);
  transform: none;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #fcced9;
  width: 400px;
  height: 50px;
  text-align: center;
  padding: 12px;
`;

export const AlertContainer = styled(Snackbar)`
  margin-bottom: 75px;
  height: 50px;
`;
