import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import TeamPage from "./components/pages/TeamPage/TeamPage";
import SignUp from "./components/pages/Signup/SignUp";
import ExplorePage from "./components/pages/ExplorePage/ExplorePage";
import LibraryPage from "./components/pages/LibraryPage/LibraryPage";
import CommunityPage from "./components/pages/CommunityPage/CommunityPage";
import PodcastPage from "./components/pages/PodcastPage/PodcastPage";
import { createGlobalStyle } from "styled-components";
import Login from "./components/pages/Login/Login";
import Profile from "./components/pages/Profile/Profile";
import SnippetPage from "./components/pages/SnippetPage/SnippetPage";
import {
  withStore,
  useStoreState,
  useGetAndSet,
  useSetStoreValue,
} from "react-context-hook";
import EpisodePage from "./components/pages/EpisodePage/EpisodePage";
import defaultProfile from "./assets/DefaultProfile.svg";
import getUserByToken from "./api/user/getUserByToken";
import getHistory from "./api/history/getHistory";
import { hotjar } from "react-hotjar";
import { Helmet } from "react-helmet";
import mixpanel from "mixpanel-browser";
import CacheBuster from "./CacheBuster";
import Playbar from "./components/shared_components/Playbar/Playbar";

const GlobalStyles = createGlobalStyle`
  @import url("../../../assets/fonts/Proxima Nova Bold.otf");
  @import url("../../../assets/fonts/ProximaNova-Regular.otf");
  body {
    font-family: 'Proxima Nova';
    font-style: bold;
    font-weight: 600;
  }
`;

function App() {
  const { currentTitle, currentSeries } = useStoreState();
  const setListened = useSetStoreValue("listened");
  const [loggedIn, setLoggedIn] = useGetAndSet("loggedIn");
  const setAttemptedLogin = useSetStoreValue("attemptedLogin");
  const [user, setUser] = useGetAndSet("user");

  mixpanel.init("4490658a0ff2c50a9b49c2f0fc4b9851");
  useEffect(() => {
    // get the user if they are in local storage and not loggedIn
    if (
      !loggedIn &&
      window.localStorage.getItem("token") !== null &&
      user.id === ""
    ) {
      getUserByToken(setUser).then((data) => {
        setAttemptedLogin(true);
        if (data.ID !== undefined) {
          setLoggedIn(true);
          getHistory(data.ID).then((data) => {
            if (data !== null && data.length >= 1) {
              setListened(true);
            }
          });
        }
      });
    }
  });

  // hook for hotjar analytics stuff
  useEffect(() => {
    hotjar.initialize("1786644", 6);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {currentTitle ? `${currentSeries} | ${currentTitle}` : "Castyr"}
        </title>
      </Helmet>
      <CacheBuster />
      <Router>
        <GlobalStyles />
        <Playbar />
        <Switch>
          <Route
            path="/podcasts/:podcastid/:page/:episodeid/:episode"
            render={(props) => {
              const page = props.match.params.page;
              const pid = props.match.params.podcastid;
              const eid = props.match.params.episodeid;
              return <EpisodePage page={page} pid={pid} eid={eid} />;
            }}
          ></Route>
          <Route
            path="/podcasts/:id/:page"
            render={(props) => {
              const page = props.match.params.page;
              const id = props.match.params.id;
              return <PodcastPage page={page} id={id} />;
            }}
          ></Route>
          <Route path="/explore">
            <ExplorePage />
          </Route>
          <Route path="/library">
            <LibraryPage />
          </Route>
          <Route path="/community">
            <CommunityPage />
          </Route>
          <Route path="/team">
            <TeamPage />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route
            path="/s/:id"
            render={(props) => {
              const id = props.match.params.id;
              return <SnippetPage id={id} />;
            }}
          ></Route>
          <Route
            path="/profile/:username"
            render={(props) => {
              const username = props.match.params.username;
              return <Profile username={username} />;
            }}
          ></Route>
          <Route
            path="/profile"
            render={(props) => {
              const neww = props.location.new ? props.location.new : false;
              return <Profile new={neww} />;
            }}
          ></Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

const initialState = {
  url: null,
  playing: false,
  played: 0,
  loaded: 0,
  currentEpisodeID: null,
  currentPodcastID: null,
  currentImage: null,
  currentTitle: null,
  currentSeries: null,
  currentStoppedAt: 0,
  loggedIn: false,
  attemptedLogin: false,
  isCreateSnippetState: false,
  user: {
    email: "",
    name: "",
    username: "",
    password: "",
    bio: "",
    podcasts: [],
    image: defaultProfile,
    token: "",
    id: "",
  },
  listened: false,
  // an array of all recently episodes
  recentlyListened: [],
  // DEMO
  count: 0,
};

const storeConfig = {
  listener: (state) => {
    // console.log("state changed", state);
  },
  logging: false, //process.env.NODE_ENV !== 'production'
};

export default withStore(App, initialState, storeConfig);
