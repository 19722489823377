import React, { useState, useEffect } from "react";
import * as s from "./StyledAudioBoardSnippet";
import { useStoreState } from "react-context-hook";
import getSnippet from "../../../../../../../api/snippets/getSnippet";
import getEpisode from "../../../../../../../api/podcasts/getEpisode";
import getPodcast from "../../../../../../../api/podcasts/getPodcast";
import ImageNotFound from "../../../../../../../assets/image_not_found.png";
import getSnippetComments from "../../../../../../../api/snippets/getSnippetComments";
import likeSnippet from "../../../../../../../api/snippets/likeSnippet";
import unlikeSnippet from "../../../../../../../api/snippets/unlikeSnippet";
import PlayIcon from "../../../../../../../assets/PlayIcon.svg";

export default function AudioBoardSnippet(props) {
  const { loggedIn, user } = useStoreState();
  const [snippet, setSnippet] = useState({});
  const [episode, setEpisode] = useState({});
  const [podcast, setPodcast] = useState({});
  const [comments, setComments] = useState([]);
  const [loaded1, setLoaded1] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [style, setStyle] = useState({ display: "none" });
  const handleFocus = (event) => event.target.select();
  const url = window.location.host + "/s/" + snippet.SnippetID;
  const [isLikeInArray, setIsLikeInArray] = React.useState(false);
  const [liked, setLiked] = React.useState(false);
  const likedToInt = liked ? 1 : 0;

  useEffect(() => {
    getSnippet(props.snippetID).then((response) => {
      setSnippet(response);
      getEpisode(response.EpisodeID).then((resp) => {
        setEpisode(resp);
        setLoaded1(true);
      });
      getPodcast(response.PodcastID).then((resp) => {
        setPodcast(resp);
        setLoaded2(true);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (snippet.Likes && user.id && snippet.Likes.includes(user.id)) {
      setLiked(true);
      setIsLikeInArray(snippet.Likes.includes(user.id));
    }
    if (snippet.SnippetID) {
      getSnippetComments(snippet.SnippetID).then((response) => {
        setComments(response);
        setLoaded3(true);
      });
    }
  }, [snippet, user.id]);

  let likeCount;
  if (isLikeInArray && liked) {
    likeCount = 0;
  } else if (isLikeInArray && !liked) {
    likeCount = -1;
  } else if (!isLikeInArray && liked) {
    likeCount = 1;
  } else if (!isLikeInArray && !liked) {
    likeCount = 0;
  }

  const handleLike = () => {
    if (liked) {
      unlikeSnippet(snippet.SnippetID, user.id);
      setLiked(false);
    } else {
      likeSnippet(snippet.SnippetID, user.id);
      setLiked(true);
    }
  };

  let thumbnail = "";

  if ("Podcast" in podcast) {
    thumbnail = episode.Img
      ? episode.Img
      : podcast.Podcast.Img
      ? podcast.Podcast.Img
      : ImageNotFound;
  } else {
    thumbnail = episode.Img ? episode.Img : ImageNotFound;
  }

  let likeHeart = liked ? (
    <s.LikedIcon onClick={handleLike} />
  ) : (
    <s.LikeIcon onClick={handleLike} />
  );

  if (!loggedIn) {
    likeHeart = (
      <s.SignUpLink to="/signup">
        <s.LikeIcon />
      </s.SignUpLink>
    );
  }

  if (!loaded1 && !loaded2 && !loaded3) {
    return <div></div>;
  }
  if (snippet.SnippetID === "") {
    return <div></div>;
  }
  return (
    <s.SnippetContainer>
      <s.ThumbnailContainer>
        <s.ThumbnailPlayContainer
          to={"/s/" + snippet.SnippetID}
          onMouseEnter={(e) => {
            setStyle({ display: "block" });
          }}
          onMouseLeave={(e) => {
            setStyle({ display: "none" });
          }}
        >
          <s.Thumbnail src={thumbnail} />
          <s.PlayIcon src={PlayIcon} style={style} />
        </s.ThumbnailPlayContainer>
      </s.ThumbnailContainer>
      <s.Info>
        <s.TitleContainer>
          <s.TitleLink to={"/s/" + snippet.SnippetID}>
            <s.Title>
              {snippet.SnippetTitle ? snippet.SnippetTitle : "Untitled"}
            </s.Title>
          </s.TitleLink>
          <s.EpisodeName
            to={
              "/podcasts/" +
              snippet.PodcastID +
              "/" +
              snippet.PodcastName +
              "/" +
              snippet.EpisodeID +
              "/" +
              snippet.EpisodeName
            }
          >
            {snippet.EpisodeName}
          </s.EpisodeName>
          <s.Description>
            {snippet.SnippetDescription
              ? snippet.SnippetDescription
              : "This snippet has no description"}
          </s.Description>
        </s.TitleContainer>
        <s.LikeContainer>
          {likeHeart}
          <s.LikeNumber>
            {snippet.Likes ? snippet.Likes.length + likeCount : likedToInt}
          </s.LikeNumber>
          <s.CommentsLink to={"/s/" + snippet.SnippetID}>
            <s.CommentIcon />
          </s.CommentsLink>
          <s.LikeNumber>{comments.length}</s.LikeNumber>
        </s.LikeContainer>
      </s.Info>
      <s.ShareLinkContainer>
        <s.ShareLinkText>Share Link</s.ShareLinkText>
        <s.ShareLinkPositioning>
          <s.LinkIcon />
          <s.ShareLink onFocus={handleFocus}>{url}</s.ShareLink>
        </s.ShareLinkPositioning>
      </s.ShareLinkContainer>
    </s.SnippetContainer>
  );
}
