import { API } from "../constants";
import axios from "axios";

/**
 * Checks whether a username exists
 *
 * @param username: the username to check if it exists
 */
const checkIfUsernameExists = async (username) => {
  let api = API + "/api/users/usernameexists?Username=" + username;
  return axios
    .get(api)
    .then((response) => {
      return response;
    })
    .then((data) => {
      console.log(data);
      return data.data;
    });
};

export default checkIfUsernameExists;
