import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";

import XIcon from "@material-ui/icons/Close";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/icons/Link";
import Button from "@material-ui/core/Button";

export const CardContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const CardContent = styled.div`
  width: 650px;
  background-color: white;
  padding: 50px;
  border-radius: 4px;
  @media (min-width: 1920px) {
    width: 866px;
    padding: 66px;
  }
  @media (min-width: 2560px) {
    width: 1155px;
    padding: 88px;
  }
  @media (min-width: 3840px) {
    width: 1733px;
    padding: 133px;
  }
`;

export const CardTitleRow = styled.div`
  font-size: 1.75rem;
  color: #2f80ed;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  @media (min-width: 1920px) {
    font-size: 2.33333333333rem;
    margin-bottom: 40px;
  }
  @media (min-width: 2560px) {
    font-size: 3.11111111111rem;
    margin-bottom: 53px;
  }
  @media (min-width: 3840px) {
    font-size: 4.66666666667rem;
    margin-bottom: 80px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 1.75rem;
  text-align: center;
  color: #2f80ed;
  @media (min-width: 1920px) {
    font-size: 2.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 3.11111111111rem;
  }
  @media (min-width: 3840px) {
    font-size: 4.66666666667rem;
  }
`;

export const FlexboxSpacing = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
`;

export const CloseButton = styled(IconButton)`
  && {
    margin-top: -15px;
    outline: none;
  }
  @media (min-width: 1920px) {
    && {
      margin-top: -20px;
    }
  }
  @media (min-width: 2560px) {
    && {
      margin-top: -26px;
    }
  }
  @media (min-width: 3840px) {
    && {
      margin-top: -40px;
    }
  }
`;

export const CloseIcon = styled(XIcon)`
  && {
    fill: #2f80ed;
    width: 36px;
    height: 36px;
  }
  @media (min-width: 1920px) {
    && {
      width: 48px;
      height: 48px;
    }
  }
  @media (min-width: 2560px) {
    && {
      width: 64px;
      height: 64px;
    }
  }
  @media (min-width: 3840px) {
    width: 96px;
    height: 96px;
    }
  }
`;

export const CardContentRow = styled.div`
  width: 650px;
  background-color: white;
  height: 350px;
  padding: 50px;
  border-radius: 4px;
  @media (min-width: 1920px) {
    width: 866px;
    height: 466px;
    padding: 66px;
  }
  @media (min-width: 2560px) {
    width: 1155px;
    height: 622px;
    padding: 88px;
  }
  @media (min-width: 3840px) {
    width: 1733px;
    height: 933px;
    padding: 133px;
  }
`;
export const CardInfoContainer = styled.div`
  display: flex;
  column-alignment: row;
`;

export const SnippetThumbnailContainer = styled.div`
  width: 40%;
  margin-right: 35px;
  @media (min-width: 1920px) {
    margin-right: 46px;
  }
  @media (min-width: 2560px) {
    margin-right: 62px;
  }
  @media (min-width: 3840px) {
    margin-right: 93px;
  }
`;

export const SnippetThumbnail = styled.img`
  border-radius: 8px;
  width: 100%;
`;

export const SnippetInfoContainer = styled.div`
  width: 60%;
`;

export const ShareLinkRow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 1.5rem;
  @media (min-width: 1920px) {
    margin-bottom: 2rem;
  }
  @media (min-width: 2560px) {
    margin-bottom: 2.666666666rem;
  }
  @media (min-width: 3840px) {
    margin-bottom: 4rem;
  }
`;

// 32 36 40
export const ShareLink = styled.input`
  background: #f2f2f2;
  padding: 4px;
  padding-top: 6px;
  color: #4f4f4f;
  padding-left: 30px;
  border-radius: 3px;
  outline: none;
  appearance: none;
  width: 100%;
  border: none;
  font-size: 0.875rem;
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
    padding-left: 40px;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
    padding-left: 52px;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
    padding-left: 76px;
  }
`;

export const ShareLinkTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: bold;
  margin: 0;
  color: #717171;
  margin-bottom: 0.25rem;
  @media (min-width: 1920px) {
    font-size: 1.5rem;
    margin-bottom: 0.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
    margin-bottom: 0.44444444444rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
    margin-bottom: 0.66666666666rem;
  }
`;

export const InputRow = styled.div`
  margin-bottom: calc(1.5rem - 6px);
  @media (min-width: 1920px) {
    margin-bottom: calc(2rem - 6px);
  }
  @media (min-width: 2560px) {
    margin-bottom: calc(2.666666666rem - 8px);
  }
  @media (min-width: 3840px) {
    margin-bottom: calc(4rem - 14px);
  }
`;

export const InputRowTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: bold;
  margin: 0;
  color: #4f4f4f;
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const TextInput = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    borderRadius: 2,
    position: "relative",
    backgroundColor: "#fff",
    fontSize: "0.875rem",
    fontFamily: "Proxima Nova",
    color: "#828282",
    opacity: 1,
    width: "100%",
    border: "1px solid #fff",
    padding: "4px 6px 4px 6px",
    marginLeft: -6,
    transition: theme.transitions.create(["border-color"]),
    "&::placeholder": {
      color: "#828282",
      opacity: 1,
    },
    "&:focus": {
      borderColor: "#2F80ED",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      padding: "6px 8px 6px 8px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      padding: "12px 14px 12px 14px",
      border: "2px solid #fff",
    },
  },
}))(InputBase);

export const LinkIcon = styled(Link)`
  position: absolute;
  transform: rotate(-40deg);
  margin-top: 4px;
  margin-left: 6px;
  && {
    font-size: 1.375rem;
  }
  @media (min-width: 1920px) {
    margin-top: 6px;

    && {
      font-size: 1.75rem;
    }
  }
  @media (min-width: 2560px) {
    margin-left: 10px;

    && {
      font-size: 2.125rem;
    }
  }
  @media (min-width: 3840px) {
    margin-top: 10px;
    margin-left: 16px;

    && {
      font-size: 3rem;
    }
  }
`;

export const ShareLinkPositioning = styled.div`
  position: relative;
`;

export const CopyButton = withStyles((theme) => ({
  root: {
    background: "#2F80ED",
    color: "white",
    position: "absolute",
    textTransform: "none",
    textDecoration: "none",
    borderRadius: 2,
    minWidth: 60,
    padding: "4px 0px",
    right: 0,
    fontSize: "0.875rem",
    "&:hover": {
      filter: "brightness(90%)",
      background: "#2F80ED",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      minWidth: 80,
      borderRadius: 3,
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      minWidth: 106,
      borderRadius: 4,
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      borderRadius: 6,
      minWidth: 160,
    },
  },
}))(Button);

export const SaveStatus = styled.p`
  color: #828282;
  font-size: 0.75rem;
  font-family: "Proxima Nova";
  font-weight: normal;

  display: flex;
  justify-content: flex-end;
  white-space: break-spaces;
  line-height: 16px;

  @media (min-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 3840px) {
    font-size: 2rem;
  }
`;

export const Checkmark = styled.span`
  font-size: 1rem;
  @media (min-width: 1920px) {
    font-size: 1.33333333333rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.77777777778rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.66666666667rem;
  }
`;
