import React, { useState, useEffect } from "react";
import * as s from "./StyledSnippetCommunityItem";
import getEpisode from "../../../../api/podcasts/getEpisode";
import getPodcast from "../../../../api/podcasts/getPodcast";
import getUser from "../../../../api/user/getUser";
import likeSnippet from "../../../../api/snippets/likeSnippet";
import unlikeSnippet from "../../../../api/snippets/unlikeSnippet";
import ImageNotFound from "../../../../assets/image_not_found.png";
import defaultProfile from "../../../../assets/DefaultProfile.svg";
import getSnippetComments from "../../../../api/snippets/getSnippetComments";
import { useStoreState } from "react-context-hook";
import PlayIcon from "../../../../assets/PlayIcon.svg";

export default function SnippetCommunityItem(props) {
  const snippet = props.snippet;
  const [episode, setEpisode] = useState({});
  const [podcast, setPodcast] = useState({});
  const [comments, setComments] = useState([]);
  const { loggedIn, user } = useStoreState();
  const handleFocus = (event) => event.target.select();
  const [episodeLoaded, setEpisodeLoaded] = useState(false);
  const [podcastLoaded, setPodcastLoaded] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [snippetUser, setSnippetUser] = React.useState({});
  const [liked, setLiked] = React.useState(false);
  const [isLikeInArray, setIsLikeInArray] = React.useState(false);
  const [style, setStyle] = useState({ display: "none" });

  useEffect(() => {
    getEpisode(snippet.EpisodeID).then((response) => {
      setEpisode(response);
      setEpisodeLoaded(true);
    });
    getPodcast(snippet.PodcastID).then((response) => {
      setPodcast(response);
      setPodcastLoaded(true);
    });
    getUser(snippet.UserID).then((response) => {
      setSnippetUser(response);
      setUserLoaded(true);
    });
    getSnippetComments(snippet.SnippetID).then((response) => {
      setComments(response);
      setCommentsLoaded(true);
    });
  }, [snippet]);

  useEffect(() => {
    if (snippet.Likes && user.id && snippet.Likes.includes(user.id)) {
      setLiked(true);
      setIsLikeInArray(snippet.Likes.includes(user.id));
    }
  }, [snippet, user.id]);

  if (Object.keys(snippet).length === 0) {
    return <div></div>;
  }
  const url = window.location.host + "/s/" + snippet.SnippetID;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleLike = () => {
    if (liked) {
      unlikeSnippet(snippet.SnippetID, user.id);
      setLiked(false);
    } else {
      likeSnippet(snippet.SnippetID, user.id);
      setLiked(true);
    }
  };

  let likeHeart = liked ? (
    <s.LikedIcon onClick={handleLike} />
  ) : (
    <s.LikeIcon onClick={handleLike} />
  );

  if (!loggedIn) {
    likeHeart = (
      <s.SignUpLink to="/signup">
        <s.LikeIcon />
      </s.SignUpLink>
    );
  }

  let thumbnail = "";

  if ("Podcast" in podcast) {
    thumbnail = episode.Img
      ? episode.Img
      : podcast.Podcast.Img
      ? podcast.Podcast.Img
      : ImageNotFound;
  } else {
    thumbnail = episode.Img ? episode.Img : ImageNotFound;
  }

  let likeCount;
  if (isLikeInArray && liked) {
    likeCount = 0;
  } else if (isLikeInArray && !liked) {
    likeCount = -1;
  } else if (!isLikeInArray && liked) {
    likeCount = 1;
  } else if (!isLikeInArray && !liked) {
    likeCount = 0;
  }

  if (
    props.shouldRefetchComments &&
    snippet.SnippetID === props.activeSnippetID
  ) {
    getSnippetComments(snippet.SnippetID).then((response) => {
      setComments(response);
      props.setShouldRefetchComments(false);
      props.loadComments(snippet.SnippetID, snippet.SnippetTitle, response);
    });
  }

  if (!userLoaded && !episodeLoaded && !podcastLoaded && !commentsLoaded) {
    return <div></div>;
  }

  return (
    <s.Container>
      <s.AuthorRow>
        <s.AuthorLink to={"/profile/" + snippetUser.Username}>
          <s.AuthorPic
            src={snippetUser.Img ? snippetUser.Img : defaultProfile}
          ></s.AuthorPic>
        </s.AuthorLink>
        <s.SnippedBy>
          Snipped by{" "}
          <s.AuthorLink to={"/profile/" + snippetUser.Username}>
            <s.AuthorText>
              {"@" +
                (snippetUser.Username ? snippetUser.Username : "anonymous")}
            </s.AuthorText>
          </s.AuthorLink>
        </s.SnippedBy>
      </s.AuthorRow>

      <s.SnippetContainer>
        <s.ThumbnailContainer>
          <s.ThumbnailPlayContainer
            to={"/s/" + snippet.SnippetID}
            onMouseEnter={(e) => {
              setStyle({ display: "block" });
            }}
            onMouseLeave={(e) => {
              setStyle({ display: "none" });
            }}
          >
            <s.Thumbnail src={thumbnail} />
            <s.PlayIconn src={PlayIcon} style={style} />
          </s.ThumbnailPlayContainer>

          <s.LikeContainer>
            <s.CommentIcon
              onClick={() => {
                if (props.activeSnippetID !== snippet.SnippetID && props.open) {
                  props.loadComments(
                    snippet.SnippetID,
                    snippet.SnippetTitle,
                    comments
                  );
                }
                if (!props.open) {
                  props.loadComments(
                    snippet.SnippetID,
                    snippet.SnippetTitle,
                    comments
                  );
                }
                if (props.activeSnippetID === snippet.SnippetID) {
                  props.setActiveSnippetID("");
                  props.setOpen(false);
                } else {
                  props.setOpen(true);
                }
              }}
            />
            <s.LikeNumber>{comments.length}</s.LikeNumber>
            {likeHeart}
            <s.LikeNumber>{snippet.Likes.length + likeCount}</s.LikeNumber>
          </s.LikeContainer>

          <s.MobileShareContainer>
            <s.ShareText>Share</s.ShareText>
            <div>
              <s.IconButtonn onClick={handleClickOpen}>
                <s.ShareIconn />
              </s.IconButtonn>
            </div>
          </s.MobileShareContainer>
        </s.ThumbnailContainer>
        <s.Info>
          <s.Title to={"/s/" + snippet.SnippetID}>
            {snippet.SnippetTitle ? snippet.SnippetTitle : "Untitled"}
          </s.Title>
          <s.PodcastName
            to={"/podcasts/" + snippet.PodcastID + "/" + snippet.PodcastName}
          >
            {snippet.PodcastName}
          </s.PodcastName>
          <s.EpisodeName
            to={
              "/podcasts/" +
              snippet.PodcastID +
              "/" +
              snippet.PodcastName +
              "/" +
              snippet.EpisodeID +
              "/" +
              snippet.EpisodeName
            }
          >
            {snippet.EpisodeName}
          </s.EpisodeName>
          <s.Description>
            {snippet.SnippetDescription
              ? snippet.SnippetDescription
              : "This snippet has no description"}
          </s.Description>
        </s.Info>

        <s.ShareContainer>
          <s.ShareText>Share</s.ShareText>
          <div>
            <s.IconButtonn onClick={handleClickOpen}>
              <s.ShareIconn />
            </s.IconButtonn>
          </div>
        </s.ShareContainer>

        <s.Dialogg onClose={handleClose} open={open}>
          <s.DialogTitlee>Share</s.DialogTitlee>
          <s.DialogContentt>
            <s.DialogContentTextt>
              {" "}
              <s.ShareLinkPositioning>
                <s.LinkIcon />
                <s.ShareLink onFocus={handleFocus}>{url}</s.ShareLink>
              </s.ShareLinkPositioning>
            </s.DialogContentTextt>
          </s.DialogContentt>
          <s.DialogActionss>
            <s.Buttonn onClick={handleClose}>Close</s.Buttonn>
          </s.DialogActionss>
        </s.Dialogg>
      </s.SnippetContainer>
    </s.Container>
  );
}
