import React from "react";
import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

export const Container = styled.div`
  display: flex;
`;

export const LinkItem = styled.a`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  white-space: nowrap;
  cursor: pointer;

  color: ${(props) => (props.active ? "#2F80ED" : "#BDBDBD")};
  :hover {
    text-decoration: none;
    color: ${(props) => (props.active ? "#2F80ED" : "#BDBDBD")};
  }

  @media (min-width: 992px) {
    font-size: 1.125rem;
    margin-right: 80px;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const DisabledLinkItem = styled.p`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  white-space: nowrap;
  color: #bdbdbd;
  cursor: pointer;

  @media (min-width: 992px) {
    font-size: 1.125rem;
    margin-right: 80px;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const Tabss = withStyles(() => ({
  root: {
    "@media (min-width: 1920px)": {},
    "@media (min-width: 2560px)": {},
    "@media (min-width: 3840px)": {},
  },
  indicator: {
    color: "transparent",
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
}))(Tabs);

export const Tabb = withStyles(() => ({
  root: {
    letterSpacing: "revert",
    opacity: "1",
    fontFamily: "Proxima Nova",
    fontWeight: "600",
    padding: 0,

    "@media (min-width: 576px)": {
      fontSize: "1rem",
      marginRight: 20,
      "&&": {
        minWidth: "min-content",
      },
    },
    "@media (min-width: 768px)": {
      marginRight: 40,
    },
    "@media (min-width: 992px)": {
      fontSize: "1.125rem",
      marginRight: 60,
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.5rem",
      marginRight: 80,
    },
    "@media (min-width: 2560px)": {
      fontSize: "2rem",
      marginRight: 100,
    },
    "@media (min-width: 3840px)": {
      fontSize: "3rem",
      marginRight: 140,
    },
  },
  selected: {
    "&&": {
      color: "#0071E2",
    },
  },
  wrapper: {
    "@media (min-width: 576px)": {
      justifyContent: "flex-start",
      flexDirection: "row",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const LongTabb = withStyles(() => ({
  root: {
    letterSpacing: "revert",
    opacity: "1",
    fontFamily: "Proxima Nova",
    fontWeight: "600",
    overflowWrap: "anywhere",
    padding: 0,

    "@media (min-width: 576px)": {
      fontSize: "1rem",
      marginRight: 20,
      "&&": {
        minWidth: "min-content",
      },
    },
    "@media (min-width: 768px)": {
      marginRight: 40,
    },
    "@media (min-width: 992px)": {
      fontSize: "1.125rem",
      overflowWrap: "initial",
      marginRight: 60,
    },
    "@media (min-width: 1440px)": {},
    "@media (min-width: 1920px)": {
      fontSize: "1.5rem",
      marginRight: 80,
    },
    "@media (min-width: 2560px)": {
      fontSize: "2rem",
      marginRight: 100,
    },
    "@media (min-width: 3840px)": {
      fontSize: "3rem",
      marginRight: 140,
    },
  },
  selected: {
    "&&": {
      color: "#0071E2",
    },
  },
  wrapper: {
    "@media (min-width: 576px)": {
      justifyContent: "flex-start",
      flexDirection: "row",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const NoShowTab = withStyles(() => ({
  root: {
    display: "none",
  },
}))((props) => <Tab disableRipple {...props} />);

export const Icon = styled.img`
  width: 14px;
  height: 14px;
  @media (min-width: 576px) {
    margin-right: 4px;
  }
  @media (min-width: 768px) {
    margin-right: 8px;
  }
  @media (min-width: 992px) {
    width: 18px;
    height: 18px;
  }
  @media (min-width: 1920px) {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  @media (min-width: 2560px) {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
  @media (min-width: 3840px) {
    width: 48px;
    height: 48px;
    margin-right: 24px;
  }
`;

export const SmallIcon = styled.img`
  width: 18px;
  height: 18px;
  @media (min-width: 576px) {
    margin-right: 4px;
  }
  @media (min-width: 768px) {
    margin-right: 8px;
  }
  @media (min-width: 992px) {
    width: 22px;
    height: 22px;
  }
  @media (min-width: 1920px) {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }
  @media (min-width: 2560px) {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
  @media (min-width: 3840px) {
    width: 58px;
    height: 58px;
    margin-right: 24px;
  }
`;
