import React, { useState, useEffect, useRef } from "react";
import * as s from "./StyledLogin";
import { Redirect } from "react-router-dom";
import Header from "../../shared_components/Header/Header";
import signIn from "../../../api/user/signIn";
import { useGetAndSet } from "react-context-hook";
import getCurrentUser from "../../../api/user/getCurrentUser";
// import GoogleLogin from "react-google-login";
// import oauthUser from "../../../api/user/oauthUser";
// import { ReactComponent as GIcon } from "../../../assets/google_logo.svg";

const Login = () => {
  const [loggedIn, setLoggedIn] = useGetAndSet("loggedIn");
  const [user, setUser] = useGetAndSet("user");
  const [errorHistory, setErrorHistory] = useState(false);
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [playing, setPlaying] = useGetAndSet("playing");

  const handleChange = async (u, p) => {
    signIn(u.trim(), p).then((response) => {
      if (!response) {
        setError(true);
        return;
      }
      if (response.Logged === true) {
        // log in the person
        getCurrentUser(response.Token, setUser);
        if (loggedIn === false) {
          setLoggedIn(true);
        }
      } else {
        setError(true);
      }
    });
  };

  // const responseGoogle = (response) => {
  //   console.log(response);
  //   console.log(response.profileObj.name.replace(/\s/g, "").toLowerCase());
  //   const email = response.profileObj.email;
  //   const username = response.profileObj.name.replace(/\s/g, "").toLowerCase();
  //   const name = response.profileObj.name;
  //   const imageUrl = response.profileObj.imageUrl;
  //   const image = imageUrl.slice(0, -5).concat("s400-c");
  //   oauthUser(username, name, email, image).then((response) => {
  //     console.log(response);
  //     if (response && response.Logged === true) {
  //       // log in the person
  //       getCurrentUser(response.Token, setUser);
  //       if (loggedIn === false) {
  //         setLoggedIn(true);
  //       }
  //     } else {
  //       setError(true);
  //     }
  //   });
  // };

  useEffect(() => {
    if (playing) {
      console.log(playing);
      setPlaying(false);
    }
    if (error) {
      setUser({ ...user, username: "", password: "" });
      setPassword("");
      setError(false);
      setErrorHistory(true);
    }
  }, [error, playing, setPlaying, setUser, user]);

  const usernameRef = useRef(username);
  usernameRef.current = username;
  const passwordRef = useRef(password);
  passwordRef.current = password;

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleChange(usernameRef.current, passwordRef.current);
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <s.Container>
      <Header active_link="login" />
      <s.LoginContainer>
        <s.WelcomeText>Welcome back! We missed you.</s.WelcomeText>
        <s.InputContainer>
          <s.Inputs
            placeholder="Username"
            value={username}
            onInput={(e) => setUsername(e.target.value)}
          ></s.Inputs>
          <s.Inputs
            placeholder="Password"
            type="Password"
            value={password}
            onInput={(e) => setPassword(e.target.value)}
          ></s.Inputs>
          {errorHistory && (
            <s.Error>
              Incorrect username or password. Please note that inputs are
              case-sensitive.{" "}
            </s.Error>
          )}
        </s.InputContainer>
        <s.LoginButton
          to="/profile"
          onClick={() => handleChange(username, password)}
        >
          Log In
        </s.LoginButton>
        {/* <GoogleLogin
          clientId="972338854953-3bbcln0hp5kt3r8fdcdhjku3ogha8b10.apps.googleusercontent.com"
          render={(renderProps) => (
            <s.GoogleButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <s.GoogleIcon>
                <GIcon style={{ width: "20%", height: "20%" }} />
                Log In
              </s.GoogleIcon>
            </s.GoogleButton>
          )}
          buttonText="Log In"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        /> */}
        <s.BottomText>
          Don't have an account?{" "}
          <s.StyledLink to="/signup">Sign up here.</s.StyledLink>
        </s.BottomText>
        {loggedIn && <Redirect to="/explore" />}
      </s.LoginContainer>
    </s.Container>
  );
};
export default Login;
