import React from "react";
import * as s from "./StyledHeader";
import CastyrLogo from "../../../assets/castyr_logo.png";
import DefaultProfile from "../../../assets/DefaultProfile.svg";
import { useStoreState } from "react-context-hook";
import { useHistory } from "react-router-dom";

export default function Header(props) {
  const { loggedIn, user } = useStoreState();
  const logoLink = loggedIn ? "/explore" : "/";
  const history = useHistory();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const isLandingPage = props.isLandingPage ? props.isLandingPage : false;
  const scrollbarWidth = -1 * (window.innerWidth - document.body.clientWidth);

  const header = (
    <s.Header disableGutters={true}>
      <s.Logo onClick={() => history.push(logoLink)} src={CastyrLogo} />
      {loggedIn ? (
        <s.LoginContainer>
          <s.Notifs />
          <s.ProfileImage
            onClick={handleMobileMenuOpen}
            src={user.image ? user.image : DefaultProfile}
          ></s.ProfileImage>
          <s.Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            disableScrollLock={true}
          >
            <s.MenuItem onClick={() => history.push("/profile")}>
              Profile
            </s.MenuItem>
            <s.MenuItem onClick={() => history.push("/library")}>
              Library
            </s.MenuItem>
            <s.MenuItem onClick={() => history.push("/explore")}>
              Listen Now
            </s.MenuItem>
            <s.MenuItem onClick={() => history.push("/community")}>
              Community
            </s.MenuItem>
          </s.Menu>
        </s.LoginContainer>
      ) : (
        <s.RightSideContainer>
          <s.RightSide
            style={isLandingPage ? { marginRight: scrollbarWidth } : {}}
          >
            <s.LinkItem onClick={() => history.push("/explore")}>
              Listen Now
            </s.LinkItem>
            <s.LinkItem onClick={() => history.push("/login")}>
              Log In
            </s.LinkItem>
            <s.LinkItem onClick={() => history.push("/signup")}>
              Sign Up
            </s.LinkItem>
          </s.RightSide>

          <s.IconButton onClick={handleMobileMenuOpen}>
            <s.MoreIcon />
          </s.IconButton>

          <s.Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            disableScrollLock={true}
          >
            <s.MenuItem onClick={() => history.push("/explore")}>
              Listen Now
            </s.MenuItem>
            <s.MenuItem onClick={() => history.push("/community")}>
              Community
            </s.MenuItem>
            <s.MenuItem onClick={() => history.push("/signup")}>
              Sign Up
            </s.MenuItem>
            <s.MenuItem onClick={() => history.push("/login")}>
              Log In
            </s.MenuItem>
          </s.Menu>
        </s.RightSideContainer>
      )}
    </s.Header>
  );
  return isLandingPage ? (
    <s.LandingHeaderContainer>{header}</s.LandingHeaderContainer>
  ) : (
    <s.HeaderContainer>{header}</s.HeaderContainer>
  );
}
