import axios from "axios";
import { API } from "../constants";

/**
 * Get comments for an episodee
 *
 * @param episodeID: the episode ID
 */
const getEpisodeComment = async (episodeID) => {
  let api = API + "/api/episode/comments?EpisodeID=" + episodeID;
  return axios.get(api).then((data) => {
    return data.data;
  });
};

export default getEpisodeComment;
