import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ProximaNova from "../../../../assets/fonts/ProximaNova-Regular.otf";

export const PodcastInfoContainer = styled.div`
  margin-top: 4vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
    margin-left: 5.13888889vw;
    width: 65vw;
    margin-top: 8vh;
    flex-wrap: wrap;
  }
`;

export const PodcastImageContainer = styled.div`
  text-align: center;
`;

export const PodcastImage = styled.img`
  border-radius: 5px;
  height: 208px;
  width: 208px;
  @media (min-width: 992px) {
    border-radius: 10px;
  }
  @media (min-width: 1920px) {
    height: 278px;
    width: 278px;
  }
  @media (min-width: 2560px) {
    height: 370px;
    width: 370px;
  }
  @media (min-width: 3840px) {
    height: 554px;
    width: 554px;
  }
`;

export const PodcastAuthorLink = styled(Link)`
  font-family: "Proxima Nova";
  font-size: 1.125rem;
  color: #828282;
  @media (min-width: 992px) {
    display: block;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
  text-decoration: none;
  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const PodcastTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  @media (min-width: 992px) {
    width: 60%;
    margin-left: 32px;
  }
  @media (min-width: 1920px) {
    margin-left: 48px;
  }
  @media (min-width: 2560px) {
    margin-left: 64px;
  }
  @media (min-width: 3840px) {
    margin-left: 96px;
  }
`;

export const PodcastInfoName = styled.div`
  color: #2f80ed;
  font-size: 1.125rem;
  font-family: "Proxima Nova Lt";
  margin-top: 2.216748768vh;
  text-align: center;
  @media (min-width: 992px) {
    margin-top: 0;
    text-align: start;
    font-size: 1.5rem;
    font-family: "Proxima Nova";
  }
  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const PodcastInfoDescription = styled.div`
  font-family: Proxima Nova Lt;
  font-weight: 400;
  color: #828282;
  text-align: start;
  font-size: 0.75rem;
  margin-top: 1.477832512vh;
  @media (min-width: 992px) {
    @font-face {
      font-family: "Proxima Nova";
      src: url(${ProximaNova}) format("woff2");
    }
    font-family: "Proxima Nova";
    margin-top: 0.738916256vh;
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const PodcastInfoAbout = styled.div`
  display: none;
  color: #262626;
  font-family: "Proxima Nova";
  font-size: 1.125rem;
  margin-top: 12px;

  @media (min-width: 992px) {
    display: block;
    color: #4f4f4f;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const PodcastName = styled.div``;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubscribeButton = withStyles((theme) => ({
  root: {
    color: "#0071E2",
    outline: "none",
    backgroundColor: "#fff",
    textTransform: "none",
    borderRadius: "30px",
    border: "1px solid #0071E2",
    width: "110px",
    minWidth: "110px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "2.216748768vh",
    marginBottom: "8px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#ebf5ff",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 992px)": {
      marginTop: "0",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "150px",
      minWidth: "150px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "200px",
      minWidth: "200px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
      border: "2px solid #0071E2",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "300px",
      minWidth: "300px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
}))(Button);

export const UnsubscribeButton = withStyles((theme) => ({
  root: {
    color: "white",
    outline: "none",
    backgroundColor: "#0071E2",
    textTransform: "none",
    borderRadius: "30px",
    width: "110px",
    minWidth: "110px",
    height: "30px",
    minHeight: "30px",
    fontFamily: "Proxima Nova",
    fontSize: ".875rem",
    marginTop: "2.216748768vh",
    marginBottom: "8px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#007ac9",
    },
    "&:active": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (min-width: 992px)": {
      marginTop: "0",
    },
    "@media (min-width: 1920px)": {
      fontSize: "1.16666666667rem",
      width: "150px",
      minWidth: "150px",
      height: "40px",
      minHeight: "40px",
    },
    "@media (min-width: 2560px)": {
      fontSize: "1.55555555556rem",
      width: "200px",
      minWidth: "200px",
      height: "50px",
      minHeight: "50px",
      marginBottom: "12px",
    },
    "@media (min-width: 3840px)": {
      fontSize: "2.33333333333rem",
      width: "300px",
      minWidth: "300px",
      height: "70px",
      minHeight: "70px",
      borderRadius: "50px",
      marginBottom: "24px",
    },
  },
}))(Button);

export const PodcastLatestEpisodes = styled.div`
  color: #2f80ed;
  font-size: 1.125rem;
  font-family: "Proxima Nova Lt";
  margin-top: 2.216748768vh;
  text-align: start;
  margin-bottom: 12px;
  @media (min-width: 992px) {
    margin-top: 0;
    text-align: start;
    font-size: 1.5rem;
    font-family: "Proxima Nova";
    margin-top: 24px;
    flex-basis: 100%;
  }
  @media (min-width: 1920px) {
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 36px;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
    margin-bottom: 24px;
    margin-top: 40px;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
    margin-bottom: 28px;
    margin-top: 52px;
  }
`;
