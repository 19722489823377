import axios from "axios";
import { API } from "../constants";
/**
 * Function to retrieve audioboards array based on user id
 *
 * @param id - user id whose audioboards to retrieve
 */
const getAudioBoards = async (id) => {
  let api = API + "/api/audioboard/user?UserID=" + id;

  return axios.get(api).then((response) => {
    return response.data;
  });
};

export default getAudioBoards;
