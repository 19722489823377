import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="footer-container">
        <div className="footer-text">
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://discord.gg/4gvSmPrKH9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>{" "}
          |{" "}
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://linktr.ee/castyr"
            target="_blank"
            rel="noopener noreferrer"
          >
            Socials
          </a>{" "}
          |{" "}
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.notion.so/caitlinpintavorn/Castyr-Changelog-6961fddc669d432bb656c0096d09a0c2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Changelog
          </a>{" "}
          |{" "}
          <Link style={{ textDecoration: "none", color: "white" }} to="/team">
            Team
          </Link>
        </div>
        <div
          className="footer-text"
          style={{
            marginTop: "-15px",
          }}
        >
          Made with{" "}
          <span role="img" aria-label="avocado">
            🥑
          </span>{" "}
          in cyberspace.
        </div>
      </div>
    );
  }
}

export default Footer;
