import styled from "styled-components";
import { Link } from "react-router-dom";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";
import ProximaNova from "../../../../../assets/fonts/ProximaNova-Regular.otf";

export const EpisodeContainer = styled.div`
  margin-bottom: 24px;
  @media (min-width: 1920px) {
    margin-bottom: 40px;
  }
  @media (min-width: 3840px) {
    margin-bottom: 80px;
  }
`;

export const EpisodeHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 4vh;
  width: 208px;
  @media (min-width: 992px) {
    flex-direction: row;
    margin-left: 5.13888889vw;
    width: 65vw;
    margin-top: 8vh;
  }
`;

export const EpisodeHeaderInfo = styled.div`
  @media (min-width: 992px) {
    margin-left: 32px;
    width: 60%;
  }
  @media (min-width: 1920px) {
    margin-left: 48px;
  }
  @media (min-width: 2560px) {
    margin-left: 64px;
  }
  @media (min-width: 3840px) {
    margin-left: 96px;
  }
`;

export const EpisodeThumbnail = styled.img`
  outline: none;
  border-radius: 5px;
  height: 208px;
  width: 208px;
  @media (min-width: 992px) {
    border-radius: 10px;
  }
  @media (min-width: 1920px) {
    height: 278px;
    width: 278px;
  }
  @media (min-width: 2560px) {
    height: 370px;
    width: 370px;
  }
  @media (min-width: 3840px) {
    height: 554px;
    width: 554px;
  }
`;

export const EpisodeThumbnailContainer = styled.div`
  text-align: center;
  position: relative;
`;

export const PodcastName = styled(Link)`
  color: #2f80ed;
  font-size: 0.75rem;
  font-family: "Proxima Nova Lt";
  margin-top: 2.216748768vh;
  @media (min-width: 992px) {
    margin-top: 0;
    font-size: 0.875rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const EpisodeName = styled.div`
  color: #262626;
  font-size: 1.125rem;
  font-family: "Proxima Nova Lt";
  text-align: center;
  margin-top: 8px;

  @media (min-width: 992px) {
    text-align: start;
    font-size: 1.5rem;
    font-family: "Proxima Nova";
    color: #4f4f4f;
  }
  @media (min-width: 1920px) {
    font-size: 2rem;
  }
  @media (min-width: 2560px) {
    font-size: 2.66666666667rem;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
  }
`;

export const EpisodeMobileDescription = styled.div`
  font-family: Proxima Nova Lt;
  font-weight: 400;
  color: #828282;
  text-align: start;
  font-size: 0.75rem;
  margin-top: 1.477832512vh;
  @media (min-width: 576px) {
    width: 70%;
    margin: 0 auto;
    margin-top: 1.477832512vh;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

export const EpisodeDesktopDescription = styled.div`
  display: none;
  color: #828282;
  text-align: start;
  font-weight: 400;
  @font-face {
    font-family: "Proxima Nova";
    src: url(${ProximaNova}) format("woff2");
  }
  margin-top: 0.738916256vh;
  font-size: 0.875rem;

  @media (min-width: 992px) {
    display: block;
  }
  @media (min-width: 1920px) {
    font-size: 1.16666666667rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.55555555556rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.33333333333rem;
  }
`;

export const EpisodeFavoriteIcon = styled(FavoriteOutlinedIcon)`
  && {
    width: 30px;
    height: 30px;
    fill: #2f80ed;
    cursor: pointer;
    @media (min-width: 992px) {
      width: 36px;
      height: 36px;
    }
    @media (min-width: 2560px) {
      width: 52px;
      height: 52px;
    }
    @media (min-width: 3840px) {
      width: 80px;
      height: 80px;
    }
  }
`;

export const EpisodeFavoriteOutlineIcon = styled(FavoriteBorderOutlinedIcon)`
  && {
    width: 30px;
    height: 30px;
    fill: #2f80ed;
    @media (min-width: 992px) {
      width: 36px;
      height: 36px;
    }
    @media (min-width: 2560px) {
      width: 52px;
      height: 52px;
    }
    @media (min-width: 3840px) {
      width: 80px;
      height: 80px;
    }
  }
`;

export const HeartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;

  @media (min-width: 2560px) {
    margin-top: 1rem;
  }
`;

export const DesktopLikeContainer = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`;

export const MobileLikeContainer = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;

export const LikeText = styled.p`
  margin: 0;
  margin-left: 8px;
  color: #262626;
  @media (min-width: 1920px) {
    font-size: 1.25rem;
  }
  @media (min-width: 2560px) {
    font-size: 1.5rem;
  }
  @media (min-width: 3840px) {
    font-size: 2.25rem;
    margin-left: 16px;
  }
`;

export const SignUpLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const EpisodeDescription = styled.div`
  display: none;
  color: #262626;
  font-family: "Proxima Nova";
  font-size: 1.125rem;
  margin-top: 12px;

  @media (min-width: 992px) {
    display: block;
    color: #4f4f4f;
  }
  @media (min-width: 1920px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px) {
    font-size: 2rem;
  }
  @media (min-width: 3840px) {
    font-size: 3rem;
  }
`;

export const LikedCount = styled.p`
  font-family: "Proxima Nova Lt";
  color: #2f80ed;
  font-size: 1.75rem;
  margin: 0;
  margin-left: 0.25rem;
  max-width: 160px;

  @media (min-width: 2560px) {
    font-size: 2.5rem;
    margin-left: 0.5rem;
    max-width: 300px;
  }
  @media (min-width: 3840px) {
    font-size: 4rem;
    margin-left: 0.75rem;
    max-width: 425px;
  }
`;

export const DesktopComments = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`;

export const MobileComments = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;

export const PauseIcon = styled.img`
  && {
    height: 208px;
    width: 208px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
  }
  @media (min-width: 1920px) {
    && {
      height: 278px;
      width: 278px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 370px;
      width: 370px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 554px;
      width: 554px;
    }
  }
`;
export const PlayIcon = styled.img`
  && {
    height: 208px;
    width: 208px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    transform: scale(0.4);
    opacity: 0.95;
    margin-left: 2%;
  }
  @media (min-width: 1920px) {
    && {
      height: 278px;
      width: 278px;
    }
  }
  @media (min-width: 2560px) {
    && {
      height: 370px;
      width: 370px;
    }
  }
  @media (min-width: 3840px) {
    && {
      height: 554px;
      width: 554px;
    }
  }
`;
