import React, { useEffect, useState } from "react";
import PageContainer from "../../shared_components/PageContainer/PageContainer";
import * as s from "./StyledCommunityPage.js";
import getCommunitySnippet from "../../../api/snippets/getCommunitySnippets";
import getFollowingSnippets from "../../../api/snippets/getFollowingSnippets";
import { useStoreState } from "react-context-hook";
import SnippetCommunityItem from "./SnippetCommunityItem/SnippetCommunityItem";
import SnippetComments from "./SnippetComments/SnippetComments";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const CommunityPage = () => {
  const { user, attemptedLogin, loggedIn } = useStoreState();
  const [communitySnippets, setCommunitySnippets] = useState([]);
  const [followingSnippets, setFollowingSnippets] = useState([]);
  const [followingLoaded, setFollowingLoaded] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [activeSnippetID, setActiveSnippetID] = useState("");
  const [activeSnippetTitle, setActiveSnippetTitle] = useState("");
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [loadingSnippetComments, setLoadingSnippetComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [shouldRefetchComments, setShouldRefetchComments] = useState(false);
  const [tab, setTab] = useState(0);
  useEffect(() => {
    getCommunitySnippet().then((response) => {
      setCommunitySnippets(response);
    });
  }, []);

  useEffect(() => {
    if (user.id) {
      getFollowingSnippets(user.id).then((response) => {
        setFollowingSnippets(response);
        setFollowingLoaded(true);
      });
    }
  }, [user.id]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  if (
    !hasFiltered &&
    communitySnippets.length !== 0 &&
    followingSnippets.length !== 0
  ) {
    const temp = followingSnippets.filter(function (fSnippets) {
      return !communitySnippets.find(function (cSnippets) {
        return cSnippets.SnippetID === fSnippets.SnippetID;
      });
    });
    setHasFiltered(true);
    setFollowingSnippets(temp);
  }

  const loadComments = (id, title, comments) => {
    setLoadingSnippetComments(true);
    setActiveSnippetTitle(title);
    setActiveSnippetID(id);
    setComments(comments);
    setLoadingSnippetComments(false);
  };

  return (
    <PageContainer app={true} active={"community"}>
      <s.Container>
        <s.HeaderContainer>
          <s.Header>Castyr Communities</s.Header>
          <s.HeaderDescription>
            Discover what episodes your friends and the greater Castyr community
            are listening to.
          </s.HeaderDescription>
        </s.HeaderContainer>
        <s.Trending>Trending Snippets</s.Trending>

        {(window.localStorage.getItem("token") !== null &&
          window.localStorage.getItem("token") !== "" &&
          loggedIn === false &&
          attemptedLogin === false) ||
        (user.id && !followingLoaded) ? (
          <div></div>
        ) : (
          <div>
            <s.Tabss value={tab} onChange={handleChange}>
              {followingSnippets.length !== 0 && <s.Tabb label="Following" />}
              <s.Tabb label="Community" />
            </s.Tabss>

            {followingSnippets.length !== 0 && (
              <TabPanel value={tab} index={0} style={{ overflowX: "hidden" }}>
                {followingSnippets.map((value, index) => {
                  return (
                    <SnippetCommunityItem
                      snippet={value}
                      key={index}
                      index={index}
                      open={commentsVisible}
                      setOpen={setCommentsVisible}
                      loadComments={loadComments}
                      activeSnippetID={activeSnippetID}
                      setActiveSnippetID={setActiveSnippetID}
                      shouldRefetchComments={shouldRefetchComments}
                      setShouldRefetchComments={setShouldRefetchComments}
                    />
                  );
                })}
              </TabPanel>
            )}

            <TabPanel
              value={tab}
              index={followingSnippets.length === 0 ? 0 : 1}
              style={{ overflowX: "hidden" }}
            >
              {communitySnippets.map((value, index) => {
                return (
                  <SnippetCommunityItem
                    snippet={value}
                    key={index}
                    index={index}
                    open={commentsVisible}
                    setOpen={setCommentsVisible}
                    loadComments={loadComments}
                    activeSnippetID={activeSnippetID}
                    setActiveSnippetID={setActiveSnippetID}
                    shouldRefetchComments={shouldRefetchComments}
                    setShouldRefetchComments={setShouldRefetchComments}
                  />
                );
              })}
            </TabPanel>
          </div>
        )}
      </s.Container>

      <SnippetComments
        open={commentsVisible}
        setOpen={setCommentsVisible}
        comments={comments}
        activeSnippetID={activeSnippetID}
        activeSnippetTitle={activeSnippetTitle}
        loading={loadingSnippetComments}
        setActiveSnippetID={setActiveSnippetID}
        setShouldRefetchComments={setShouldRefetchComments}
      />
    </PageContainer>
  );
};

export default CommunityPage;
