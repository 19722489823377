import React, { useState, useEffect } from "react";
import * as s from "./StyledPodcastContainer";
import PodcastCategory from "../PodcastCategory/PodcastCategory";
import PodcastRecs from "../PodcastRecs/PodcastRecs";
import recommendations from "../../recommendations";
import getAllPodcasts from "../../../../../api/podcasts/getAllPodcasts";
import CastyrLoadingGif from "../../../../../assets/castyr_loading.gif";

const PodcastContainer = (props) => {
  // consider using global variables but...caused infinite rerender?

  // create states for rendering all of the podcasts
  const [allPodcasts, setPodcasts] = useState([]);
  const [podcastByCategories, setPodcastByCategories] = useState(new Map());
  const [podcastByCuration, setPodcastByCuration] = useState(new Map());
  const [
    filteredPodcastByCategories,
    setFilteredPodcastByCategories,
  ] = useState(podcastByCategories);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [podcastsLoading, setPodcastsLoading] = useState(true);
  const [curated, setCurated] = useState(true);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  // similar to componentDidMount, used to get all of the podcasts only once
  useEffect(() => {
    // get all of the podcasts and sort them by name
    if (allPodcasts.length === 0) {
      setPodcasts([{}]);
      getAllPodcasts().then((podcasts) => {
        podcasts.sort((a, b) => {
          var x = a.Title.toLowerCase();
          var y = b.Title.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        podcasts.forEach((podcast) => {
          // by category
          if (podcastByCategories[podcast.Category] === undefined) {
            let copyPodcast = podcastByCategories;
            copyPodcast[podcast.Category] = [podcast];
            setPodcastByCategories(copyPodcast);
          } else {
            let copyPodcast = podcastByCategories;
            copyPodcast[podcast.Category].push(podcast);
            setPodcastByCategories(copyPodcast);
          }

          // by curation
          if (podcastByCuration[podcast.Curation] === undefined) {
            let copyPodcast = podcastByCuration;
            copyPodcast[podcast.Curation] = [podcast];
            setPodcastByCuration(copyPodcast);
          } else {
            let copyPodcast = podcastByCuration;
            copyPodcast[podcast.Curation].push(podcast);
            setPodcastByCuration(copyPodcast);
          }
        });

        //store the podcasts to the allPodcasts variable
        setPodcasts(podcasts);
        setPodcastsLoading(false);
      });
    }
  }, [allPodcasts, podcastByCategories, podcastByCuration]);

  /**
   * function to render each individual podcasts, sorted by sections
   *
   * @param allPodcasts
   */
  const renderPodcast = (header, podcast) => {
    return <PodcastCategory podcasts={podcast} header={header} />;
  };

  const renderPodcastByCategories = () => {
    let keys = Object.keys(filteredPodcastByCategories).sort();

    let sections = [];
    keys.forEach((key) => {
      sections.push(renderPodcast(key, podcastByCategories[key]));
    });

    return sections;
  };

  const renderPodcastByCuration = () => {
    const keys = [
      "Young 20-somethings in the city",
      "Startups, startups everywhere",
      "All about healthcare",
      "Listen to sports recaps and talks just to feel something",
      "Unpacking identities",
      "Saving our planet, if you're into that",
      "Work, work, work, work, work, work",
      "Move fast and break things",
      "Learn how to handle the business side",
      "We live in a society",
      "Put on your lab goggles",
      "For when you're feeling artsy",
      "Politics, activism, and everything in between",
      "University chats",
    ];

    let sections = [];
    if (!podcastsLoading) {
      sections.push(<PodcastRecs podcasts={recommendations} />);
      keys.forEach((key) => {
        sections.push(renderPodcast(key, podcastByCuration[key]));
      });
    }

    return sections;
  };

  const renderCategoryKeys = () => {
    let keys = Object.keys(podcastByCategories).sort();
    let sections = [
      <s.StyledMenuItem
        data-my-value={"All"}
        onClick={(event) => setFilteredPodcastByCategories(podcastByCategories)}
      >
        {"All"}
      </s.StyledMenuItem>,
    ];
    keys.forEach((key) => {
      if (key) {
        sections.push(
          <s.StyledMenuItem
            data-my-value={key}
            onClick={(event) => {
              setCurated(false);
              let tempPods = {};
              tempPods[event.currentTarget.dataset.myValue] =
                podcastByCategories[event.currentTarget.dataset.myValue];
              setFilteredPodcastByCategories(tempPods);
              closeMenu();
            }}
          >
            {key}
          </s.StyledMenuItem>
        );
      }
    });
    return sections;
  };

  return (
    <s.AllPodcasts>
      <s.PodcastHeaderContainer>
        <s.PodcastHeader>Podcasts</s.PodcastHeader>{" "}
        <s.MenuButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={openMenu}
        >
          Select Genre
        </s.MenuButton>
        <s.StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          {renderCategoryKeys()}
        </s.StyledMenu>
      </s.PodcastHeaderContainer>
      {curated && (
        <s.InfoText>
          Curated selection of content from up-and-coming creators. Discover the
          next big podcast.
        </s.InfoText>
      )}

      <s.LoadingContainer loading={podcastsLoading}>
        <s.VertCenterLoading>
          <s.CenterLoading>
            <s.LoadingGif src={CastyrLoadingGif} />
          </s.CenterLoading>
        </s.VertCenterLoading>
      </s.LoadingContainer>
      {curated ? renderPodcastByCuration() : renderPodcastByCategories()}
    </s.AllPodcasts>
  );
};

export default PodcastContainer;
