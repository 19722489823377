import React, { useEffect, useState } from "react";
import { useStoreState, useSetStoreValue } from "react-context-hook";
import PageContainer from "../../shared_components/PageContainer/PageContainer";
import HistoryContainer from "./History/History";
import Likes from "./Likes/Likes";
import SnippetLibrary from "./SnippetLibrary/SnippetLibrary";
import LibraryNav from "./LibraryNav/LibraryNav";
import Subscriptions from "./Subscriptions/Subscriptions";
import * as s from "./StyledLibraryPage";
import getUserByToken from "../../../api/user/getUserByToken";

const LibraryPage = () => {
  const { loggedIn, attemptedLogin } = useStoreState();
  const [activePage, setActivePage] = useState("");
  const setUser = useSetStoreValue("user");

  const isHistory = "history" === activePage;
  const isSubscription = "subscriptions" === activePage;
  const isLikes = "likes" === activePage;
  const isSnippets = "snippets" === activePage;
  let renderContent = () => {
    if (
      window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("token") !== "" &&
      loggedIn === false &&
      attemptedLogin === false
    ) {
      return <div></div>;
    } else if (!loggedIn) {
      return (
        <s.LoggetOutMessage>
          To view your listening history, subscriptions, liked episodes, and
          audio snippets,{" "}
          <s.LoggedOutLink to="/signup">
            create an account first.
          </s.LoggedOutLink>
        </s.LoggetOutMessage>
      );
    } else if (isHistory) {
      return <HistoryContainer />;
    } else if (isSubscription) {
      return <Subscriptions />;
    } else if (isLikes) {
      return <Likes />;
    } else if (isSnippets) {
      return <SnippetLibrary />;
    }
  };

  useEffect(() => {
    if (loggedIn) {
      setActivePage("history");
      getUserByToken(setUser);
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  return (
    <PageContainer app={true} active={"library"}>
      <s.LibraryContainer>
        <s.LibraryHeader>Library</s.LibraryHeader>
        <LibraryNav
          activePage={activePage}
          setActivePage={(page) => setActivePage(page)}
          loggedIn={loggedIn}
        />
        {renderContent()}
      </s.LibraryContainer>
    </PageContainer>
  );
};

export default LibraryPage;
